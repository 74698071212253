import Property from "@/entities/PrizificPageBuilder/Property";
import { EInputType } from "@/enums/PrizificPageBuilder/EInputType";
import { EProperty } from "@/enums/PrizificPageBuilder/EProperty";
import { ERoles } from "@/enums/PrizificPageBuilder/ERoles";
import { EState } from "@/enums/PrizificPageBuilder/EState";
import { EUnit } from "@/enums/PrizificPageBuilder/EUnit";

export const loginFormBackground = new Property(
    "Background color",
    "#FFFFFF00",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginFormBackground"
);

export const loginFormItemLabelColor = new Property(
    "Label text color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginFormItemLabel"
);

export const loginFormItemLabelFontSize = new Property(
    "Label font size",
    14,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginFormItemLabel",
    EUnit.PX,
    14,
    24
);

export const loginFormItemBackground = new Property(
    "Input background color",
    "#fff",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginFormItemInput"
);

export const loginFormItemBorderColor = new Property(
    "Input border color",
    "#fff",
    EState.DEFAULT,
    EProperty.BORDER_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginFormItemInput"
);

export const loginFormItemBorderRadius = new Property(
    "Input border radius",
    8,
    EState.DEFAULT,
    EProperty.BORDER_RADIUS,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginFormItemInput",
    EUnit.PX,
    0,
    48
);

export const loginFormItemBorderWidth = new Property(
    "Input border width",
    1,
    EState.DEFAULT,
    EProperty.BORDER_WIDTH,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginFormItemInput",
    EUnit.PX,
    0,
    8
);

export const loginFormCheckboxBackground = new Property(
    "Checkbox background",
    "#fff",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginFormCheckbox"
);

export const loginFormCheckboxColor = new Property(
    "Checkbox check color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginFormCheckbox"
);

export const loginFormCheckboxBorderRadius = new Property(
    "Checkbox border radius",
    8,
    EState.DEFAULT,
    EProperty.BORDER_RADIUS,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginFormCheckbox",
    EUnit.PX,
    0,
    48
);

export const loginFormSubmitButtonPadding = new Property(
    "Submit button padding",
    "6px 8px",
    EState.DEFAULT,
    EProperty.PADDING,
    EInputType.TEXT,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginFormSubmitButton"
);

export const loginFormSubmitButtonFontSize = new Property(
    "Submit button font size",
    14,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginFormSubmitButton",
    EUnit.PX,
    14,
    24
);

export const loginFormSubmitButtonBackground = new Property(
    "Submit button background color",
    "#3A89A2",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginFormSubmitButton"
);

export const loginFormSubmitButtonTextColor = new Property(
    "Submit button text color",
    "#fff",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginFormSubmitButton"
);

export const loginFormSubmitButtonBorderRadius = new Property(
    "Submit button border radius",
    8,
    EState.DEFAULT,
    EProperty.BORDER_RADIUS,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginFormSubmitButton",
    EUnit.PX,
    0,
    48
);

export const loginFormSubmitButtonBackgroundHover = new Property(
    "Submit button hover background color",
    "#3A89A2",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginFormSubmitButtonHover"
);

export const loginFormSubmitButtonTextColorHover = new Property(
    "Submit button hover text color",
    "#fff",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginFormSubmitButtonHover"
);
