import UserConsenting from "~~/entities/PrizificPageBuilder/sections/UserConsenting";
import {
    userConsentingFontFamily,
    userConsentingBackground,
    userConsentingParagraphColor,
    userConsentingParagraphFontSize,
    userConsentingTitleColor,
    userConsentingTitleFontSize,
} from "./UserConsentingProperty";
import { userConsentingGroup } from "./components/UserConsentingGroup";
import Text from "~~/entities/PrizificPageBuilder/components/Text";

export const userConsent = new UserConsenting(
    "-",
    "User consent",
    [
        userConsentingFontFamily,
        userConsentingBackground,
        userConsentingTitleColor,
        userConsentingTitleFontSize,
        userConsentingParagraphColor,
        userConsentingParagraphFontSize,
    ],
    "",
    [userConsentingGroup],
    []
);
