import Img from "@/entities/PrizificPageBuilder/components/Img";
import { ageGateBrandLogoWidth } from "./AgeGateBrandLogoProperties";

export const ageGateBrandLogo = new Img(
    "-",
    "Brand Logo",
    [ageGateBrandLogoWidth],
    "",
    [],
    ""
);
