import Modal from "@/entities/PrizificPageBuilder/components/Modal";
import { EModal } from "~~/enums/PrizificPageBuilder/EModal";

export const apCodeConflictModal = new Modal(
    "-", 
    "Ap code conflict modal",
    [],
    "",
    [],
    "Ezekkel az adatokkal már játszott valaki",
    "Van már feltöltés a rendszerünkben az általad megadott AP kóddal, vásárlási dátummal és időponttal. Kérlek ellenőrizd, hogy nem töltötte-e fel valaki, aki hozzáférhetett a blokkodhoz. Ha ez nem oldotta meg a problémát, kérlek, keresd az ügyfélszolgálatunkat.",
    "",
    "Felveszem a kapcsolatot az ügyfélszolgálattal",
    "Ezekkel az adatokkal már játszott valaki",
    "Van már feltöltés a rendszerünkben az általad megadott AP kóddal, vásárlási dátummal és időponttal. Kérlek ellenőrizd, hogy nem töltötte-e fel valaki, aki hozzáférhetett a blokkodhoz. Ha ez nem oldotta meg a problémát, kérlek, keresd az ügyfélszolgálatunkat.",
    "",
    "Felveszem a kapcsolatot az ügyfélszolgálattal",
    EModal.APCODECONFLICT
);
