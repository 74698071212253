import Modal from "@/entities/PrizificPageBuilder/components/Modal";
import { EModal } from "~~/enums/PrizificPageBuilder/EModal";

export const apCodeExhaustedModal = new Modal(
    "-",
    "Ap code exhausted modal",
    [],
    "",
    [],
    "title",
    "description",
    "",
    "button",
    "title",
    "description",
    "",
    "button",
    EModal.APCODEEXHAUSTED
);
