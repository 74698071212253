import FormItem from "@/entities/PrizificPageBuilder/components/FormItem";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { EEditProfileFormItem } from "~~/enums/PrizificPageBuilder/EEditProfileFormItem";

export const firstNameFormItem = new FormItem(
    "-",
    "First name form item",
    [],
    "",
    [],
    "Keresztnév",
    "",
    "Keresztnév",
    "",
    EEditProfileFormItem.FIRST_NAME
);
