import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { IFooter } from "@/interfaces/PrizificPageBuilder/IFooter";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import AComponent from "../AComponent";
import ASharedComponent from "../ASharedComponent";
import { IImg } from "~~/interfaces/PrizificPageBuilder/IImg";

export default class Footer
    extends ASharedComponent<EComponents.FOOTER>
    implements IComponent<EComponents.FOOTER>, IFooter
{
    constructor(
        id: string,
        sharedComponentId: string | number | null,
        isSharedComponent: boolean,
        title: string,
        style: Array<IProperty>,
        classNames: string,
        components: Array<IComponent<EComponents>>,
        public firstGroupTitle: string,
        public secondGroupTitle: string,
        public thirdGroupTitle: string,
        public firstGroupTitleRaw: string,
        public secondGroupTitleRaw: string,
        public thirdGroupTitleRaw: string,
        public images?: Array<IImg>
    ) {
        super(
            id,
            sharedComponentId,
            isSharedComponent,
            title,
            EComponents.FOOTER,
            style,
            classNames,
            components
        );
    }

    toPayload(): { [key: string]: any } {
        return super.toPayload({
            firstGroupTitle: this.firstGroupTitleRaw,
            secondGroupTitle: this.secondGroupTitleRaw,
            thirdGroupTitle: this.thirdGroupTitleRaw,
            images: this.images,
        });
    }
}
