import NavItem from "@/entities/PrizificPageBuilder/components/NavItem";

import {
    privacyPolicyFontSize,
    privacyPolicyPadding,
    privacyPolicyBorderRadius,
    privacyPolicyTextColor,
    privacyPolicyTextColorHover,
    privacyPolicyBackgroundColor,
    privacyPolicyBackgroundColorHover,
} from "./PrivacyPolicyProperties";

export const privacyPolicy = new NavItem(
    "-",
    null,
    false,
    "Adatvédelmi nyilatkozat",
    [
        privacyPolicyFontSize,
        privacyPolicyPadding,
        privacyPolicyTextColor,
        privacyPolicyTextColorHover,
        privacyPolicyBackgroundColor,
        privacyPolicyBackgroundColorHover,
    ],
    "",
    [],
    "Adatvédelmi nyilatkozat",
    "Adatvédelmi nyilatkozat",
    "",
    true
);
