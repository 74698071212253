import Property from "@/entities/PrizificPageBuilder/Property";
import { EInputType } from "@/enums/PrizificPageBuilder/EInputType";
import { EProperty } from "@/enums/PrizificPageBuilder/EProperty";
import { ERoles } from "@/enums/PrizificPageBuilder/ERoles";
import { EState } from "@/enums/PrizificPageBuilder/EState";
import { EUnit } from "@/enums/PrizificPageBuilder/EUnit";

export const editPasswordFormBackground = new Property(
    "Background color",
    "#FFFFFF00",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editPasswordFormBackground"
);

export const editPasswordFormItemLabelColor = new Property(
    "Label text color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editPasswordFormItemLabel"
);

export const editPasswordFormItemLabelFontSize = new Property(
    "Label font size",
    14,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editPasswordFormItemLabel",
    EUnit.PX,
    14,
    24
);

export const editPasswordFormItemBackground = new Property(
    "Input background color",
    "#fff",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editPasswordFormItemInput"
);

export const editPasswordFormItemBorderColor = new Property(
    "Input border color",
    "#fff",
    EState.DEFAULT,
    EProperty.BORDER_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editPasswordFormItemInput"
);

export const editPasswordFormItemBorderRadius = new Property(
    "Input border radius",
    8,
    EState.DEFAULT,
    EProperty.BORDER_RADIUS,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editPasswordFormItemInput",
    EUnit.PX,
    0,
    48
);

export const editPasswordFormItemBorderWidth = new Property(
    "Input border width",
    1,
    EState.DEFAULT,
    EProperty.BORDER_WIDTH,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editPasswordFormItemInput",
    EUnit.PX,
    0,
    8
);

export const editPasswordFormCheckboxBackground = new Property(
    "Checkbox background",
    "#fff",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editPasswordFormCheckbox"
);

export const editPasswordFormCheckboxColor = new Property(
    "Checkbox check color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editPasswordFormCheckbox"
);

export const editPasswordFormCheckboxBorderRadius = new Property(
    "Checkbox border radius",
    8,
    EState.DEFAULT,
    EProperty.BORDER_RADIUS,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editPasswordFormCheckbox",
    EUnit.PX,
    0,
    48
);

export const editPasswordFormSubmitButtonPadding = new Property(
    "Submit button padding",
    "6px 8px",
    EState.DEFAULT,
    EProperty.PADDING,
    EInputType.TEXT,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editPasswordFormSubmitButton"
);

export const editPasswordFormSubmitButtonFontSize = new Property(
    "Submit button font size",
    14,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editPasswordFormSubmitButton",
    EUnit.PX,
    14,
    24
);

export const editPasswordFormSubmitButtonBackground = new Property(
    "Submit button background color",
    "#3A89A2",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editPasswordFormSubmitButton"
);

export const editPasswordFormSubmitButtonTextColor = new Property(
    "Submit button text color",
    "#fff",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editPasswordFormSubmitButton"
);

export const editPasswordFormSubmitButtonBorderRadius = new Property(
    "Submit button border radius",
    8,
    EState.DEFAULT,
    EProperty.BORDER_RADIUS,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editPasswordFormSubmitButton",
    EUnit.PX,
    0,
    48
);

export const editPasswordFormSubmitButtonBackgroundHover = new Property(
    "Submit button background color hover",
    "#3A89A2",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editPasswordFormSubmitButtonHover"
);

export const editPasswordFormSubmitButtonTextColorHover = new Property(
    "Submit button text color hover",
    "#fff",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editPasswordFormSubmitButtonHover"
);
