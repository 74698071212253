import NavItem from "@/entities/PrizificPageBuilder/components/NavItem";

import {
    legalNoticeFontSize,
    legalNoticePadding,
    legalNoticeBorderRadius,
    legalNoticeTextColor,
    legalNoticeTextColorHover,
    legalNoticeBackgroundColor,
    legalNoticeBackgroundColorHover,
} from "./LegalNoticeProperties";

export const legalNotice = new NavItem(
    "-",
    null,
    false,
    "Jogi nyilatkozat",
    [
        legalNoticeFontSize,
        legalNoticePadding,
        legalNoticeTextColor,
        legalNoticeTextColorHover,
        legalNoticeBackgroundColor,
        legalNoticeBackgroundColorHover,
    ],
    "",
    [],
    "Jogi nyilatkozat",
    "Jogi nyilatkozat",
    "",
    true
);
