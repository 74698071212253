import "element-plus/dist/index.css";
import ElementPlus from "element-plus";
import hu from "element-plus/es/locale/lang/hu";
//import dayjs from "dayjs";

export default defineNuxtPlugin((nuxtApp) => {
    

    nuxtApp.vueApp.use(ElementPlus, {
        locale: hu,
    });
});
