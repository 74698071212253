import FormItem from "@/entities/PrizificPageBuilder/components/FormItem";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { ERegistrationFormItem } from "~~/enums/PrizificPageBuilder/ERegistrationFormItem";

export const lastNameFormItem = new FormItem(
    "-",
    "Last Name form item",
    [],
    "",
    [],
    "Vezetéknév",
    "",
    "Vezetéknév",
    "",
    ERegistrationFormItem.LASTNAME
);
