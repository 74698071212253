import ContactPrivacyPolicy from "../../../../PrizificPageBuilder/sections/ContactPrivacyPolicy";
import {
    contactBackground,
    contactFontFamily,
    contactParagraphColor,
    contactTitleColor,
    contactTitleFontSize,
    contactParagraphFontSize,
    contactCheckboxBackground,
    contactCheckboxColor,
    contactCheckboxBorderColor,
    contactCheckboxBorderRadius,
    contactSubmitButtonPadding,
    contactSubmitButtonFontSize,
    contactSubmitButtonBackground,
    contactSubmitButtonTextColor,
    contactSubmitButtonBorderColor,
    contactSubmitButtonBorderWidth,
    contactSubmitButtonBorderRadius,
    contactSubmitButtonBackgroundHover,
    contactSubmitButtonTextColorHover,
    contactSubmitButtonBorderColorHover,
    contactSubmitButtonBorderWidthHover,
} from "./ContactPrivacyPolicyProperties";

export const contactPrivacyPolicy = new ContactPrivacyPolicy(
    "-",
    null,
    false,
    "Contact privacy policy",
    [
        contactFontFamily,
        contactBackground,
        contactTitleColor,
        contactTitleFontSize,
        contactParagraphColor,
        contactParagraphFontSize,
        contactCheckboxBackground,
        contactCheckboxColor,
        contactCheckboxBorderColor,
        contactCheckboxBorderRadius,
        contactSubmitButtonPadding,
        contactSubmitButtonFontSize,
        contactSubmitButtonBackground,
        contactSubmitButtonTextColor,
        contactSubmitButtonBorderColor,
        contactSubmitButtonBorderWidth,
        contactSubmitButtonBorderRadius,
        contactSubmitButtonBackgroundHover,
        contactSubmitButtonTextColorHover,
        contactSubmitButtonBorderColorHover,
        contactSubmitButtonBorderWidthHover,
    ],
    "",
    [],
    "Kapcsolat",
    "Kapcsolat",
    "Kérdésed van? Az Adatkezelési Tájékoztatónk elfogadásával keress minket bizalommal!",
    "Kérdésed van? Az Adatkezelési Tájékoztatónk elfogadásával keress minket bizalommal!",
    "Elolvastam és elfogadom az Adatkezelési Tájákoztatót.",
    "Elolvastam és elfogadom az Adatkezelési Tájákoztatót.",
    "A játékkal kapcsolatos kérdéseiddel, valaimt a további felvilágosításért keresd ügyfélszolgálatunkat a promóció időtartama alatt az info@pennyfalatok.hu e-mail címen!",
    "A játékkal kapcsolatos kérdéseiddel, valaimt a további felvilágosításért keresd ügyfélszolgálatunkat a promóció időtartama alatt az info@pennyfalatok.hu e-mail címen!",
    "Promóció időtartama: ",
    "Promóció időtartama: ",
    []
);
