import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { ISection } from "@/interfaces/PrizificPageBuilder/ISection";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import AComponent from "../AComponent";
import { IImg } from "~~/interfaces/PrizificPageBuilder/IImg";

export default class UserConsenting
    extends AComponent<EComponents.USER_CONSENTING>
    implements IComponent<EComponents.USER_CONSENTING>
{
    constructor(
        id: string,
        title: string,
        style: Array<IProperty>,
        classNames: string,
        components: Array<IComponent<EComponents>>,
        public images?: Array<IImg>
    ) {
        super(
            id,
            title,
            EComponents.USER_CONSENTING,
            style,
            classNames,
            components
        );
    }

    toPayload(): { [key: string]: any } {
        return super.toPayload({
            images: this.images,
            //guestUserTitle: this.guestUserTitle,
            //guestUserDescription: this.guestUserDescription,
            //loggedInUserTitle: this.loggedInUserTitle,
            //loggedInUserDescription: this.loggedInUserDescription,
        });
    }
}
