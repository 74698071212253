import { defineStore } from "pinia";
import { useCampaignDataStore } from "~~/store/campaign/campaignData";
import { useAuthStore } from "~~/store/user/auth";
import { watch } from "vue";

export enum TalentsTab {
    NAGY_NAPSUGAR = "nagy_napsugar",
    KRISZT_SAROLTA = "kriszt_sarolta",
    ZEMAN_ZOE_ZORA = "zeman_zoe_zora",
}

export const useVoteStore = defineStore("vote", {
    state: () => {
        return {
            voted: "",
            canVote: true,
            modal: false,
            activeTab: TalentsTab.NAGY_NAPSUGAR,
            talents: [
                {
                    id: "1",
                    talentId: "nagynapsugar",
                    talentEnum: TalentsTab.NAGY_NAPSUGAR,
                    image: "/images/szentkiralyi/nagy_napsugar_with_bg.webp",
                    name: "Nagy Napsugár",
                    birthDate: "2008.12.14.",
                    city: "Zalaegerszeg",
                    sport: "úszás",
                    association: "Zalaco ZÚK",
                    sportExperience: "5 év vagy annál több",
                    myGoals:
                        "“Célom, hogy minél több világ és európai versenyen részt tudjak venni. Minden versenyszámban, amiben indulok, el szeretném érni a kijutáshoz szükséges szintidőket és az egyéni csúcsaimat szeretném tovább javítani.”",
                    sportResults: [
                        "Nyíltvízi Junior Eb. 5000m II.hely",
                        "Rövidpályás korosztályos magyar bajnokság gyorsúszás; 200m, 400m, 800m Magyar bajnoka, 1500m II. hely.",
                        "CECJM 800m I.hely",
                        "Serdülő Magyar bajnokság gyorsúszás 50m medence: 400m , 800m, 1500m Magyar bajnoka",
                        "Ifi Magyar Bajnokság gyorsúszás 50 m medence : 1500m II.hely, 400m III.hely, 800m I.hely",
                        "Hosszútávú magyar bajnokság 5000 m és 1500m 2xI Magyar bajnoka (5000 m országos csúcs)",
                        "Hosszútávú magyar bajnokság 7500 m és 1500 Magyar bajnoka",
                    ],
                    importantStations: [
                        "Portugália nyíltvízi Eb.",
                        "Korfu nyíltvízi Eb.",
                        "Románia Felnőtt rövidpályás Eb.",
                        "Serdülő, Ifi, és felnőtt magyar bajnokságok",
                        "Eyof Maribor",
                        "CECJM Prága",
                    ],
                    financialSupport: [
                        "úszás felszerelések",
                        "edzőtábor támogatás",
                        "erőnléti edzéshez sporteszközök",
                        "táplálékkiegészítők",
                        "vitaminok",
                        "pályabérlés",
                        "versenyekre utazás támogatás",
                    ],
                    percentage: 0,
                    videoUrl:
                        "https://szentkiralyi-videos.s3.eu-west-2.amazonaws.com/NAPSUGAR_ONLINE_FINAL_01+(1).mp4",
                },
                {
                    id: "2",
                    talentId: "krisztsarolta",
                    talentEnum: TalentsTab.KRISZT_SAROLTA,
                    image: "/images/szentkiralyi/kriszt_sarolta_with_bg.webp",
                    name: "Kriszt Sarolta",
                    birthDate: "2006.10.28.",
                    city: "Gödöllő",
                    sport: "atlétika",
                    association: "MATE - Gödöllői Egyetemi Atlétikai Club",
                    sportExperience: "5 év vagy annál több",
                    myGoals:
                        "“Részvétel a római felnőtt EB-n a 4x400 méteres női váltóval, illetve a legfontosabb verseny, az augusztus végi, perui U20-as világbajnokság, amelyre hétpróbában a kvalifikációs szintet teljesítettem, jelenleg vezetem a világranglistát, így első éves juniorként nagy reményekkel készülök.”",
                    sportResults: [
                        "2023: EYOF aranyérem hétpróba, U18-as országos csúcs; részvétel a Budapesti Atlétikai Világbajnokságon 4x400-as váltóban",
                        "2022: U18 EB ezüstérem hétpróba, U18-as országos csúcs; részvétel a Müncheni Atlétikai Európa-bajnokságon 4x400-as váltóban, EYOF bronzérem svédváltóban és IV. hely 200 méter síkfutás",
                        "Az elmúlt három évben 15 országos bajnoki aranyérem, ötpróba, 100 és 400 méter gátfutás, 400m, távolugrás, 200m",
                        "4 db korosztályos országos csúcs, hétpróba, ötpróba, 100 és 400 méter gátfutás",
                    ],
                    importantStations: [
                        "2019-től kezdődően minden korosztályos országos bajnokságon nyertem aranyérmet, U14, U16, U18 majd U20 korosztályban.",
                        "Az első nemzetközi sikerem 2022-es jeruzsálemi U18-as EB, majd az azévi EYOF érmek. 2023-ban EYOF aranyérem és felnőtt válogatott kerettagság, részvétel a hazai Világbajnokságon a 4x400-as váltóval. 2023-tól a Magyar Atlétikai Szövetség kiemelt utánpótlás, ún. Talent programjának vagyok a tagja.",
                        "Ugyanebben az évben megválasztottak az Év Ifjúsági Női Atlétájának.",
                        "Kétszer elnyertem MOL-Új Európa Alapítvány ösztöndíját. 2024-ben életem első felnőtt, „GOLD” kategóriájú többpróba versenyére hívtak meg, és egyetlen U20-as indulóként a 26 fős mezőnyben 11. helyen végeztem.",
                        "2022 óta a felnőtt 4x400-as váltóval is készülök.",
                        "Eddigi pályafutásom során ötször javítottam meg korosztályos országos csúcsot.",
                    ],
                    financialSupport: [
                        "Freelap timing system pack 212",
                        "mely az edzéseken a pontos sebességmérésben segít, így tökéletes képet és időeredményt kaphatok minden futásról. Minden ősszel magaslati alapozó edzőtáborokban kell részt vennünk, télen-tavasszal pedig melegégövi edzéslehetőségekre van szükségünk.",
                    ],
                    percentage: 0,
                    videoUrl:
                        "https://szentkiralyi-videos.s3.eu-west-2.amazonaws.com/SAROLTA_ONLINE_FINAL_01.mp4",
                },
                {
                    id: "3",
                    talentId: "zemanzoezora",
                    talentEnum: TalentsTab.ZEMAN_ZOE_ZORA,
                    image: "/images/szentkiralyi/zeman_zoe_zora_with_bg.webp",
                    name: "Zemán Zóra Zoé",
                    birthDate: "2006.06.10.",
                    city: "Kapospula",
                    sport: "öttusa",
                    association: "Budapesti Honvéd Sportegyesület",
                    sportExperience: "5 év vagy annál több",
                    myGoals:
                        "“A 2024. évben célkitűzéseim között szerepel két korosztályban is a válogatott kerettagságot elérni, mind U19, mind pedig JUNIOR korosztályokban. Erre vonatkozóan a válogató versenyek folyamatosan zajlanak. Ezidáig egy U19-es verseny volt, ahol 1. helyezést értem el, valamint két JUNIOR verseny volt, és mindkettőn szintén 1. helyezést értem el. Célom, hogy mind az Európa mind pedig a világbajnokságon legalább a tavalyi évben elért egyéni eredményeimet (dobogós helyezés), vagy annál jobb eredményt érjek el. Úszásban szeretném az egyéni időeredményemet javítani, és legalább 02:15-öt úszni 200 méter gyorson. OCR-ben szeretném az egyéni időeredményemet javítani, és legalább 00:35-öt menni a pályán. Laser Runban szeretnék egyéni csúcsot elérni. Vívásban az Európa-, és a világbajnokságon az első 5-ben szeretnék lenni.”",
                    sportResults: [
                        `
                        2023. év
                        <br/>
                        Ifjúsági Európa Bajnokság (Litvánia):<br/>
                        - egyéni: 3. helyezés,<br/>
                        - váltó: 1 helyezés,<br/>
                        - csapat: 1. helyezés.
                        <br/><br/>
                        Laser Run Európa Bajnokság (Németország):<br/>
                        - triathle egyéni: 1. helyezés,<br/>
                        - laser run: egyéni: 1. helyezés,<br/>
                        - váltó: 1. helyezés.
                        <br/><br/>
                        Ifjúsági Világbajnokság 2023 (Isztambul):<br/>
                        - egyéni: 3. helyezés,<br/>
                        - váltó: 1 helyezés,<br/>
                        - csapat: 1. helyezés.
                        <br/><br/>
                        U19 Országos Bajnokság:<br/>
                        - egyéni: 1. helyezés.
                        <br/><br/>
                        Junior Országos Bajnokság:<br/>
                        - egyéni: 2. helyezés.
                        <br/><br/>
                        Országos Diákolimpia:<br/>
                        - 4x800 méter leány váltó: 2. helyezés.
                        <br/><br/>
                    `,
                        `
                        2022.év
                        <br/>
                        Ifjúsági Világbajnokság:<br/>
                        - egyéni: 6. helyezés,<br/>
                        - csapat: 2. helyezés.
                        <br/><br/>
                        Ifjúsági Európa Bajnokság:<br/>
                        - csapat: 1. helyezés.
                        <br/><br/>
                        Olimpiai Reménységek Versenye:<br/>
                        - egyéni: 1. helyezés,<br/>
                        - váltó: 2. helyezés.
                        <br/><br/>
                        Országos Bajnokság:<br/>
                        - 1. helyezés.
                        <br/><br/>
                        Országos Diákolimpia:<br/>
                        - 800 méter síkfutás: 2. helyezés.<br/>
                        - 4x800 méter leány váltló: 1. helyezés.
                        <br/><br/>
                    `,
                        `
                        2021. év<br/>
                        Ifjúsági Világbajnokság:<br/>
                        - egyéni: 9. helyezés,<br/>
                        - csapat: 2. helyezés.
                        <br/><br/>
                        Ifjúsági Európa Bajnokság:
                        - egyéni: 5. helyezés,<br/>
                        - csapat: 1. helyezés.
                    `,
                    ],
                    importantStations: [
                        "2011-től (5 éves korom óta) a Dombóvári Sportiskola Egyesületben úsztam, majd két- és három tusáztam.",
                        "Ezzel párhuzamosan a Dombóvári Vasutas Atlétikai Sportegyesületben atlétizáltam, Fazekas Gáspár edző irányításával és maximális támogatásával, akivel a két- és háromtusa versenyekre is készültem, mind futásban, mind pedig lövészetben.",
                        "Mindeközben szüleim Kaposvárra hordtak át heti két alkalommal vívni, Mató Gyula paralimpikon edzőhöz.",
                        "14 éves koromban szüleimet megkereste Kovács Tamás, a Budapesti Honvéd Sportegyesület öttusa szakosztályvezetője, és kérte, hogy nézzük meg az egyesület munkáját, majd igazoljak hozzájuk.",
                        "2021. óta a Budapesti Honvéd Sportegyesület U19-es korosztályos igazolt öttusázója vagyok. Munkámnak köszönhetően, minden évben a válogatott kerettagjaként indulhattam világversenyeken.",
                    ],
                    financialSupport: [
                        "Szeretnék egy laser fegyvert vásárolni. A jelenlegit még szüleim a pályafutásom elején vásárolták, és ez az első pisztolyom, amelynek a markolata a sok évi edzésmunka és verseny alatt már elhasználódott. Már több alkalommal is volt javítva, cserélve, de így már nem az igazi. A csere mellett szól még az is, hogy ma már az enyémnél sokkal modernebb, könnyebb fegyvereket lehet beszerezni, amelynek használata során jelentősen javulhatna az időeredményem is.",
                        "Úszáshoz egy jó minőségű „CARBAN” úszóruhára lenne szükségem. A jelenlegit már kinőtem, ezért nehezen tudom felvenni verseny előtt. Két versenyszám között, pedig sokat számít a bemelegítési idő, amelyet a gyors öltözéssel tudok növelni.",
                    ],
                    percentage: 0,
                    videoUrl:
                        "https://szentkiralyi-videos.s3.eu-west-2.amazonaws.com/ZORA_ONLINE_FINAL_01.mp4",
                },
            ],
            voteList: [
                {
                    id: "1",
                    image: "/images/szentkiralyi/nagy_napsugar_with_bg_small.webp",
                    title: "Nagy Napsugár",
                    description: "úszás",
                    link: "#talents",
                    talentEnum: TalentsTab.NAGY_NAPSUGAR,
                    videoUrl: "i911_s9yvZc",
                },
                {
                    id: "2",
                    image: "/images/szentkiralyi/kriszt_sarolta_with_bg_small.webp",
                    title: "Kriszt Sarolta",
                    description: "atlétika",
                    link: "#talents",
                    talentEnum: TalentsTab.KRISZT_SAROLTA,
                    videoUrl: "l-n8QC3AGgg",
                },
                {
                    id: "3",
                    image: "/images/szentkiralyi/zeman_zoe_zora_with_bg_small.webp",
                    title: "Zemán Zóra Zoé",
                    description: "öttusa",
                    link: "#talents",
                    talentEnum: TalentsTab.ZEMAN_ZOE_ZORA,
                    videoUrl: "4N-bUasIOzw",
                },
            ],
            minionVoteList: [
                {
                    id: "1",
                    image: "/images/mogyi_minion/Gru.png",
                    title: "Gru",
                    description: "",
                },
                {
                    id: "2",
                    image: "/images/mogyi_minion/Gus.png",
                    title: "Gus",
                    description: "",
                },
                {
                    id: "3",
                    image: "/images/mogyi_minion/Jerry.png",
                    title: "Jerry",
                    description: "",
                },
                {
                    id: "4",
                    image: "/images/mogyi_minion/Dave.png",
                    title: "Dave",
                    description: "",
                },
                {
                    id: "5",
                    image: "/images/mogyi_minion/Phil.png",
                    title: "Phil",
                    description: "",
                },
                {
                    id: "6",
                    image: "/images/mogyi_minion/Tim.png",
                    title: "Tim",
                    description: "",
                },
            ],
        };
    },
    getters: {
        yourTalent(state) {
            return (talentId: string) => {
                return state.voted == talentId;
            };
        },
        voteable(state) {
            return !state.canVote;
        },
    },
    actions: {
        async votePercentage() {
            const campaignDataStore = useCampaignDataStore();

            const { data, error } = await useApiFetch(
                `/endusermanagerms/vote/${campaignDataStore.campaignUUId}/vote-status`,
                {
                    key: "vote-status",
                    method: "GET",
                }
            );

            if (data.value) {
                // this.setPercentages(data.value);
            }
        },
        async vote(id: string) {
            const campaignDataStore = useCampaignDataStore();
            const authStore = useAuthStore();

            if (authStore.isAuthenticated && this.canVote) {
                const { data, error } = await useApiFetch(
                    `/endusermanagerms/vote/${campaignDataStore.campaignUUId}`,
                    {
                        key: "vote",
                        method: "POST",
                        body: {
                            userId: authStore.user?.id,
                            vote: id,
                        },
                    }
                );

                if (data.value) {
                    this.showModal();
                    this.canVote = false;
                    this.voted = id;
                    // this.setPercentages(data.value);
                }
            }
        },
        async getVoted() {
            const campaignDataStore = useCampaignDataStore();
            const authStore = useAuthStore();

            const { data, error } = await useApiFetch(
                `/endusermanagerms/vote/${campaignDataStore.campaignUUId}/${authStore.user?.id}/voted`,
                {
                    key: "voted",
                    method: "GET",
                }
            );

            this.canVote = !data.value?.voted;
            // this.setPercentages(data.value);
        },
        setPercentages(data: any) {
            this.talents.forEach((talent) => {
                talent.percentage = data.votePercentages[talent.id];
            });
        },
        showModal() {
            this.modal = true;
        },
        hideModal() {
            this.modal = false;
        },
        setActiveTab(activeTab: TalentsTab) {
            this.activeTab = activeTab;
        },
    },
});
