import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import Registration from "@/entities/PrizificPageBuilder/sections/Registration";
import { AComponentFactory } from "../ComponentFactory";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { registration } from "~~/entities/pages/home/sections/Registration/Registration";

export class RegistrationFactory extends AComponentFactory<Registration> {
    getTypeName() {
        return EComponents.REGISTRATION;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, [
            // "data.specific.sectionTitleCompiled",
            // "data.specific.sectionParagraphCompiled",
            // "data.specific.sectionTitle",
            // "data.specific.sectionParagraph",
        ]);

        return new Registration(
            rawData.id,
            rawData.data.title,
            usePropertiesMerger(properties, registration.style),
            rawData.data.classNames,
            subComponents,
            getConvertedText(rawData.data.specific.sectionTitleCompiled),
            getConvertedText(rawData.data.specific.sectionParagraphCompiled),
            rawData.data.specific.sectionTitle,
            rawData.data.specific.sectionParagraph,
            rawData.data.specific.images
                ? rawData.data.specific.images
                : [
                      {
                          src: "",
                          imageType: "extra-extra-large",
                          imageLabel: "1920px",
                      },
                      {
                          src: "",
                          imageType: "extra-large",
                          imageLabel: "1400px",
                      },
                      {
                          src: "",
                          imageType: "large",
                          imageLabel: "1200px",
                      },
                      {
                          src: "",
                          imageType: "medium",
                          imageLabel: "992px",
                      },
                      {
                          src: "",
                          imageType: "small",
                          imageLabel: "768px",
                      },
                      {
                          src: "",
                          imageType: "extra-small",
                          imageLabel: "576px",
                      },
                  ]
        );
    }
}
