import { useAuthStore } from "@/store/user/auth";

export default defineNuxtRouteMiddleware(async (to, from) => {
    const authStore = useAuthStore();
    //console.log("[AUTH MIDDLEWARE TO]", to);
    //console.log("[AUTH MIDDLEWARE FROM]", from);

    if (authStore.token && authStore.user == undefined) {
        try {
            await authStore.getMe();
        } catch (e) {
            if (e.response) {
                console.error("Auth Middleware error", e.response.data);
            } else {
                console.error("Auth Middleware error", e);
            }
        }
    } else {
        if (
            to.name == useDynamicLink("/profil-modositas") ||
            to.name == useDynamicLink("/jelszo-modositas") ||
            to.name == useDynamicLink("/feltoltott-kodok")
        ) {
            console.log("FOOLDALRA");
            return navigateTo(useDynamicLink(""), {
                redirectCode: 401,
            });
        }
    }
});
