import FormItem from "@/entities/PrizificPageBuilder/components/FormItem";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { ERegistrationFormItem } from "~~/enums/PrizificPageBuilder/ERegistrationFormItem";

export const dateOfBirthMonthFormItem = new FormItem(
    "-",
    "Date of birth month form item",
    [],
    "",
    [],
    "",
    "Month",
    "",
    "Month",
    ERegistrationFormItem.DATE_OF_BIRTH_MONTH
);
