import Property from "@/entities/PrizificPageBuilder/Property";
import { EInputType } from "@/enums/PrizificPageBuilder/EInputType";
import { EProperty } from "@/enums/PrizificPageBuilder/EProperty";
import { ERoles } from "@/enums/PrizificPageBuilder/ERoles";
import { EState } from "@/enums/PrizificPageBuilder/EState";
import { EUnit } from "@/enums/PrizificPageBuilder/EUnit";

export const winnersListToggleButtonBackground = new Property(
    "Toggle button background color",
    "#fff",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "winnersListToggleButton"
);

export const winnersListToggleButtonColor = new Property(
    "Toggle button color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "winnersListToggleButton"
);

export const winnersListToggleButtonBorderColor = new Property(
    "Toggle button border color",
    "#444444",
    EState.DEFAULT,
    EProperty.BORDER_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "winnersListToggleButton"
);

export const winnersListToggleButtonActiveColor = new Property(
    "Toggle button active color",
    "#fff",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "winnersListToggleButtonActive"
);

export const winnersListToggleButtonActiveBackground = new Property(
    "Toggle button active background color",
    "#444444",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "winnersListToggleButtonActive"
);

export const winnersListToggleButtonBorderRadius = new Property(
    "Toggle button border radius",
    4,
    EState.DEFAULT,
    EProperty.BORDER_RADIUS,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "winnersListToggleButton",
    EUnit.PX,
    0,
    48
);

export const winnersListItemBackground = new Property(
    "Item background color",
    "#fff",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "winnersListItem"
);

export const winnersListItemColor = new Property(
    "Item text color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "winnersListItem"
);

export const winnersListItemBorderColor = new Property(
    "Item border color",
    "#444444",
    EState.DEFAULT,
    EProperty.BORDER_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "winnersListItem"
);

export const winnersListItemBorderRadius = new Property(
    "Item border radius",
    8,
    EState.DEFAULT,
    EProperty.BORDER_RADIUS,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "winnersListItem",
    EUnit.PX,
    0,
    48
);

export const winnersListItemBorderWidth = new Property(
    "Item border width",
    2,
    EState.DEFAULT,
    EProperty.BORDER_WIDTH,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "winnersListItem",
    EUnit.PX,
    0,
    8
);

export const winnersListItemPadding = new Property(
    "Item padding",
    "4px 8px",
    EState.DEFAULT,
    EProperty.PADDING,
    EInputType.TEXT,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "winnersListItem"
);
