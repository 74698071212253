import Group from "~/entities/PrizificPageBuilder/components/Group";
import { noRecipeModal } from "./NoRecipeModal";
import {
    modalTitleTextColor,
    modalDescriptionTextColor,
    modalCloseIconColor,
    modalRightButtonBackground,
    modalRightButtonColor,
    modalRightButtonBorderColor,
    modalRightButtonFontSize,
    modalRightButtonPadding,
    modalRightButtonBorderRadius,
    modalLeftButtonBackground,
    modalLeftButtonColor,
    modalLeftButtonBorderColor,
    modalLeftButtonFontSize,
    modalLeftButtonPadding,
    modalLeftButtonBorderRadius,
} from "./RecipeFeedbackModalsProperties";

export const recipeUploadFeedbackModals = new Group(
    "-",
    "Feedback modals",
    [
        modalTitleTextColor,
        modalDescriptionTextColor,
        modalCloseIconColor,
        modalLeftButtonBackground,
        modalLeftButtonColor,
        modalLeftButtonBorderColor,
        modalLeftButtonFontSize,
        modalLeftButtonPadding,
        modalLeftButtonBorderRadius,
        modalRightButtonBackground,
        modalRightButtonColor,
        modalRightButtonBorderColor,
        modalRightButtonFontSize,
        modalRightButtonPadding,
        modalRightButtonBorderRadius,
    ],
    "",
    [noRecipeModal]
);
