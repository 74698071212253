import Modal from "@/entities/PrizificPageBuilder/components/Modal";
import { EModal } from "~~/enums/PrizificPageBuilder/EModal";

export const noRecipeModal = new Modal(
    "-",
    "No recipe modal",
    [],
    "",
    [],
    "Ajjaj, nem találod a nyugtát?",
    "A játékszabályaink szerint a nyugtádat mindenképp be kell mutatnod nyerés esetén, hiszen ez bizonyítja, hogy a felvitt adatok helyesek, és a kódfeltöltések érvényes.    Kérlek, próbáld meg megtalálni! Ha biztosan nincs meg, akkor sajnos a játékod nem érvényes. :( A nyerésről küldött emailből később is eléred ezt az oldalt, így még van időd felkutatni. Fontos viszont, hogy a játékszabályok értelmében a nyerésról kapott értesítéstől számított 10 napon belül kell bemutatnod a nyugtát.",
    "Nem tudom bemutatni a nyugtát",
    "Rendben",
    "Ajjaj, nem találod a nyugtát?",
    "A játékszabályaink szerint a nyugtádat mindenképp be kell mutatnod nyerés esetén, hiszen ez bizonyítja, hogy a felvitt adatok helyesek, és a kódfeltöltések érvényes.    Kérlek, próbáld meg megtalálni! Ha biztosan nincs meg, akkor sajnos a játékod nem érvényes. :( A nyerésről küldött emailből később is eléred ezt az oldalt, így még van időd felkutatni. Fontos viszont, hogy a játékszabályok értelmében a nyerésról kapott értesítéstől számított 10 napon belül kell bemutatnod a nyugtát.",
    "Nem tudom bemutatni a nyugtát",
    "Rendben",
    EModal.NO_RECIPE_MODAL
);
