import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import {
    discountCouponsFontFamily,
    discountCouponsBackground,
    discountCouponsImageWidth,
    discountCouponsTextColor,
    discountCouponsMoreTextColor,
    discountCouponsMoreHoverTextColor,
    discountCouponsTypeFontSize,
    discountCouponsPrizeNameFontSize,
    discountCouponsDescriptionFontSize,
    discountCouponsMoreFontSize,
} from "./DiscountCouponsProperties";
import DiscountCoupons from "../../../../PrizificPageBuilder/sections/DiscountCoupons";

export const discountCoupons = new DiscountCoupons(
    "-",
    "Discount coupons",
    [
        discountCouponsFontFamily,
        discountCouponsBackground,
        discountCouponsImageWidth,
        discountCouponsTextColor,
        discountCouponsMoreTextColor,
        discountCouponsMoreHoverTextColor,
        discountCouponsTypeFontSize,
        discountCouponsPrizeNameFontSize,
        discountCouponsDescriptionFontSize,
        discountCouponsMoreFontSize,
    ],
    "",
    [],
    "Default title",
    "Default paragraph",
    "Default title",
    "Default paragraph"
);
