import NavItem from "@/entities/PrizificPageBuilder/components/NavItem";

import {
    navItemPrizesFontSize,
    navItemPrizesPadding,
    navItemPrizesBorderRadius,
    navItemPrizesTextColor,
    navItemPrizesTextColorHover,
    navItemPrizesBackgroundColor,
    navItemPrizesBackgroundColorHover,
} from "./NavItemPrizesProperties";

export const navItemPrizes = new NavItem(
    "-",
    null,
    false,
    "Nyeremények",
    [
        navItemPrizesFontSize,
        navItemPrizesPadding,
        navItemPrizesBorderRadius,
        navItemPrizesTextColor,
        navItemPrizesTextColorHover,
        navItemPrizesBackgroundColor,
        navItemPrizesBackgroundColorHover,
    ],
    "fw-bold",
    [],
    "Nyeremények",
    "Nyeremények",
    "#nyeremenyek",
    false
);
