import NavItem from "@/entities/PrizificPageBuilder/components/NavItem";
//import { logoTiktok } from "ionicons/icons";

import {
    socialTiktokWidth,
    socialTiktokFillColor,
    socialTiktokFillColorHover,
} from "./SocialTiktokProperties";

export const socialTiktok = new NavItem(
    "-",
    null,
    false,
    "Tiktok",
    [socialTiktokWidth, socialTiktokFillColor, socialTiktokFillColorHover],
    "",
    [],
    "",
    "",
    "",
    true,
    "tiktok"
);
