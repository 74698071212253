import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { ICookieSettings } from "@/interfaces/PrizificPageBuilder/ICookieSettings";
import AComponent from "../AComponent";
import ASharedComponent from "../ASharedComponent";

export default class CookieSettings
    extends ASharedComponent<EComponents.COOKIE_SETTINGS>
    implements IComponent<EComponents.COOKIE_SETTINGS>, ICookieSettings
{
    constructor(
        id: string,
        sharedComponentId: string | number | null,
        isSharedComponent: boolean,
        title: string,
        style: Array<IProperty>,
        classNames: string,
        components: Array<IComponent<EComponents>>,
        public modalTitle: string,
        public modalParagraph: string,
        public modalSettingsButtonText: string,
        public modalAllAcceptButtonText: string,
        public modalSelectedAcceptButtonText: string,
        public modalTitleRaw: string,
        public modalParagraphRaw: string,
        public modalSettingsButtonTextRaw: string,
        public modalAllAcceptButtonTextRaw: string,
        public modalSelectedAcceptButtonTextRaw: string
    ) {
        super(
            id,
            sharedComponentId,
            isSharedComponent,
            title,
            EComponents.COOKIE_SETTINGS,
            style,
            classNames,
            components
        );
    }

    toPayload(): { [key: string]: any } {
        return super.toPayload({
            modalTitle: this.modalTitleRaw,
            modalParagraph: this.modalParagraphRaw,
            modalSettingsButtonText: this.modalSettingsButtonTextRaw,
            modalAllAcceptButtonText: this.modalAllAcceptButtonTextRaw,
            modalSelectedAcceptButtonText:
                this.modalSelectedAcceptButtonTextRaw,
        });
    }
}
