import Property from "@/entities/PrizificPageBuilder/Property";
import { EInputType } from "@/enums/PrizificPageBuilder/EInputType";
import { EProperty } from "@/enums/PrizificPageBuilder/EProperty";
import { ERoles } from "@/enums/PrizificPageBuilder/ERoles";
import { EState } from "@/enums/PrizificPageBuilder/EState";
import { EUnit } from "@/enums/PrizificPageBuilder/EUnit";

export const editProfileFormBackground = new Property(
    "Background color",
    "#FFFFFF00",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editProfileFormBackground"
);

export const editProfileFormItemLabelColor = new Property(
    "Label text color",
    "#3a89a2",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editProfileFormItemLabel"
);

export const editProfileFormItemLabelFontSize = new Property(
    "Label font size",
    14,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editProfileFormItemLabel",
    EUnit.PX,
    14,
    24
);

export const editProfileFormItemBackground = new Property(
    "Input background color",
    "#fff",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editProfileFormItemInput"
);

export const editProfileFormItemBorderColor = new Property(
    "Input border color",
    "#fff",
    EState.DEFAULT,
    EProperty.BORDER_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editProfileFormItemInput"
);

export const editProfileFormItemBorderRadius = new Property(
    "Input border radius",
    8,
    EState.DEFAULT,
    EProperty.BORDER_RADIUS,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editProfileFormItemInput",
    EUnit.PX,
    0,
    48
);

export const editProfileFormItemBorderWidth = new Property(
    "Input border width",
    1,
    EState.DEFAULT,
    EProperty.BORDER_WIDTH,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editProfileFormItemInput",
    EUnit.PX,
    0,
    8
);

export const editProfileFormCheckboxBackground = new Property(
    "Checkbox background",
    "#fff",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editProfileFormCheckbox"
);

export const editProfileFormCheckboxColor = new Property(
    "Checkbox check color",
    "#e44f60",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editProfileFormCheckbox"
);

export const editProfileFormCheckboxBorderRadius = new Property(
    "Checkbox border radius",
    8,
    EState.DEFAULT,
    EProperty.BORDER_RADIUS,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editProfileFormCheckbox",
    EUnit.PX,
    0,
    48
);

export const editProfileFormSubmitButtonPadding = new Property(
    "Submit button padding",
    "6px 8px",
    EState.DEFAULT,
    EProperty.PADDING,
    EInputType.TEXT,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editProfileFormSubmitButton"
);

export const editProfileFormSubmitButtonFontSize = new Property(
    "Submit button font size",
    14,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editProfileFormSubmitButton",
    EUnit.PX,
    14,
    24
);

export const editProfileFormSubmitButtonBackground = new Property(
    "Submit button background color",
    "#e44f60",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editProfileFormSubmitButton"
);

export const editProfileFormSubmitButtonTextColor = new Property(
    "Submit button text color",
    "#fdfcf9",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editProfileFormSubmitButton"
);

export const editProfileFormSubmitButtonBorderRadius = new Property(
    "Submit button border radius",
    8,
    EState.DEFAULT,
    EProperty.BORDER_RADIUS,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editProfileFormSubmitButton",
    EUnit.PX,
    0,
    48
);

export const editProfileFormSubmitButtonBackgroundHover = new Property(
    "Submit button background color",
    "#e44f60",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editProfileFormSubmitButtonHover"
);

export const editProfileFormSubmitButtonTextColorHover = new Property(
    "Submit button text color",
    "#fdfcf9",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "editProfileFormSubmitButtonHover"
);
