import FormItem from "@/entities/PrizificPageBuilder/components/FormItem";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { ERegistrationFormItem } from "@/enums/PrizificPageBuilder/ERegistrationFormItem";

export const addressFormItem = new FormItem(
    "-",
    "Address form item",
    [],
    "",
    [],
    "Address",
    "",
    "Address",
    "",
    ERegistrationFormItem.ADDRESS
);
