import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { RuntimeComponentParseError } from "@/error/RuntimeError";
import { AComponentFactory } from "../ComponentFactory";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import YoutubeVideo from "~~/entities/PrizificPageBuilder/sections/YoutubeVideo";
import Video from "~~/entities/PrizificPageBuilder/components/Video";
import Property from "~~/entities/PrizificPageBuilder/Property";
import { EState } from "~~/enums/PrizificPageBuilder/EState";
import { EProperty } from "~~/enums/PrizificPageBuilder/EProperty";
import { EInputType } from "~~/enums/PrizificPageBuilder/EInputType";
import { ERoles } from "~~/enums/PrizificPageBuilder/ERoles";
import { EUnit } from "~~/enums/PrizificPageBuilder/EUnit";
import TextButton from "~~/entities/PrizificPageBuilder/sections/TextButton";
import Img from "~~/entities/PrizificPageBuilder/components/Img";
import Button from "~~/entities/PrizificPageBuilder/components/Button";
import Maps from "~~/entities/PrizificPageBuilder/sections/Maps";

export class MapsFactory extends AComponentFactory<Maps> {
    getTypeName() {
        return EComponents.MAPS;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, [
            // "data.specific.sectionTitleCompiled",
            // "data.specific.sectionParagraphCompiled",
            // "data.specific.sectionTitle",
            // "data.specific.sectionParagraph",
            // "data.specific.images",
        ]);

        return new Maps(
            rawData.id,
            rawData.data.title,
            properties,
            rawData.data.classNames,
            subComponents,
            getConvertedText(rawData.data.specific.sectionTitleCompiled),
            getConvertedText(rawData.data.specific.sectionParagraphCompiled),
            rawData.data.specific.sectionTitle,
            rawData.data.specific.sectionParagraph,
            rawData.data.specific.images
                ? rawData.data.specific.images
                : [
                      {
                          src: "",
                          imageType: "extra-extra-large",
                          imageLabel: "1920px",
                      },
                      {
                          src: "",
                          imageType: "extra-large",
                          imageLabel: "1400px",
                      },
                      {
                          src: "",
                          imageType: "large",
                          imageLabel: "1200px",
                      },
                      {
                          src: "",
                          imageType: "medium",
                          imageLabel: "992px",
                      },
                      {
                          src: "",
                          imageType: "small",
                          imageLabel: "768px",
                      },
                      {
                          src: "",
                          imageType: "extra-small",
                          imageLabel: "576px",
                      },
                  ]
        );
    }

    createPlain() {
        const imagePin = new Img("-", "", false, "Pin", [], "", [], "");

        const buttonColor = new Property(
            "Button text color",
            "#3A89A2",
            EState.DEFAULT,
            EProperty.COLOR,
            EInputType.COLOR_PICKER,
            [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
            "button"
        );

        const buttonBackground = new Property(
            "Button background color",
            "",
            EState.DEFAULT,
            EProperty.BACKGROUND_COLOR,
            EInputType.COLOR_PICKER,
            [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
            "button"
        );

        const buttonFontSize = new Property(
            "Button font size",
            13,
            EState.DEFAULT,
            EProperty.FONT_SIZE,
            EInputType.NUMBER,
            [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
            "button",
            EUnit.PX,
            11,
            32
        );

        const buttonBorderRadius = new Property(
            "Button border radius",
            0,
            EState.DEFAULT,
            EProperty.BORDER_RADIUS,
            EInputType.NUMBER,
            [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
            "button",
            EUnit.PX,
            0,
            48
        );

        const buttonPadding = new Property(
            "Button padding",
            "6px 8px",
            EState.DEFAULT,
            EProperty.PADDING,
            EInputType.TEXT,
            [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
            "button"
        );

        const button = new Button(
            "-",
            null,
            false,
            "Button",
            [
                buttonColor,
                buttonBackground,
                buttonFontSize,
                buttonBorderRadius,
                buttonPadding,
            ],
            "",
            [],
            "",
            ""
        );

        const sectionFontFamily = new Property(
            "Font family",
            "",
            EState.DEFAULT,
            EProperty.FONT_FAMILY,
            EInputType.FONT_SELECT,
            [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
            "wrapper"
        );

        const sectionBackground = new Property(
            "Background color",
            "#fdfcf9",
            EState.DEFAULT,
            EProperty.BACKGROUND_COLOR,
            EInputType.COLOR_PICKER,
            [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
            "wrapper"
        );

        const sectionTitleColor = new Property(
            "Title text color",
            "#444444",
            EState.DEFAULT,
            EProperty.COLOR,
            EInputType.COLOR_PICKER,
            [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
            "title"
        );

        const sectionFontSize = new Property(
            "Title font size",
            32,
            EState.DEFAULT,
            EProperty.FONT_SIZE,
            EInputType.NUMBER,
            [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
            "title",
            EUnit.PX,
            16,
            48
        );

        const sectionParagraphColor = new Property(
            "Paragraph text color",
            "#444444",
            EState.DEFAULT,
            EProperty.COLOR,
            EInputType.COLOR_PICKER,
            [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
            "paragraph"
        );

        const sectionParagraphFontSize = new Property(
            "Paragraph font size",
            16,
            EState.DEFAULT,
            EProperty.FONT_SIZE,
            EInputType.NUMBER,
            [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
            "paragraph",
            EUnit.PX,
            16,
            48
        );

        return new Maps(
            "-",
            "Map section",
            [
                sectionFontFamily,
                sectionBackground,
                sectionTitleColor,
                sectionFontSize,
                sectionParagraphColor,
                sectionParagraphFontSize,
            ],
            "",
            [imagePin],
            getConvertedText("Default title"),
            getConvertedText("Default paragraph"),
            "Default title",
            "Default paragraph"
        );
    }
}
