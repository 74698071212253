import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import EditProfile from "@/entities/PrizificPageBuilder/sections/EditProfile";
import { RuntimeComponentParseError } from "@/error/RuntimeError";
import { AComponentFactory } from "../ComponentFactory";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { editProfile } from "~~/entities/pages/edit-profile/sections/EditProfile/EditProfile";

export class EditProfileFactory extends AComponentFactory<EditProfile> {
    getTypeName() {
        return EComponents.EDIT_PROFILE;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, [
            "data.specific.sectionTitleCompiled",
            "data.specific.sectionParagraphCompiled",
            "data.specific.sectionTitle",
            "data.specific.sectionParagraph",
        ]);

        return new EditProfile(
            rawData.id,
            rawData.data.title,
            usePropertiesMerger(properties, editProfile.style),
            rawData.data.classNames,
            subComponents,
            getConvertedText(rawData.data.specific.sectionTitleCompiled),
            getConvertedText(rawData.data.specific.sectionParagraphCompiled),
            rawData.data.specific.sectionTitle,
            rawData.data.specific.sectionParagraph
        );
    }
}
