import { Quill } from "@vueup/vue-quill";
const Embed = Quill.import("blots/embed");
class ParamsBlot extends Embed {
    static tagName = "SPAN";
    static blotName = "params";

    static create(data: any) {
        if (!data) {
            return super.create(false);
        }

        const node = super.create();

        const selectedParam = data.params.filter(
            (param: any) => param.name === data.selectedParamName
        );

        if (selectedParam !== undefined) {
            const contentNode = document.createElement("span");
            contentNode.textContent = `   ${selectedParam[0].name}   `;

            node.classList.add("tag-param");
            node.setAttribute("data-value", selectedParam[0].tag);
            node.setAttribute("contenteditable", false);
            node.setAttribute("spellcheck", false);
            node.setAttribute("autocomplete", "off");
            node.setAttribute("autocorrect", "off");
            node.setAttribute("autocapitalize", "off");
            node.appendChild(contentNode);
            // node.innerHTML = `&nbsp;&nbsp;&nbsp;${selectedParam[0].name}&nbsp;&nbsp;&nbsp;`;

            return node;
        }

        return;
    }

    static value(domNode: any) {
        // const spans = domNode.querySelectorAll("span:last-child");
        // console.log(spans[spans.length - 1].innerHTML.trim());

        // if (spans.length > 2) {
        //     const lastSpan = spans[spans.length - 1];

        //     domNode.innerHTML = lastSpan;
        // }

        return domNode;
    }

    static formats(node: any) {
        // const spans = node.querySelectorAll("span:last-child");
        // console.log(spans[spans.length - 1].innerHTML.trim());

        // if (spans.length > 2) {
        //     const lastSpan = spans[spans.length - 1];

        //     node.innerHTML = lastSpan;
        // }

        return node;
    }
}

export default ParamsBlot;
