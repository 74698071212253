import FormItem from "@/entities/PrizificPageBuilder/components/FormItem";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { ERegistrationFormItem } from "~~/enums/PrizificPageBuilder/ERegistrationFormItem";

export const firstNameFormItem = new FormItem(
    "-",
    "First Name form item",
    [],
    "",
    [],
    "Keresztnév",
    "",
    "Keresztnév",
    "",
    ERegistrationFormItem.FIRSTNAME
);
