import NavItem from "@/entities/PrizificPageBuilder/components/NavItem";

import {
    winnersNavItemFontSize,
    winnersNavItemPadding,
    winnersNavItemBorderRadius,
    winnersNavItemTextColor,
    winnersNavItemTextColorHover,
    winnersNavItemBackgroundColor,
    winnersNavItemBackgroundColorHover,
} from "./WinnersNavItemProperties";

export const winnersNavItem = new NavItem(
    "-",
    null,
    false,
    "Nyertesek",
    [
        winnersNavItemFontSize,
        winnersNavItemPadding,
        winnersNavItemBorderRadius,
        winnersNavItemTextColor,
        winnersNavItemTextColorHover,
        winnersNavItemBackgroundColor,
        winnersNavItemBackgroundColorHover,
    ],
    "",
    [],
    "Nyertesek",
    "Nyertesek",
    "/nyertesek",
    false
);
