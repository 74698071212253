import Property from "~~/entities/PrizificPageBuilder/Property";

export function usePropertiesMerger(
    arrayOld: Property[],
    arrayExtended: Property[]
): Property[] {
    arrayExtended.forEach((item2, index) => {
        const existsInArrayOne = arrayOld.some(
            (item1) => item1.label === item2.label
        );
        if (!existsInArrayOne) {
            arrayOld.splice(index, 0, item2);
        }
    });

    return arrayOld;
}
