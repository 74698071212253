import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { IForm } from "~~/interfaces/PrizificPageBuilder/IForm";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import AComponent from "../AComponent";
import { EHiveLoginText } from "~~/enums/PrizificPageBuilder/EHiveLoginText";

export default class Text
    extends AComponent<EComponents.TEXT>
    implements IComponent<EComponents.TEXT>
{
    constructor(
        id: string,
        title: string,
        style: Array<IProperty>,
        classNames: string,
        components: Array<IComponent<EComponents>>,
        public text: string,
        public textRaw: string,
        public belongsTo: EHiveLoginText | string
    ) {
        super(id, title, EComponents.TEXT, style, classNames, components);
    }

    toPayload(): { [key: string]: any } {
        return super.toPayload({
            text: this.text,
            textRaw: this.textRaw,
            belongsTo: this.belongsTo,
        });
    }
}
