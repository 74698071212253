import { usePagesStore } from "~~/store/pages";

export default defineNuxtRouteMiddleware(async (to, from) => {

    //TODO: add error handling!
    if (to.matched) {
        const matched = to.matched.find((match) => {
            if (match.name == "index") {
                return true
            } else if (match.name == "slug-page") {
                return true
            } else if (match.name == "slug-index") {
                return true;
            }

        })

        if (!matched) {
            return true
        }
    }

    const pageType = useActualPageType()

    const pageStore = usePagesStore();
    try {
        if (process.server) {

            await pageStore.getPageFromServer(pageType)
        } else if (process.client) {

            let page = pageStore.getPage(pageType);

            if (!page) {
                await pageStore.getPageFromServer(pageType)
            }
        }
    } catch (e) {
        console.log("e", e);
    }
});