import GameSteps from "@/entities/PrizificPageBuilder/sections/GameSteps";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { firstGameStep } from "./components/FirstGameStep/FirstGameStep";
import { secondGameStep } from "./components/SecondGameStep/SecondGameStep";
import { thirdGameStep } from "./components/ThirdGameStep/ThirdGameStep";
import { fourthGameStep } from "./components/FourthGameStep/FourthGameStep";
import {
    gamestepsFontFamily,
    gameStepsBackground,
    gameStepsTitleColor,
    gameStepsFontSize,
    gameStepsParagraphColor,
    gameStepsParagraphFontSize,
} from "./GameStepsProperties";

export const gameSteps = new GameSteps(
    "-",
    "Game steps wrapper",
    [
        gamestepsFontFamily,
        gameStepsBackground,
        gameStepsTitleColor,
        gameStepsFontSize,
        gameStepsParagraphColor,
        gameStepsParagraphFontSize,
    ],
    "",
    [firstGameStep, secondGameStep, thirdGameStep, fourthGameStep],
    "A játék lépései",
    "Ha nyersz, napi és heti nyeremények esetén azonnal értesítünk, főnyeremény esetén pedig a promóció végén emailben kapsz róla értesítést.",
    "A játék lépései",
    "Ha nyersz, napi és heti nyeremények esetén azonnal értesítünk, főnyeremény esetén pedig a promóció végén emailben kapsz róla értesítést.",
    ""
);
