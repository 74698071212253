import { ECodeUploadFormItem } from "~/enums/PrizificPageBuilder/ECodeUploadFormItem";
import { EModal } from "~/enums/PrizificPageBuilder/EModal";
import { ERecipeUploadFormItem } from "~/enums/PrizificPageBuilder/ERecipeUploadFormItem";
import { EShippingAddressFormItem } from "~/enums/PrizificPageBuilder/EShippingAddressFormItem";
import { ELoginFormItem } from "~~/enums/PrizificPageBuilder/ELoginFormItem";
import { ERegistrationFormItem } from "~~/enums/PrizificPageBuilder/ERegistrationFormItem";

type TFormItemTexts = {
    label: string;
    placeholder: string;
};

/**
 * Get form item texts
 * @param components
 * @param belongsTo
 * @returns
 */
export const getFormItemText = (
    component: any,
    belongsTo:
        | ECodeUploadFormItem
        | EShippingAddressFormItem
        | ERecipeUploadFormItem
        | ELoginFormItem
        | ERegistrationFormItem
): TFormItemTexts | null => {
    if (component == undefined) {
        return null;
    }

    const formItemTexts = { label: "", placeholder: "", checkboxText: "" };

    if (!component || !component.components) {
        return null;
    }
    component.components.forEach((component: any) => {
        if (component.belongsTo === belongsTo) {
            formItemTexts.label = component.label;
            formItemTexts.placeholder = component.placeholder;
            formItemTexts.checkboxText = component.checkboxText
                ? component.checkboxText
                : "";
        }
    });

    return formItemTexts;
};

type TModalText = {
    title: string;
    description: string;
    leftButton: string;
    rightButton: string;
};

/**
 * Get form item texts
 * @param components
 * @param belongsTo
 * @returns
 */
export const getGroupText = (
    component: any,
    belongsTo: EModal
): TModalText | null => {
    if (component == undefined) {
        return null;
    }

    const modalTexts = {
        title: "",
        description: "",
        leftButton: "",
        rightButton: "",
    };

    component.components.forEach((component: any) => {
        if (component.belongsTo === belongsTo) {
            modalTexts.title = component.modalTitle;
            modalTexts.description = component.modalDescription;
            modalTexts.leftButton = component.modalLeftButtonText;
            modalTexts.rightButton = component.modalRightButtonText;
        }
    });

    return modalTexts;
};

export const getConvertedText = (rawText: string): any => {
    // if (rawText == undefined) {
    //     rawText = "";
    // }
    // if (process.client) {

    //     const parsed = new DOMParser().parseFromString(rawText, "text/html");

    //     //Remove span tag-param
    //     parsed.querySelectorAll(".tag-param")?.forEach((tag: any) => {
    //         let variable = tag.getAttribute("data-value") as string;

    //         tag.replaceWith(variable);
    //     });

    //     //Remove P tags
    //     parsed.querySelectorAll("p")?.forEach((pTag: any) => {
    //         pTag.replaceWith(pTag.innerHTML);
    //     });

    //     return parsed
    //         .querySelector("body")
    //         ?.innerHTML.toString()
    //         .replaceAll("&lt;strong&gt;", "<strong>")
    //         .replaceAll("&lt;/strong&gt;", "</strong>");
    // } else if (process.server) {
    //     return rawText.replace(/<\/?[^>]+>/gi, "");
    // }

    // return "";
    return rawText;
};
