import FormItem from "@/entities/PrizificPageBuilder/components/FormItem";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { ERegistrationFormItem } from "~~/enums/PrizificPageBuilder/ERegistrationFormItem";

export const dateOfBirthYearFormItem = new FormItem(
    "-",
    "Date of birth year form item",
    [],
    "",
    [],
    "Date of birth",
    "Year",
    "Date of birth",
    "Year",
    ERegistrationFormItem.DATE_OF_BIRTH_YEAR
);
