import Footer from "@/entities/PrizificPageBuilder/sections/Footer";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { brandLogo } from "./components/BrandLogo/BrandLogo";
import { campaignRule } from "./components/CampaignRule/CampaignRule";
import { codeUploadNavItem } from "./components/CodeUploadNavItem/CodeUploadNavItem";
import { cookie } from "./components/Cookie/Cookie";
import { homeNavItem } from "./components/HomeNavItem/HomeNavItem";
import { legalNotice } from "./components/LegalNotice/LegalNotice";
import { privacyPolicy } from "./components/PrivacyPolicy/PrivacyPolicy";
import { prizesNavItem } from "./components/PrizesNavItem/PrizesNavItem";
import { promoProductsNavItem } from "./components/PromoProductsNavItem/PromoProductsNavItem";
import { socialFacebook } from "./components/SocialFacebook/SocialFacebook";
import { socialInstagram } from "./components/SocialInstagram/SocialInstagram";
import { socialTiktok } from "./components/SocialTiktok/SocialTiktok";
import { socialYoutube } from "./components/SocialYoutube/SocialYoutube";
import { termsOfUse } from "./components/TermsOfUse/TermsOfUse";
import { winnersNavItem } from "./components/WinnersNavItem/WinnersNavItem";
import {
    footerFontFamily,
    footerBackground,
    footerFirstGroupTitleColor,
    footerFirstGroupTitleFontSize,
    footerSecondtGroupTitleColor,
    footerSecondGroupTitleFontSize,
    footerThirdGroupTitleColor,
    footerThirdGroupTitleFontSize,
} from "./FooterProperties";

export const footer = new Footer(
    "-",
    null,
    false,
    "Footer",
    [
        footerFontFamily,
        footerBackground,
        footerFirstGroupTitleColor,
        footerFirstGroupTitleFontSize,
        footerSecondtGroupTitleColor,
        footerSecondGroupTitleFontSize,
        footerThirdGroupTitleColor,
        footerThirdGroupTitleFontSize,
    ],
    "",
    [
        brandLogo,
        campaignRule,
        privacyPolicy,
        cookie,
        termsOfUse,
        legalNotice,
        homeNavItem,
        codeUploadNavItem,
        winnersNavItem,
        prizesNavItem,
        promoProductsNavItem,
        socialFacebook,
        socialYoutube,
        socialInstagram,
        socialTiktok,
    ],
    "",
    "",
    "",
    "",
    "",
    "",
    ""
);
