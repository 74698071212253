import Modal from "@/entities/PrizificPageBuilder/components/Modal";
import { EModal } from "~~/enums/PrizificPageBuilder/EModal";

export const apCodeShortTimeGateModal = new Modal(
    "-",
    "Ap code short time gate modal",
    [],
    "",
    [],
    "title",
    "description",
    "",
    "button",
    "title",
    "description",
    "",
    "button",
    EModal.APCODESHORTTIMEGATE
);
