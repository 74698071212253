import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import NavItem from "@/entities/PrizificPageBuilder/components/NavItem";
import { AComponentFactory } from "../ComponentFactory";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";

export class NavItemFactory extends AComponentFactory<NavItem> {
    getTypeName() {
        return EComponents.NAV_ITEM;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, [
            // "data.specific.textCompiled",
            "data.specific.text",
            "data.specific.to",
            // "data.specific.link",
            // "data.specific.icon",
        ]);

        return new NavItem(
            rawData.id,
            rawData.sharedComponentId ?? null,
            rawData.isSharedComponent ?? false,
            rawData.data.title,
            properties,
            rawData.data.classNames,
            subComponents,
            getConvertedText(rawData.data.specific.textCompiled) ?? "",
            rawData.data.specific.text,
            rawData.data.specific.to,
            rawData.data.specific.link,
            rawData.data.specific.icon
        );
    }
}
