import NavItem from "@/entities/PrizificPageBuilder/components/NavItem";

import {
    cookieFontSize,
    cookiePadding,
    cookieBorderRadius,
    cookieTextColor,
    cookieTextColorHover,
    cookieBackgroundColor,
    cookieBackgroundColorHover,
} from "./CookieProperties";

export const cookie = new NavItem(
    "-",
    null,
    false,
    "Cookie tájékoztató",
    [
        cookieFontSize,
        cookiePadding,
        cookieTextColor,
        cookieTextColorHover,
        cookieBackgroundColor,
        cookieBackgroundColorHover,
    ],
    "",
    [],
    "Cookie tájékoztató",
    "Cookie tájékoztató",
    "",
    true
);
