import Form from "@/entities/PrizificPageBuilder/components/Form";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";

import {
    shippingAddressFormPadding,
    shippingAddressFormBackground,
    shippingAddressFormItemLabelColor,
    shippingAddressFormItemLabelFontSize,
    shippingAddressFormItemBorderColor,
    shippingAddressFormItemBorderWidth,
    shippingAddressFormItemBorderRadius,
    shippingAddressFormSubmitButtonPadding,
    shippingAddressFormSubmitButtonFontSize,
    shippingAddressFormSubmitButtonBackground,
    shippingAddressFormSubmitButtonTextColor,
    shippingAddressFormSubmitButtonBorderRadius,
    shippingAddressFormSubmitButtonBackgroundHover,
    shippingAddressFormSubmitButtonTextColorHover,
    shippingAddressFormSubmitButtonBorderWidth,
    shippingAddressFormSubmitButtonBorderColor,
    shippingAddressFormSubmitButtonBorderWidthHover,
    shippingAddressFormSubmitButtonBorderColorHover,
} from "./ShippingAddressFormProperties";
import { shippingCityFormItem } from "./ShippingCityFormItem";
import { shippingMessageForTheCourierFormItem } from "./ShippingMessageForTheCourierFormItem";
import { shippingNameFormItem } from "./ShippingNameFormItem";
import { shippingPhoneFormItem } from "./ShippingPhoneFormItem";
import { shippingStreetFormItem } from "./ShippingStreetFormItem";
import { shippingZipCodeFormItem } from "./ShippingZipCodeFormItem";

export const shippingAddressForm = new Form(
    "-",
    "Shipping address form",
    [
        shippingAddressFormPadding,
        shippingAddressFormBackground,
        shippingAddressFormItemLabelColor,
        shippingAddressFormItemLabelFontSize,
        shippingAddressFormItemBorderColor,
        shippingAddressFormItemBorderWidth,
        shippingAddressFormItemBorderRadius,
        shippingAddressFormSubmitButtonPadding,
        shippingAddressFormSubmitButtonFontSize,
        shippingAddressFormSubmitButtonBackground,
        shippingAddressFormSubmitButtonTextColor,
        shippingAddressFormSubmitButtonBorderRadius,
        shippingAddressFormSubmitButtonBorderWidth,
        shippingAddressFormSubmitButtonBorderColor,
        shippingAddressFormSubmitButtonBackgroundHover,
        shippingAddressFormSubmitButtonTextColorHover,
        shippingAddressFormSubmitButtonBorderWidthHover,
        shippingAddressFormSubmitButtonBorderColorHover,
    ],
    "",
    [
        shippingNameFormItem,
        shippingZipCodeFormItem,
        shippingCityFormItem,
        shippingStreetFormItem,
        shippingPhoneFormItem,
        shippingMessageForTheCourierFormItem,
    ]
);
