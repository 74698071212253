import AgeGate from "@/entities/PrizificPageBuilder/sections/AgeGate";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import {
    ageGateBackground,
    ageGateBrandLogoWidth,
    ageGateCodesTitleFontSize,
    ageGateParagraphColor,
    ageGateParagraphFontSize,
    ageGateTitleColor,
} from "./AgeGateProperties";
import { ageGateBrandLogo } from "./components/AgeGateBrandLogo";
import { ageGateForm } from "./components/AgeGateForm";

export const ageGate = new AgeGate(
    "-",
    "Edit Age Gate",
    [
        ageGateBackground,
        ageGateBrandLogoWidth,
        ageGateTitleColor,
        ageGateCodesTitleFontSize,
        ageGateParagraphColor,
        ageGateParagraphFontSize,
    ],
    "",
    [ageGateBrandLogo, ageGateForm],
    "HELLO ITT",
    "Fantörpikus, hogy végre itt vagy. Tölts fel sokat, vidd el a nyereményeket!",
    "HELLO ITT",
    "Fantörpikus, hogy végre itt vagy. Tölts fel sokat, vidd el a nyereményeket!"
);
