import YourPrize from "~~/entities/PrizificPageBuilder/sections/YourPrize";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import {
    yourPrizeFontFamily,
    yourPrizePadding,
    yourPrizeBackground,
    yourPrizeTitleColor,
    yourPrizeTitleFontSize,
    yourPrizeNameFontSize,
    yourPrizeNameColor,
    yourPrizeImageWidth,
} from "./YourPrizeProperties";

export const yourPrize = new YourPrize(
    "",
    "Your prize",
    [
        yourPrizeFontFamily,
        yourPrizePadding,
        yourPrizeBackground,
        yourPrizeTitleFontSize,
        yourPrizeTitleColor,
        yourPrizeNameFontSize,
        yourPrizeNameColor,
        yourPrizeImageWidth,
    ],
    "",
    [],
    "Nyereményed:",
    "",
    "Nyereményed:",
    ""
);
