import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { ISection } from "@/interfaces/PrizificPageBuilder/ISection";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import AComponent from "../AComponent";

export default class FeedbackPageMessage
    extends AComponent<
        | EComponents.WINNER_FEEDBACK_PAGE_MESSAGE
        | EComponents.ACTIVATE_REGISTRATION_FEEDBACK_PAGE_MESSAGE
        | EComponents.WE_ARE_SORRY_FEEDBACK_PAGE_MESSAGE
        | EComponents.THANK_YOU_FEEDBACK_PAGE_MESSAGE
    >
    implements
        IComponent<
            | EComponents.WINNER_FEEDBACK_PAGE_MESSAGE
            | EComponents.ACTIVATE_REGISTRATION_FEEDBACK_PAGE_MESSAGE
            | EComponents.WE_ARE_SORRY_FEEDBACK_PAGE_MESSAGE
            | EComponents.THANK_YOU_FEEDBACK_PAGE_MESSAGE
        >,
        ISection
{
    constructor(
        id: string,
        title: string,
        style: Array<IProperty>,
        classNames: string,
        components: Array<
            IComponent<
                | EComponents.WINNER_FEEDBACK_PAGE_MESSAGE
                | EComponents.ACTIVATE_REGISTRATION_FEEDBACK_PAGE_MESSAGE
                | EComponents.WE_ARE_SORRY_FEEDBACK_PAGE_MESSAGE
                | EComponents.THANK_YOU_FEEDBACK_PAGE_MESSAGE
            >
        >,
        public sectionTitle?: string,
        public sectionParagraph?: string,
        public sectionTitleRaw?: string,
        public sectionParagraphRaw?: string
    ) {
        super(
            id,
            title,
            EComponents.WINNER_FEEDBACK_PAGE_MESSAGE ||
                EComponents.ACTIVATE_REGISTRATION_FEEDBACK_PAGE_MESSAGE ||
                EComponents.WE_ARE_SORRY_FEEDBACK_PAGE_MESSAGE ||
                EComponents.THANK_YOU_FEEDBACK_PAGE_MESSAGE,
            style,
            classNames,
            components
        );
    }

    toPayload(): { [key: string]: any } {
        return super.toPayload({
            sectionTitle: this.sectionTitleRaw,
            sectionParagraph: this.sectionParagraphRaw,
        });
    }
}
