import { IPage } from "@/interfaces/PrizificPageBuilder/IPage";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import AComponent from "./AComponent";
import { IProperty } from "~~/interfaces/PrizificPageBuilder/IProperty";

export default class Page<T extends EComponents>
    extends AComponent<T>
    implements IComponent<T>, IPage<T>
{
    constructor(
        id: string | number,
        title: string,
        type: T,
        style: Array<IProperty>,
        classNames: string,
        components: Array<IComponent<EComponents>>,
        public globalConfig: object
    ) {
        
        super(id, title, type, style, classNames, components);
    }
}
