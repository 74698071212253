import EditPassword from "~~/entities/PrizificPageBuilder/sections/EditPassword";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { editPasswordForm } from "./components/EditPasswordForm";
import {
    editPasswordBackground,
    editPasswordTitleColor,
    editPasswordTitleFontSize,
    editPasswordGoToButtonPadding,
    editPasswordGoToButtonFontSize,
    editPasswordGoToButtonBackground,
    editPasswordGoToButtonTextColor,
    editPasswordGoToButtonBorderRadius,
    editPasswordGoToButtonBackgroundHover,
    editPasswordGoToButtonTextColorHover,
} from "./EditPasswordProperties";

export const editPassword = new EditPassword(
    "-",
    "Edit password",
    [
        editPasswordBackground,
        editPasswordTitleColor,
        editPasswordTitleFontSize,
        editPasswordGoToButtonPadding,
        editPasswordGoToButtonFontSize,
        editPasswordGoToButtonBackground,
        editPasswordGoToButtonTextColor,
        editPasswordGoToButtonBorderRadius,
        editPasswordGoToButtonBackgroundHover,
        editPasswordGoToButtonTextColorHover,
    ],
    "",
    [editPasswordForm],
    "Jelszó módosítása",
    "",
    "Jelszó módosítása",
    ""
);
