import { defineNuxtPlugin } from "#app";
import { Quill, QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import "@vueup/vue-quill/dist/vue-quill.bubble.css";
import ParamsBlot from "~/quillModules/ParamsBlot";
import ParamsModule from "~~/quillModules/ParamsModule";
export default defineNuxtPlugin((nuxtApp) => {
    Quill.register(ParamsBlot);
    Quill.register("modules/params", ParamsModule);

    nuxtApp.vueApp.component("QuillEditor", QuillEditor);
});
