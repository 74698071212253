import Form from "@/entities/PrizificPageBuilder/components/Form";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import {
    registrationFormBackground,
    registrationFormItemLabelColor,
    registrationFormItemLabelFontSize,
    registrationFormItemBorderColor,
    registrationFormItemBorderWidth,
    registrationFormItemBorderRadius,
    registrationFormSubmitButtonPadding,
    registrationFormSubmitButtonFontSize,
    registrationFormSubmitButtonBackground,
    registrationFormSubmitButtonTextColor,
    registrationFormSubmitButtonBorderRadius,
    registrationFormSubmitButtonBackgroundHover,
    registrationFormSubmitButtonTextColorHover,
    registrationFormCheckboxBorderRadius,
    registrationFormCheckboxColor,
    registrationFormCheckboxBackground,
} from "./RegistrationFormProperties";
import { emailFormItem } from "./EmailFormItem";
import { passwordFormItem } from "./PasswordFormItem";
import { privacyPolicyFormItem } from "./PrivacyPolicyFormItem";
import { edmSubscriptionFormItem } from "./EdmSubscriptionFormItem";
import { overEighteenFormItem } from "./OverEighteenFormItem";

export const registrationForm = new Form(
    "-",
    "Registration form",

    [
        registrationFormBackground,
        registrationFormItemLabelColor,
        registrationFormItemLabelFontSize,
        registrationFormItemBorderColor,
        registrationFormItemBorderWidth,
        registrationFormItemBorderRadius,
        registrationFormCheckboxBackground,
        registrationFormCheckboxColor,
        registrationFormCheckboxBorderRadius,
        registrationFormSubmitButtonPadding,
        registrationFormSubmitButtonFontSize,
        registrationFormSubmitButtonBorderRadius,
        registrationFormSubmitButtonBackground,
        registrationFormSubmitButtonTextColor,
        registrationFormSubmitButtonBackgroundHover,
        registrationFormSubmitButtonTextColorHover,
    ],
    "",
    [
        emailFormItem,
        passwordFormItem,
        privacyPolicyFormItem,
        edmSubscriptionFormItem,
        overEighteenFormItem,
    ]
);
