import Modal from "@/entities/PrizificPageBuilder/components/Modal";
import { EModal } from "~~/enums/PrizificPageBuilder/EModal";

export const emailOrPasswordIncorrectModal = new Modal(
    "-", 
    "Email or password incorrect modal",
    [],
    "",
    [],
    "Email vagy a jelszó helytelen cím",
    "Email vagy a jelszó helytelen leírás",
    "",
    "Bezár",
    "Email vagy a jelszó helytelen cím",
    "Email vagy a jelszó helytelen leírás",
    "",
    "Bezár",
    EModal.EMAILORPASSWORDINCORRECT
);
