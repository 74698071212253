import Property from "@/entities/PrizificPageBuilder/Property";
import { EInputType } from "@/enums/PrizificPageBuilder/EInputType";
import { EProperty } from "@/enums/PrizificPageBuilder/EProperty";
import { ERoles } from "@/enums/PrizificPageBuilder/ERoles";
import { EState } from "@/enums/PrizificPageBuilder/EState";
import { EUnit } from "@/enums/PrizificPageBuilder/EUnit";

export const codeUploadFormBackground = new Property(
    "Background color",
    "#FFFFFF00",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormBackground"
);

export const codeUploadFormItemLabelColor = new Property(
    "Label text color",
    "#fdfcf9",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormItemLabel"
);

export const codeUploadFormItemLabelFontSize = new Property(
    "Label font size",
    14,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormItemLabel",
    EUnit.PX,
    14,
    24
);

export const codeUploadFormItemBackground = new Property(
    "Input background color",
    "#fff",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormItemInput"
);

export const codeUploadFormItemTextColor = new Property(
    "Input text color",
    "#fff",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormItemInput"
);

export const codeUploadFormItemPlaceholderColor = new Property(
    "Input placeholder text color",
    "#fff",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormItemInputPlaceholder"
);

export const codeUploadFormItemReceiptImageCloseIconColor = new Property(
    "Receipt image close icon color",
    "#fff",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormItemReceiptImage"
);

export const codeUploadFormItemPadding = new Property(
    "Input padding",
    "2px 12px",
    EState.DEFAULT,
    EProperty.PADDING,
    EInputType.TEXT,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormItemInput"
);

export const codeUploadFormItemHeight = new Property(
    "Input height",
    12,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormItemInput",
    EUnit.PX,
    12,
    48
);

export const codeUploadFormItemFontSize = new Property(
    "Input font size",
    14,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormItemInput",
    EUnit.PX,
    14,
    24
);

export const codeUploadFormItemBorderColor = new Property(
    "Input border color",
    "#fff",
    EState.DEFAULT,
    EProperty.BORDER_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormItemInput"
);

export const codeUploadFormItemBorderRadius = new Property(
    "Input border radius",
    8,
    EState.DEFAULT,
    EProperty.BORDER_RADIUS,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormItemInput",
    EUnit.PX,
    0,
    48
);

export const codeUploadFormItemBorderWidth = new Property(
    "Input border width",
    1,
    EState.DEFAULT,
    EProperty.BORDER_WIDTH,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormItemInput",
    EUnit.PX,
    0,
    8
);

export const codeUploadFormItemToggleButtonBackground = new Property(
    "Toggle button background color",
    "#fff",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormItemToggleButton"
);

export const codeUploadFormItemToggleButtonColor = new Property(
    "Toggle button color",
    "#e44f60",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormItemToggleButton"
);

export const codeUploadFormItemToggleButtonBorderColor = new Property(
    "Toggle button border color",
    "#e44f60",
    EState.DEFAULT,
    EProperty.BORDER_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormItemToggleButton"
);

export const codeUploadFormItemToggleButtonActiveColor = new Property(
    "Toggle button active color",
    "#fdfcf9",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormItemToggleButtonActive"
);

export const codeUploadFormItemToggleButtonActiveBackground = new Property(
    "Toggle button active background color",
    "#e44f60",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormItemToggleButtonActive"
);

export const codeUploadFormItemToggleButtonBorderRadius = new Property(
    "Toggle button border radius",
    4,
    EState.DEFAULT,
    EProperty.BORDER_RADIUS,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormItemToggleButton",
    EUnit.PX,
    0,
    48
);

export const codeUploadFormSubmitButtonPadding = new Property(
    "Submit button padding",
    "6px 8px",
    EState.DEFAULT,
    EProperty.PADDING,
    EInputType.TEXT,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormSubmitButton"
);

export const codeUploadFormSubmitButtonFontSize = new Property(
    "Submit button font size",
    14,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormSubmitButton",
    EUnit.PX,
    14,
    24
);

export const codeUploadFormSubmitButtonBackground = new Property(
    "Submit button background color",
    "#e44f60",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormSubmitButton"
);

export const codeUploadFormSubmitButtonTextColor = new Property(
    "Submit button text color",
    "#fdfcf9",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormSubmitButton"
);

export const codeUploadFormSubmitButtonBorderColor = new Property(
    "Submit button border color",
    "#fdfcf9",
    EState.DEFAULT,
    EProperty.BORDER_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormSubmitButton"
);

export const codeUploadFormSubmitButtonBorderWidth = new Property(
    "Submit button border width",
    1,
    EState.DEFAULT,
    EProperty.BORDER_WIDTH,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormSubmitButton",
    EUnit.PX,
    0,
    8
);

export const codeUploadFormSubmitButtonBorderRadius = new Property(
    "Submit button border radius",
    8,
    EState.DEFAULT,
    EProperty.BORDER_RADIUS,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormSubmitButton",
    EUnit.PX,
    0,
    48
);

export const codeUploadFormSubmitButtonBackgroundHover = new Property(
    "Submit button hover background color",
    "#e44f60",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormSubmitButtonHover"
);

export const codeUploadFormSubmitButtonTextColorHover = new Property(
    "Submit button hover text color",
    "#fdfcf9",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormSubmitButtonHover"
);

export const codeUploadFormSubmitButtonBorderColorHover = new Property(
    "Submit button hover border color",
    "#fdfcf9",
    EState.DEFAULT,
    EProperty.BORDER_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormSubmitButtonHover"
);

export const codeUploadFormSubmitButtonBorderWidthHover = new Property(
    "Submit button hover border width",
    1,
    EState.DEFAULT,
    EProperty.BORDER_WIDTH,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormSubmitButtonHover",
    EUnit.PX,
    0,
    8
);

export const codeUploadFormExampleTextColor = new Property(
    "Example text color",
    "#fdfcf9",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormExample"
);

export const codeUploadFormExampleHighlightColor = new Property(
    "Example recipe highlight border color",
    "#e44f60",
    EState.DEFAULT,
    EProperty.BORDER_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadFormExampleHighlight"
);
