import Property from "~/entities/PrizificPageBuilder/Property";
import { EInputType } from "~/enums/PrizificPageBuilder/EInputType";
import { EProperty } from "~/enums/PrizificPageBuilder/EProperty";
import { ERoles } from "~/enums/PrizificPageBuilder/ERoles";
import { EState } from "~/enums/PrizificPageBuilder/EState";
import { EUnit } from "~/enums/PrizificPageBuilder/EUnit";

export const modalSelectedBackground = new Property(
    "Modal selected background color",
    "#e44f60",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalPurchasePromotionProducts"
);

export const modalSelectedTextColor = new Property(
    "Modal selected text color",
    "#fff",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalPurchasePromotionProducts"
);

export const modalSelectedBorderColor = new Property(
    "Modal selected border color",
    "#fff",
    EState.DEFAULT,
    EProperty.BORDER_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalPurchasePromotionProducts"
);
