import Modal from "@/entities/PrizificPageBuilder/components/Modal";
import { EModal } from "~~/enums/PrizificPageBuilder/EModal";

export const forgottenPasswordModal = new Modal(
    "-",
    "Forgotten password modal",
    [],
    "",
    [],
    "Elfelejtett jelszó cím",
    "Elfelejtett jelszó leírás",
    "",
    "Bezár",
    "Elfelejtett jelszó cím",
    "Elfelejtett jelszó leírás",
    "",
    "Bezár",
    EModal.FORGOTTENPASSWORD
);
