import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { AComponentFactory } from "../ComponentFactory";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import Text from "~~/entities/PrizificPageBuilder/components/Text";

export class TextFactory extends AComponentFactory<Text> {
    getTypeName() {
        return EComponents.TEXT;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, [
            // "data.specific.modalTitleCompiled",
            // "data.specific.modalDescriptionCompiled",
            // "data.specific.modalLeftButtonTextCompiled",
            // "data.specific.modalRightButtonTextCompiled",
            // "data.specific.modalTitle",
            // "data.specific.modalDescription",
            // "data.specific.modalLeftButtonText",
            // "data.specific.modalRightButtonText",
            // "data.specific.belongsTo",
        ]);
        //console.log("rawData.data", rawData.data);

        let textCompiled = "-";
        let text = "-";
        let belongsTo = "-";

        if (rawData.data.specific) {
            textCompiled =  getConvertedText(rawData.data.specific.textRaw);
            text = rawData.data.specific.textRawCompiled
            belongsTo = rawData.data.specific.belongsTo
        }

        return new Text(
            rawData.id,
            rawData.data.title,
            properties,
            rawData.data.classNames,
            subComponents,
            textCompiled,
            text,
            belongsTo
        );
    }

    createPlain() {
        return new Text(
            "-",
            "Text",
            [],
            "",
            [],
            "-",
            "-",
            "",
        );
    }
}
