import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { ISection } from "@/interfaces/PrizificPageBuilder/ISection";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import AComponent from "../AComponent";

export default class Draw
    extends AComponent<EComponents.DRAW>
    implements IComponent<EComponents.DRAW>, ISection
{
    constructor(
        id: string,
        title: string,
        style: Array<IProperty>,
        classNames: string,
        components: Array<IComponent<EComponents>>,
        public sectionTitle?: string,
        public sectionParagraph?: string,
        public sectionTitleRaw?: string,
        public sectionParagraphRaw?: string
    ) {
        super(id, title, EComponents.DRAW, style, classNames, components);
    }

    toPayload(): { [key: string]: any } {
        return super.toPayload({
            sectionTitle: this.sectionTitleRaw,
            sectionParagraph: this.sectionParagraphRaw,
        });
    }
}
