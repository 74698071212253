import Property from "@/entities/PrizificPageBuilder/Property";
import { EInputType } from "@/enums/PrizificPageBuilder/EInputType";
import { EProperty } from "@/enums/PrizificPageBuilder/EProperty";
import { ERoles } from "@/enums/PrizificPageBuilder/ERoles";
import { EState } from "@/enums/PrizificPageBuilder/EState";
import { EUnit } from "~~/enums/PrizificPageBuilder/EUnit";

export const gamestepsFontFamily = new Property(
    "Font family",
    "",
    EState.DEFAULT,
    EProperty.FONT_FAMILY,
    EInputType.FONT_SELECT,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "wrapper"
);

export const gameStepsBackground = new Property(
    "Background color",
    "#fdfcf9",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "wrapper"
);

export const gameStepsTitleColor = new Property(
    "Title text color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "title"
);

export const gameStepsFontSize = new Property(
    "Title font size",
    32,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "title",
    EUnit.PX,
    16,
    48
);

export const gameStepsParagraphColor = new Property(
    "Paragraph text color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "paragraph"
);

export const gameStepsParagraphFontSize = new Property(
    "Paragraph font size",
    16,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "paragraph",
    EUnit.PX,
    16,
    48
);

export const stepColor = new Property(
    "Step text color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "step-text-color"
);

export const stepIncrementWidth = new Property(
    "Step icrement width",
    25,
    EState.DEFAULT,
    EProperty.WIDTH,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "step-icrement-width",
    EUnit.PX,
    25,
    56
);

export const stepIncrementHeight = new Property(
    "Step icrement height",
    25,
    EState.DEFAULT,
    EProperty.HEIGHT,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "step-icrement-height",
    EUnit.PX,
    25,
    56
);

export const stepIncrementBackground = new Property(
    "Step icrement background color",
    "#f9c357",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "step-icrement-background"
);

export const stepIncrementColor = new Property(
    "Step icrement text color",
    "#000000",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "step-icrement-color"
);

export const stepIncrementBorderRadius = new Property(
    "Step icrement border radius",
    5,
    EState.DEFAULT,
    EProperty.BORDER_RADIUS,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "step-icrement-border-radius",
    EUnit.PX,
    0,
    24
);

export const stepFontSize = new Property(
    "Step font size",
    16,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "step-increment-font-size",
    EUnit.PX,
    16,
    48
);
