import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { AComponentFactory } from "../ComponentFactory";
import WinnersList from "@/entities/PrizificPageBuilder/components/WinnersList";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { winnersList } from "~~/entities/pages/winners/sections/Winners/components/WinnersList";

export class WinnersListFactory extends AComponentFactory<WinnersList> {
    getTypeName() {
        return EComponents.WINNERS_LIST;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, []);

        return new WinnersList(
            rawData.id,
            rawData.data.title,
            usePropertiesMerger(properties, winnersList.style),
            rawData.data.classNames,
            subComponents,
            rawData?.data?.specific?.mainlyPrizeTabText,
            getConvertedText(
                rawData?.data?.specific?.mainlyPrizeTabTextCompiled
            ),
            rawData?.data?.specific?.monthlyPrizeTabText,
            getConvertedText(
                rawData?.data?.specific?.monthlyPrizeTabTextCompiled
            ),
            rawData?.data?.specific?.weeklyPrizeTabText,
            getConvertedText(
                rawData?.data?.specific?.weeklyPrizeTabTextCompiled
            ),
            rawData?.data?.specific?.dailyPrizeTabText,
            getConvertedText(rawData?.data?.specific?.dailyPrizeTabTextCompiled)
        );
    }
}
