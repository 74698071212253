import { useCampaignDataStore } from "@/store/campaign/campaignData";

export default defineNuxtRouteMiddleware(async (to, from) => {
    const campaignDataStore = useCampaignDataStore();
    
    if (process.server) {
        
        await campaignDataStore.getCampaignData();
        
    }else if(process.client){
        
    }    
});