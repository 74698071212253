import FormItem from "@/entities/PrizificPageBuilder/components/FormItem";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { ERegistrationFormItem } from "~~/enums/PrizificPageBuilder/ERegistrationFormItem";

export const privacyPolicyFormItem = new FormItem(
    "-",
    "Privacy Policy form item",
    [],
    "",
    [],
    "",
    "",
    "",
    "",
    ERegistrationFormItem.PRIVACY_POLICY,
    "",
    ""
);
