import NavItem from "@/entities/PrizificPageBuilder/components/NavItem";
//import { logoInstagram } from "ionicons/icons";

import {
    socialInstagramWidth,
    socialInstagramFillColor,
    socialInstagramFillColorHover,
} from "./SocialInstagramProperties";

export const socialInstagram = new NavItem(
    "-",
    null,
    false,
    "Instagram",
    [
        socialInstagramWidth,
        socialInstagramFillColor,
        socialInstagramFillColorHover,
    ],
    "",
    [],
    "",
    "",
    "",
    true,
    "instagram"
);
