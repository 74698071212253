import FormItem from "@/entities/PrizificPageBuilder/components/FormItem";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { EEditProfileFormItem } from "~~/enums/PrizificPageBuilder/EEditProfileFormItem";

export const lastNameFormItem = new FormItem(
    "-",
    "Last name form item",
    [],
    "",
    [],
    "Vezetéknév",
    "",
    "Vezetéknév",
    "",
    EEditProfileFormItem.LAST_NAME
);
