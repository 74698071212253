import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { ISection } from "@/interfaces/PrizificPageBuilder/ISection";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import AComponent from "../AComponent";
import ASharedComponent from "../ASharedComponent";
import { IImg } from "~~/interfaces/PrizificPageBuilder/IImg";

export default class Contact
    extends ASharedComponent<EComponents.CONTACT>
    implements IComponent<EComponents.CONTACT>, ISection
{
    constructor(
        id: string,
        sharedComponentId: string | number | null,
        isSharedComponent: boolean,
        title: string,
        style: Array<IProperty>,
        classNames: string,
        components: Array<IComponent<EComponents>>,
        public sectionTitle?: string,
        public sectionParagraph?: string,
        public sectionTitleRaw?: string,
        public sectionParagraphRaw?: string,
        public images?: Array<IImg>
    ) {
        super(
            id,
            sharedComponentId,
            isSharedComponent,
            title,
            EComponents.CONTACT || EComponents.CONTACT_WITH_PRIVACY_POLICY,
            style,
            classNames,
            components
        );
    }

    toPayload(): { [key: string]: any } {
        return super.toPayload({
            sectionTitle: this.sectionTitleRaw,
            sectionParagraph: this.sectionParagraphRaw,
            images: this.images,
        });
    }
}
