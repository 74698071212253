import FormItem from "@/entities/PrizificPageBuilder/components/FormItem";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { EShippingAddressFormItem } from "~~/enums/PrizificPageBuilder/EShippingAddressFormItem";

export const shippingZipCodeFormItem = new FormItem(
    "-",
    "Zip code form item",
    [],
    "",
    [],
    "Irányítószám",
    "",
    "Irányítószám",
    "",
    EShippingAddressFormItem.ZIP_CODE
);
