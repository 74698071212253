import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { EInputType } from "@/enums/PrizificPageBuilder/EInputType";
import { EProperty } from "@/enums/PrizificPageBuilder/EProperty";
import { ERoles } from "@/enums/PrizificPageBuilder/ERoles";
import { EState } from "@/enums/PrizificPageBuilder/EState";
import { EUnit } from "@/enums/PrizificPageBuilder/EUnit";

export default class Property implements IProperty {
    constructor(
        public label: string,
        public value: string | number,
        public state: EState,
        public property: EProperty,
        public inputType: EInputType,
        public roles: Array<ERoles>,
        public belongsTo: string,
        public unit?: EUnit,
        public min?: number,
        public max?: number
    ) { }



    toPayload(): { [key: string]: any } {
        return {
            label: this.label,
            value: this.value,
            state: this.state,
            property: this.property,
            inputType: this.inputType,
            roles: this.roles,
            belongsTo: this.belongsTo,
            unit: this.unit,
            min: this.min,
            max: this.max,
        }
    }
}
