import Modal from "@/entities/PrizificPageBuilder/components/Modal";
import { EModal } from "~~/enums/PrizificPageBuilder/EModal";

export const almostDoneModal = new Modal(
    "-",
    "Almost done modal",
    [],
    "",
    [],
    "Majdnem kész cím",
    "Majdnem kész leírás",
    "",
    "Bezár",
    "Majdnem kész cím",
    "Majdnem kész leírás",
    "",
    "Bezár",
    EModal.ALMOSTDONE
);
