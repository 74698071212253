import { default as _91page_933bafnaULV5Meta } from "/opt/atlassian/pipelines/agent/build/pages/[page].vue?macro=true";
import { default as indexr8l2bqgye6Meta } from "/opt/atlassian/pipelines/agent/build/pages/elmultal-tizennyolc/index.vue?macro=true";
import { default as indexrMJAom2L5CMeta } from "/opt/atlassian/pipelines/agent/build/pages/felhasznalo-aktivacio/index.vue?macro=true";
import { default as indexXIixO6t6G1Meta } from "/opt/atlassian/pipelines/agent/build/pages/feltoltott-kodjaim/index.vue?macro=true";
import { default as healt_45checkp01HrDj1i7Meta } from "/opt/atlassian/pipelines/agent/build/pages/healt-check.vue?macro=true";
import { default as indexOD9t3F2bSJMeta } from "/opt/atlassian/pipelines/agent/build/pages/index.vue?macro=true";
import { default as index167t0PIAz2Meta } from "/opt/atlassian/pipelines/agent/build/pages/jelszo-emlekezteto/index.vue?macro=true";
import { default as indexTw0WVER1uQMeta } from "/opt/atlassian/pipelines/agent/build/pages/jelszo-modositas/index.vue?macro=true";
import { default as indexCIupzWBAGJMeta } from "/opt/atlassian/pipelines/agent/build/pages/kostoltatas/index.vue?macro=true";
import { default as indexbTcRzrc2tXMeta } from "/opt/atlassian/pipelines/agent/build/pages/koszonjuk/index.vue?macro=true";
import { default as indexjX44bquYetMeta } from "/opt/atlassian/pipelines/agent/build/pages/nem-vagy-eleg-idos/index.vue?macro=true";
import { default as _91id_93FWAZ0SQ7GXMeta } from "/opt/atlassian/pipelines/agent/build/pages/nyertel/[id].vue?macro=true";
import { default as indexNCsD0WBSkEMeta } from "/opt/atlassian/pipelines/agent/build/pages/nyertesek/index.vue?macro=true";
import { default as indexP69PoGHeRoMeta } from "/opt/atlassian/pipelines/agent/build/pages/o-auth/index.vue?macro=true";
import { default as indexMBoY3eEA6oMeta } from "/opt/atlassian/pipelines/agent/build/pages/profil-modositas/index.vue?macro=true";
import { default as indexfrS6SuAxQ8Meta } from "/opt/atlassian/pipelines/agent/build/pages/sajnaljuk/index.vue?macro=true";
import { default as _91id_93L6ena8BoqhMeta } from "/opt/atlassian/pipelines/agent/build/pages/sorsolas/[id].vue?macro=true";
export default [
  {
    name: _91page_933bafnaULV5Meta?.name ?? "page",
    path: _91page_933bafnaULV5Meta?.path ?? "/:page",
    meta: _91page_933bafnaULV5Meta || {},
    alias: _91page_933bafnaULV5Meta?.alias || [],
    redirect: _91page_933bafnaULV5Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[page].vue").then(m => m.default || m)
  },
  {
    name: indexr8l2bqgye6Meta?.name ?? "elmultal-tizennyolc",
    path: indexr8l2bqgye6Meta?.path ?? "/elmultal-tizennyolc",
    meta: indexr8l2bqgye6Meta || {},
    alias: indexr8l2bqgye6Meta?.alias || [],
    redirect: indexr8l2bqgye6Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/elmultal-tizennyolc/index.vue").then(m => m.default || m)
  },
  {
    name: indexrMJAom2L5CMeta?.name ?? "felhasznalo-aktivacio",
    path: indexrMJAom2L5CMeta?.path ?? "/felhasznalo-aktivacio",
    meta: indexrMJAom2L5CMeta || {},
    alias: indexrMJAom2L5CMeta?.alias || [],
    redirect: indexrMJAom2L5CMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/felhasznalo-aktivacio/index.vue").then(m => m.default || m)
  },
  {
    name: indexXIixO6t6G1Meta?.name ?? "feltoltott-kodjaim",
    path: indexXIixO6t6G1Meta?.path ?? "/feltoltott-kodjaim",
    meta: indexXIixO6t6G1Meta || {},
    alias: indexXIixO6t6G1Meta?.alias || [],
    redirect: indexXIixO6t6G1Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/feltoltott-kodjaim/index.vue").then(m => m.default || m)
  },
  {
    name: healt_45checkp01HrDj1i7Meta?.name ?? "healt-check",
    path: healt_45checkp01HrDj1i7Meta?.path ?? "/healt-check",
    meta: healt_45checkp01HrDj1i7Meta || {},
    alias: healt_45checkp01HrDj1i7Meta?.alias || [],
    redirect: healt_45checkp01HrDj1i7Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/healt-check.vue").then(m => m.default || m)
  },
  {
    name: indexOD9t3F2bSJMeta?.name ?? "index",
    path: indexOD9t3F2bSJMeta?.path ?? "/",
    meta: indexOD9t3F2bSJMeta || {},
    alias: indexOD9t3F2bSJMeta?.alias || [],
    redirect: indexOD9t3F2bSJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index167t0PIAz2Meta?.name ?? "jelszo-emlekezteto",
    path: index167t0PIAz2Meta?.path ?? "/jelszo-emlekezteto",
    meta: index167t0PIAz2Meta || {},
    alias: index167t0PIAz2Meta?.alias || [],
    redirect: index167t0PIAz2Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/jelszo-emlekezteto/index.vue").then(m => m.default || m)
  },
  {
    name: indexTw0WVER1uQMeta?.name ?? "jelszo-modositas",
    path: indexTw0WVER1uQMeta?.path ?? "/jelszo-modositas",
    meta: indexTw0WVER1uQMeta || {},
    alias: indexTw0WVER1uQMeta?.alias || [],
    redirect: indexTw0WVER1uQMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/jelszo-modositas/index.vue").then(m => m.default || m)
  },
  {
    name: indexCIupzWBAGJMeta?.name ?? "kostoltatas",
    path: indexCIupzWBAGJMeta?.path ?? "/kostoltatas",
    meta: indexCIupzWBAGJMeta || {},
    alias: indexCIupzWBAGJMeta?.alias || [],
    redirect: indexCIupzWBAGJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/kostoltatas/index.vue").then(m => m.default || m)
  },
  {
    name: indexbTcRzrc2tXMeta?.name ?? "koszonjuk",
    path: indexbTcRzrc2tXMeta?.path ?? "/koszonjuk",
    meta: indexbTcRzrc2tXMeta || {},
    alias: indexbTcRzrc2tXMeta?.alias || [],
    redirect: indexbTcRzrc2tXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/koszonjuk/index.vue").then(m => m.default || m)
  },
  {
    name: indexjX44bquYetMeta?.name ?? "nem-vagy-eleg-idos",
    path: indexjX44bquYetMeta?.path ?? "/nem-vagy-eleg-idos",
    meta: indexjX44bquYetMeta || {},
    alias: indexjX44bquYetMeta?.alias || [],
    redirect: indexjX44bquYetMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/nem-vagy-eleg-idos/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93FWAZ0SQ7GXMeta?.name ?? "nyertel-id",
    path: _91id_93FWAZ0SQ7GXMeta?.path ?? "/nyertel/:id",
    meta: _91id_93FWAZ0SQ7GXMeta || {},
    alias: _91id_93FWAZ0SQ7GXMeta?.alias || [],
    redirect: _91id_93FWAZ0SQ7GXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/nyertel/[id].vue").then(m => m.default || m)
  },
  {
    name: indexNCsD0WBSkEMeta?.name ?? "nyertesek",
    path: indexNCsD0WBSkEMeta?.path ?? "/nyertesek",
    meta: indexNCsD0WBSkEMeta || {},
    alias: indexNCsD0WBSkEMeta?.alias || [],
    redirect: indexNCsD0WBSkEMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/nyertesek/index.vue").then(m => m.default || m)
  },
  {
    name: indexP69PoGHeRoMeta?.name ?? "o-auth",
    path: indexP69PoGHeRoMeta?.path ?? "/o-auth",
    meta: indexP69PoGHeRoMeta || {},
    alias: indexP69PoGHeRoMeta?.alias || [],
    redirect: indexP69PoGHeRoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/o-auth/index.vue").then(m => m.default || m)
  },
  {
    name: indexMBoY3eEA6oMeta?.name ?? "profil-modositas",
    path: indexMBoY3eEA6oMeta?.path ?? "/profil-modositas",
    meta: indexMBoY3eEA6oMeta || {},
    alias: indexMBoY3eEA6oMeta?.alias || [],
    redirect: indexMBoY3eEA6oMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/profil-modositas/index.vue").then(m => m.default || m)
  },
  {
    name: indexfrS6SuAxQ8Meta?.name ?? "sajnaljuk",
    path: indexfrS6SuAxQ8Meta?.path ?? "/sajnaljuk",
    meta: indexfrS6SuAxQ8Meta || {},
    alias: indexfrS6SuAxQ8Meta?.alias || [],
    redirect: indexfrS6SuAxQ8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/sajnaljuk/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93L6ena8BoqhMeta?.name ?? "sorsolas-id",
    path: _91id_93L6ena8BoqhMeta?.path ?? "/sorsolas/:id",
    meta: _91id_93L6ena8BoqhMeta || {},
    alias: _91id_93L6ena8BoqhMeta?.alias || [],
    redirect: _91id_93L6ena8BoqhMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/sorsolas/[id].vue").then(m => m.default || m)
  },
  {
    name: _91page_933bafnaULV5Meta?.name ?? "slug-page",
    path: _91page_933bafnaULV5Meta?.path ?? "/:slug//:page",
    meta: _91page_933bafnaULV5Meta || {},
    alias: _91page_933bafnaULV5Meta?.alias || [],
    redirect: _91page_933bafnaULV5Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[page].vue").then(m => m.default || m)
  },
  {
    name: indexr8l2bqgye6Meta?.name ?? "slug-elmultal-tizennyolc",
    path: indexr8l2bqgye6Meta?.path ?? "/:slug//elmultal-tizennyolc",
    meta: indexr8l2bqgye6Meta || {},
    alias: indexr8l2bqgye6Meta?.alias || [],
    redirect: indexr8l2bqgye6Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/elmultal-tizennyolc/index.vue").then(m => m.default || m)
  },
  {
    name: indexrMJAom2L5CMeta?.name ?? "slug-felhasznalo-aktivacio",
    path: indexrMJAom2L5CMeta?.path ?? "/:slug//felhasznalo-aktivacio",
    meta: indexrMJAom2L5CMeta || {},
    alias: indexrMJAom2L5CMeta?.alias || [],
    redirect: indexrMJAom2L5CMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/felhasznalo-aktivacio/index.vue").then(m => m.default || m)
  },
  {
    name: indexXIixO6t6G1Meta?.name ?? "slug-feltoltott-kodjaim",
    path: indexXIixO6t6G1Meta?.path ?? "/:slug//feltoltott-kodjaim",
    meta: indexXIixO6t6G1Meta || {},
    alias: indexXIixO6t6G1Meta?.alias || [],
    redirect: indexXIixO6t6G1Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/feltoltott-kodjaim/index.vue").then(m => m.default || m)
  },
  {
    name: healt_45checkp01HrDj1i7Meta?.name ?? "slug-healt-check",
    path: healt_45checkp01HrDj1i7Meta?.path ?? "/:slug//healt-check",
    meta: healt_45checkp01HrDj1i7Meta || {},
    alias: healt_45checkp01HrDj1i7Meta?.alias || [],
    redirect: healt_45checkp01HrDj1i7Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/healt-check.vue").then(m => m.default || m)
  },
  {
    name: indexOD9t3F2bSJMeta?.name ?? "slug-index",
    path: indexOD9t3F2bSJMeta?.path ?? "/:slug//",
    meta: indexOD9t3F2bSJMeta || {},
    alias: indexOD9t3F2bSJMeta?.alias || [],
    redirect: indexOD9t3F2bSJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index167t0PIAz2Meta?.name ?? "slug-jelszo-emlekezteto",
    path: index167t0PIAz2Meta?.path ?? "/:slug//jelszo-emlekezteto",
    meta: index167t0PIAz2Meta || {},
    alias: index167t0PIAz2Meta?.alias || [],
    redirect: index167t0PIAz2Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/jelszo-emlekezteto/index.vue").then(m => m.default || m)
  },
  {
    name: indexTw0WVER1uQMeta?.name ?? "slug-jelszo-modositas",
    path: indexTw0WVER1uQMeta?.path ?? "/:slug//jelszo-modositas",
    meta: indexTw0WVER1uQMeta || {},
    alias: indexTw0WVER1uQMeta?.alias || [],
    redirect: indexTw0WVER1uQMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/jelszo-modositas/index.vue").then(m => m.default || m)
  },
  {
    name: indexCIupzWBAGJMeta?.name ?? "slug-kostoltatas",
    path: indexCIupzWBAGJMeta?.path ?? "/:slug//kostoltatas",
    meta: indexCIupzWBAGJMeta || {},
    alias: indexCIupzWBAGJMeta?.alias || [],
    redirect: indexCIupzWBAGJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/kostoltatas/index.vue").then(m => m.default || m)
  },
  {
    name: indexbTcRzrc2tXMeta?.name ?? "slug-koszonjuk",
    path: indexbTcRzrc2tXMeta?.path ?? "/:slug//koszonjuk",
    meta: indexbTcRzrc2tXMeta || {},
    alias: indexbTcRzrc2tXMeta?.alias || [],
    redirect: indexbTcRzrc2tXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/koszonjuk/index.vue").then(m => m.default || m)
  },
  {
    name: indexjX44bquYetMeta?.name ?? "slug-nem-vagy-eleg-idos",
    path: indexjX44bquYetMeta?.path ?? "/:slug//nem-vagy-eleg-idos",
    meta: indexjX44bquYetMeta || {},
    alias: indexjX44bquYetMeta?.alias || [],
    redirect: indexjX44bquYetMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/nem-vagy-eleg-idos/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93FWAZ0SQ7GXMeta?.name ?? "slug-nyertel-id",
    path: _91id_93FWAZ0SQ7GXMeta?.path ?? "/:slug//nyertel/:id",
    meta: _91id_93FWAZ0SQ7GXMeta || {},
    alias: _91id_93FWAZ0SQ7GXMeta?.alias || [],
    redirect: _91id_93FWAZ0SQ7GXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/nyertel/[id].vue").then(m => m.default || m)
  },
  {
    name: indexNCsD0WBSkEMeta?.name ?? "slug-nyertesek",
    path: indexNCsD0WBSkEMeta?.path ?? "/:slug//nyertesek",
    meta: indexNCsD0WBSkEMeta || {},
    alias: indexNCsD0WBSkEMeta?.alias || [],
    redirect: indexNCsD0WBSkEMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/nyertesek/index.vue").then(m => m.default || m)
  },
  {
    name: indexP69PoGHeRoMeta?.name ?? "slug-o-auth",
    path: indexP69PoGHeRoMeta?.path ?? "/:slug//o-auth",
    meta: indexP69PoGHeRoMeta || {},
    alias: indexP69PoGHeRoMeta?.alias || [],
    redirect: indexP69PoGHeRoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/o-auth/index.vue").then(m => m.default || m)
  },
  {
    name: indexMBoY3eEA6oMeta?.name ?? "slug-profil-modositas",
    path: indexMBoY3eEA6oMeta?.path ?? "/:slug//profil-modositas",
    meta: indexMBoY3eEA6oMeta || {},
    alias: indexMBoY3eEA6oMeta?.alias || [],
    redirect: indexMBoY3eEA6oMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/profil-modositas/index.vue").then(m => m.default || m)
  },
  {
    name: indexfrS6SuAxQ8Meta?.name ?? "slug-sajnaljuk",
    path: indexfrS6SuAxQ8Meta?.path ?? "/:slug//sajnaljuk",
    meta: indexfrS6SuAxQ8Meta || {},
    alias: indexfrS6SuAxQ8Meta?.alias || [],
    redirect: indexfrS6SuAxQ8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/sajnaljuk/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93L6ena8BoqhMeta?.name ?? "slug-sorsolas-id",
    path: _91id_93L6ena8BoqhMeta?.path ?? "/:slug//sorsolas/:id",
    meta: _91id_93L6ena8BoqhMeta || {},
    alias: _91id_93L6ena8BoqhMeta?.alias || [],
    redirect: _91id_93L6ena8BoqhMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/sorsolas/[id].vue").then(m => m.default || m)
  }
]