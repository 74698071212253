import Group from "~/entities/PrizificPageBuilder/components/Group";
import {
    formBackground,
    toggleButtonColor,
    toggleButtonBackground,
    toggleButtonBorderColor,
    toggleButtonActiveColor,
    toggleButtonActiveBackground,
    toggleButtonBorderRadius,
    formCheckboxBackground,
    formCheckboxBorderRadius,
    formCheckboxColor,
    formItemBorderColor,
    formItemBorderRadius,
    formItemBorderWidth,
    formItemLabelColor,
    formItemLabelFontSize,
    formSubmitButtonBackground,
    formSubmitButtonBackgroundHover,
    formSubmitButtonBorderRadius,
    formSubmitButtonFontSize,
    formSubmitButtonPadding,
    formSubmitButtonTextColor,
    formSubmitButtonTextColorHover,
} from "./UserConsentFormProperties";
import { userConsentLoginForm } from "./UserConsentLogin";
import { userConsentRegistrationForm } from "./UserConsentRegistrationForm";
import Text from "~~/entities/PrizificPageBuilder/components/Text";

export const userConsentingGroup = new Group(
    "-",
    "Group for user consent forms",
    [
        formBackground,
        toggleButtonColor,
        toggleButtonBackground,
        toggleButtonBorderColor,
        toggleButtonActiveColor,
        toggleButtonActiveBackground,
        toggleButtonBorderRadius,
        formItemLabelColor,
        formItemLabelFontSize,
        formItemBorderColor,
        formItemBorderWidth,
        formItemBorderRadius,
        formCheckboxBackground,
        formCheckboxColor,
        formCheckboxBorderRadius,
        formSubmitButtonPadding,
        formSubmitButtonFontSize,
        formSubmitButtonBorderRadius,
        formSubmitButtonBackground,
        formSubmitButtonTextColor,
        formSubmitButtonBackgroundHover,
        formSubmitButtonTextColorHover,
    ],
    "",
    [
        userConsentLoginForm,
        userConsentRegistrationForm,
        new Text(
            "-",
            "Login title",
            [],
            "",
            [],
            "Heló!",
            "Heló!",
            "loginTitle"
        ),
        new Text(
            "-",
            "Login paragraph",
            [],
            "",
            [],
            "Ha már korábban regisztráltál. Itt tudsz bejelentkezni.",
            "Ha már korábban regisztráltál. Itt tudsz bejelentkezni.",
            "loginParagrah"
        ),
        new Text(
            "-",
            "Registration title",
            [],
            "",
            [],
            "Heló!",
            "Heló!",
            "registrationTitle"
        ),
        new Text(
            "-",
            "Registration paragraph",
            [],
            "",
            [],

            "Nem vagy még tag? Itt regisztrálhatsz!",
            "Nem vagy még tag? Itt regisztrálhatsz!",
            "registrationParagraph"
        ),
        new Text(
            "-",
            "Accept user consent title",
            [],
            "",
            [],
            "Lorem ipsum dolor sit amet",
            "Lorem ipsum dolor sit amet",
            "acceptUserConsentTitle"
        ),
        new Text(
            "-",
            "Accept user consent paragraph",
            [],
            "",
            [],
            "Lorem ipsum dolor sit amet",
            "Lorem ipsum dolor sit amet",
            "acceptUserConsentParagraph"
        ),
    ]
);
