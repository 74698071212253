import NavItem from "@/entities/PrizificPageBuilder/components/NavItem";

import {
    promoProductsNavItemFontSize,
    promoProductsNavItemPadding,
    promoProductsNavItemBorderRadius,
    promoProductsNavItemTextColor,
    promoProductsNavItemTextColorHover,
    promoProductsNavItemBackgroundColor,
    promoProductsNavItemBackgroundColorHover,
} from "./PromoProductsNavItemProperties";

export const promoProductsNavItem = new NavItem(
    "-",
    null,
    false,
    "Résztvevő termékek",
    [
        promoProductsNavItemFontSize,
        promoProductsNavItemPadding,
        promoProductsNavItemBorderRadius,
        promoProductsNavItemTextColor,
        promoProductsNavItemTextColorHover,
        promoProductsNavItemBackgroundColor,
        promoProductsNavItemBackgroundColorHover,
    ],
    "",
    [],
    "Résztvevő termékek",
    "Résztvevő termékek",
    "/#resztvevo-termekek",
    false
);
