import { Quill } from "@vueup/vue-quill";

export default class ParamsModule {
    private container: any;

    constructor(protected quill: Quill, protected options: any) {
        this.container = document.querySelector(options.container);
        quill.on("text-change", this.update.bind(this));
        this.update();

        const toolbar = quill.getModule("toolbar");

        /**
         * Create handler for params
         */
        toolbar.addHandler("params", (selectedParamName: any) => {
            const cursorPos = this.quill.selection?.lastRange?.index;
            const data = {
                selectedParamName: selectedParamName,
                params: options,
            };

            if (selectedParamName) {
                quill.insertEmbed(cursorPos, "params", data);
                quill.insertText(cursorPos + 1, " ");
                quill.setSelection(cursorPos + 2);
            }
        });
    }

    update() {
        this.quill.theme.buildButtons;
    }
}
