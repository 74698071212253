import NavItem from "@/entities/PrizificPageBuilder/components/NavItem";
//import { logoFacebook } from "ionicons/icons";

import {
    socialFacebookWidth,
    socialFacebookFillColor,
    socialFacebookFillColorHover,
} from "./SocialFacebookProperties";

export const socialFacebook = new NavItem(
    "-",
    null,
    false,
    "Facebook",
    [
        socialFacebookWidth,
        socialFacebookFillColor,
        socialFacebookFillColorHover,
    ],
    "",
    [],
    "",
    "",
    "",
    true,
    "facebook"
);
