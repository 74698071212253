import Button from "@/entities/PrizificPageBuilder/components/Button";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import {
    contactButtonColor,
    contactButtonBackground,
    contactButtonFontSize,
    contactButtonBorderRadius,
    contactButtonPadding,
} from "./ContactButtonProperties";

export const contactButton = new Button(
    "-",
    null,
    false,
    "Contact button",
    [
        contactButtonColor,
        contactButtonBackground,
        contactButtonFontSize,
        contactButtonBorderRadius,
        contactButtonPadding,
    ],
    "",
    [],
    "Kapcsolatfelvétel",
    "Kapcsolatfelvétel"
);
