import FormItem from "@/entities/PrizificPageBuilder/components/FormItem";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { ENewPasswordFormItem } from "~~/enums/PrizificPageBuilder/ENewPasswordFormItem";

export const passwordFormItem = new FormItem(
    "-",
    "Password form item",
    [],
    "",
    [],
    "Új jelszó",
    "",
    "Új jelszó",
    "",
    ENewPasswordFormItem.PASSWORD
);
