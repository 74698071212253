import NavItem from "@/entities/PrizificPageBuilder/components/NavItem";

import {
    navItemWinnersFontSize,
    navItemWinnersPadding,
    navItemWinnersBorderRadius,
    navItemWinnersTextColor,
    navItemWinnersTextColorHover,
    navItemWinnersBackgroundColor,
    navItemWinnersBackgroundColorHover,
} from "./NavItemWinnersProperties";

export const navItemWinners = new NavItem(
    "-",
    null,
    false,
    "Nyertesek",
    [
        navItemWinnersFontSize,
        navItemWinnersPadding,
        navItemWinnersBorderRadius,
        navItemWinnersTextColor,
        navItemWinnersTextColorHover,
        navItemWinnersBackgroundColor,
        navItemWinnersBackgroundColorHover,
    ],
    "fw-bold",
    [],
    "Nyertesek",
    "Nyertesek",
    "nyertesek",
    false
);
