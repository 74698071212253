import FormItem from "@/entities/PrizificPageBuilder/components/FormItem";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { ECodeUploadFormItem } from "~/enums/PrizificPageBuilder/ECodeUploadFormItem";

export const receiptNumberFormItem = new FormItem(
    "-",
    "Receipt number form item",
    [],
    "",
    [],
    "Nyugtaszám",
    "Add meg a nyugtaszámot (pl. 0003/0001)",
    "Nyugtaszám",
    "Add meg a nyugtaszámot (pl. 0003/0001)",
    ECodeUploadFormItem.RECEIPT_NUMBER
);
