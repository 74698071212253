import NavItem from "@/entities/PrizificPageBuilder/components/NavItem";
import {
    navItemCodeUploadFontSize,
    navItemCodeUploadPadding,
    navItemCodeUploadBorderRadius,
    navItemCodeUploadTextColor,
    navItemCodeUploadTextColorHover,
    navItemCodeUploadBackgroundColor,
    navItemCodeUploadBackgroundColorHover,
} from "./NavItemCodeUploadProperties";
import NavItemWithThreeState from "~~/entities/PrizificPageBuilder/components/NavItemWithThreeState";

export const navItemCodeUpload = new NavItemWithThreeState(
    "-",
    null,
    false,
    "Kódfeltöltés",
    [
        navItemCodeUploadFontSize,
        navItemCodeUploadPadding,
        navItemCodeUploadBorderRadius,
        navItemCodeUploadTextColor,
        navItemCodeUploadTextColorHover,
        navItemCodeUploadBackgroundColor,
        navItemCodeUploadBackgroundColorHover,
    ],
    "fw-bold",
    [],
    "Regisztráció",
    "Regisztráció",
    "Kódfeltöltés",
    "Kódfeltöltés",
    "Belépés",
    "Belépés",
    "#regisztracio",
    "#kodfeltoltes",
    "#user-consenting",
    false
);
