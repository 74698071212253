import EditProfile from "~~/entities/PrizificPageBuilder/sections/EditProfile";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { editProfileForm } from "./components/EditProfileForm";
import {
    editProfileFontFamily,
    editProfileBackground,
    editProfileTitleColor,
    editProfileTitleFontSize,
    editProfileGoToButtonPadding,
    editProfileGoToButtonFontSize,
    editProfileGoToButtonBackground,
    editProfileGoToButtonTextColor,
    editProfileGoToButtonBorderRadius,
    editProfileGoToButtonBackgroundHover,
    editProfileGoToButtonTextColorHover,
    editProfileDeleteButtonPadding,
    editProfileDeleteButtonFontSize,
    editProfileDeleteButtonBackground,
    editProfileDeleteButtonTextColor,
    editProfileDeleteButtonBorderRadius,
    editProfileDeleteButtonBackgroundHover,
    editProfileDeleteButtonTextColorHover,
} from "./EditProfileProperties";

export const editProfile = new EditProfile(
    "-",
    "Edit Profile",
    [
        editProfileFontFamily,
        editProfileBackground,
        editProfileTitleColor,
        editProfileTitleFontSize,
        editProfileGoToButtonPadding,
        editProfileGoToButtonFontSize,
        editProfileGoToButtonBackground,
        editProfileGoToButtonTextColor,
        editProfileGoToButtonBorderRadius,
        editProfileGoToButtonBackgroundHover,
        editProfileGoToButtonTextColorHover,
        editProfileDeleteButtonPadding,
        editProfileDeleteButtonFontSize,
        editProfileDeleteButtonBackground,
        editProfileDeleteButtonTextColor,
        editProfileDeleteButtonBorderRadius,
        editProfileDeleteButtonBackgroundHover,
        editProfileDeleteButtonTextColorHover,
    ],
    "",
    [editProfileForm],
    "Profil módosítása",
    "",
    "Profil módosítása",
    ""
);
