import ShippingAddress from "~~/entities/PrizificPageBuilder/sections/ShippingAddress";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { shippingAddressForm } from "./components/ShippingAddressForm";
import {
    shippingAddressFontFamily,
    shippingAddressBackground,
    shippingAddressTitleColor,
    shippingAddressTitleFontSize,
    shippingAddressParagraphColor,
    shippingAddressParagraphFontSize,
} from "./ShippingAddressProperties";

export const shippingAddress = new ShippingAddress(
    "",
    "Shipping address",
    [
        shippingAddressFontFamily,
        shippingAddressBackground,
        shippingAddressTitleColor,
        shippingAddressTitleFontSize,
        shippingAddressParagraphColor,
        shippingAddressParagraphFontSize,
    ],
    "",
    [shippingAddressForm],
    "Szállítási adatok megadása",
    "Erre a címre küldjük el a nyereményedet.",
    "Szállítási adatok megadása",
    "Erre a címre küldjük el a nyereményedet."
);
