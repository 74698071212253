import FormItem from "@/entities/PrizificPageBuilder/components/FormItem";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { EShippingAddressFormItem } from "~~/enums/PrizificPageBuilder/EShippingAddressFormItem";

export const shippingMessageForTheCourierFormItem = new FormItem(
    "-",
    "Message for the courier form item",
    [],
    "",
    [],
    "Üzenet a futárnak",
    "",
    "Üzenet a futárnak",
    "",
    EShippingAddressFormItem.MESSAGE_FOR_THE_COURIER
);
