import Modal from "@/entities/PrizificPageBuilder/components/Modal";
import { EModal } from "~~/enums/PrizificPageBuilder/EModal";

export const notActivatedUserModal = new Modal(
    "-",
    "User not activated modal",
    [],
    "",
    [],
    "Nem aktivált felhasználó cím",
    "Nem aktivált felhasználó leírás",
    "",
    "Bezár",
    "Nem aktivált felhasználó cím",
    "Nem aktivált felhasználó leírás",
    "",
    "Bezár",
    EModal.NOTACTIVATEDUSER
);
