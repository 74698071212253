import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { INavItem } from "@/interfaces/PrizificPageBuilder/INavItem";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import AComponent from "../AComponent";
import ASharedComponent from "../ASharedComponent";

export default class NavItem
    extends ASharedComponent<EComponents.NAV_ITEM>
    implements IComponent<EComponents.NAV_ITEM>, INavItem
{
    constructor(
        id: string,
        sharedComponentId: string | number | null,
        isSharedComponent: boolean,
        title: string,
        style: Array<IProperty>,
        classNames: string,
        components: Array<IComponent<EComponents>>,
        public text: string,
        public textRaw: string,
        public to: string,
        public link: boolean,
        public icon?: string
    ) {
        super(
            id,
            sharedComponentId,
            isSharedComponent,
            title,
            EComponents.NAV_ITEM,
            style,
            classNames,
            components
        );
    }

    toPayload(): { [key: string]: any } {
        return super.toPayload({
            text: this.textRaw,
            to: this.to,
            link: this.link,
            icon: this.icon,
        });
    }
}
