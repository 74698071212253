import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import FooterSmall from "@/entities/PrizificPageBuilder/sections/FooterSmall";
import { AComponentFactory } from "../ComponentFactory";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import {
    footerFontFamily,
    footerBackground,
} from "~~/entities/pages/shared/sections/Footer/FooterProperties";
import { campaignRule } from "~~/entities/pages/shared/sections/Footer/components/CampaignRule/CampaignRule";
import { privacyPolicy } from "~~/entities/pages/shared/sections/Footer/components/PrivacyPolicy/PrivacyPolicy";
import { cookie } from "~~/entities/pages/shared/sections/Footer/components/Cookie/Cookie";
import { termsOfUse } from "~~/entities/pages/shared/sections/Footer/components/TermsOfUse/TermsOfUse";
import { legalNotice } from "~~/entities/pages/shared/sections/Footer/components/LegalNotice/LegalNotice";

export class FooterSmallFactory extends AComponentFactory<FooterSmall> {
    getTypeName() {
        return EComponents.FOOTER_SMALL;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, [
            // "data.specific.firstGroupTitleCompiled",
            // "data.specific.secondGroupTitleCompiled",
            // "data.specific.thirdGroupTitleCompiled",
            // "data.specific.firstGroupTitle",
            // "data.specific.secondGroupTitle",
            // "data.specific.thirdGroupTitle",
            // "data.specific.images"
        ]);

        return new FooterSmall(
            rawData.id,
            rawData.sharedComponentId ?? null,
            rawData.isSharedComponent ?? false,
            rawData.data.title,
            properties,
            rawData.data.classNames,
            subComponents,
            rawData.data.specific.images
                ? rawData.data.specific.images
                : [
                      {
                          src: "",
                          imageType: "extra-extra-large",
                          imageLabel: "1920px",
                      },
                      {
                          src: "",
                          imageType: "extra-large",
                          imageLabel: "1400px",
                      },
                      {
                          src: "",
                          imageType: "large",
                          imageLabel: "1200px",
                      },
                      {
                          src: "",
                          imageType: "medium",
                          imageLabel: "992px",
                      },
                      {
                          src: "",
                          imageType: "small",
                          imageLabel: "768px",
                      },
                      {
                          src: "",
                          imageType: "extra-small",
                          imageLabel: "576px",
                      },
                  ]
        );
    }

    createPlain() {
        return new FooterSmall(
            "-",
            null,
            false,
            "Footer",
            [footerFontFamily, footerBackground],
            "",
            [campaignRule, privacyPolicy, cookie, termsOfUse, legalNotice],
            [
                {
                    src: "",
                    imageType: "extra-extra-large",
                    imageLabel: "1920px",
                },
                {
                    src: "",
                    imageType: "extra-large",
                    imageLabel: "1400px",
                },
                {
                    src: "",
                    imageType: "large",
                    imageLabel: "1200px",
                },
                {
                    src: "",
                    imageType: "medium",
                    imageLabel: "992px",
                },
                {
                    src: "",
                    imageType: "small",
                    imageLabel: "768px",
                },
                {
                    src: "",
                    imageType: "extra-small",
                    imageLabel: "576px",
                },
            ]
        );
    }
}
