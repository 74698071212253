import RecipeUpload from "~~/entities/PrizificPageBuilder/sections/RecipeUpload";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import {
    recipeUploadFontFamily,
    recipeUploadBackground,
    recipeUploadColor,
    recipeUploadFontSize,
} from "./RecipeUploadProperties";
import { recipeUploadFeedbackModals } from "./components/RecipeFeedbackModals";
import { noRecipeButton } from "./components/NoRecipeButton";
import { recipeUploadForm } from "./components/RecipeUploadForm";

export const recipeUpload = new RecipeUpload(
    "",
    "Recipe upload",
    [
        recipeUploadFontFamily,
        recipeUploadBackground,
        recipeUploadColor,
        recipeUploadFontSize,
    ],
    "",
    [recipeUploadForm, noRecipeButton, recipeUploadFeedbackModals],
    "Kérlek töltsd fel egy olyan képet a nyugtáról, amelyen a fenti adatok jól olvashatóak!",
    "",
    "Kérlek töltsd fel egy olyan képet a nyugtáról, amelyen a fenti adatok jól olvashatóak!",
    ""
);
