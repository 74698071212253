import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import CookieSettings from "@/entities/PrizificPageBuilder/sections/CookieSettings";
import { RuntimeComponentParseError } from "@/error/RuntimeError";
import { AComponentFactory } from "../ComponentFactory";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { cookieSettings } from "~~/entities/pages/shared/sections/CookieSettings/CookieSettings";

export class CookieSettingsFactory extends AComponentFactory<CookieSettings> {
    getTypeName() {
        return EComponents.COOKIE_SETTINGS;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, [
            // "data.specific.modalTitleCompiled",
            // "data.specific.modalParagraphCompiled",
            // "data.specific.modalSettingsButtonTextRawCompiled",
            // "data.specific.modalAllAcceptButtonTextCompiled",
            // "data.specific.modalSelectedAcceptButtonTextCompiled",
            // "data.specific.modalTitle",
            // "data.specific.modalParagraph",
            // "data.specific.modalSettingsButtonTextRaw",
            // "data.specific.modalAllAcceptButtonText",
            // "data.specific.modalSelectedAcceptButtonText",
        ]);

        return new CookieSettings(
            rawData.id,
            rawData.sharedComponentId ?? null,
            rawData.isSharedComponent ?? false,
            rawData.data.title,
            usePropertiesMerger(properties, cookieSettings.style),
            rawData.data.classNames,
            subComponents,
            getConvertedText(rawData.data.specific.modalTitleCompiled),
            getConvertedText(rawData.data.specific.modalParagraphCompiled),
            getConvertedText(
                rawData.data.specific.modalSettingsButtonTextRawCompiled
            ),
            getConvertedText(
                rawData.data.specific.modalAllAcceptButtonTextCompiled
            ),
            getConvertedText(
                rawData.data.specific.modalSelectedAcceptButtonTextCompiled
            ),
            rawData.data.specific.modalTitle || "",
            rawData.data.specific.modalParagraph || "",
            rawData.data.specific.modalSettingsButtonTextRaw || "",
            rawData.data.specific.modalAllAcceptButtonTextRaw || "",
            rawData.data.specific.modalSelectedAcceptButtonTextRaw || ""
        );
    }
}
