import { IUploadedCodesCard } from "@/interfaces/PrizificPageBuilder/IUploadedCodesCard";
import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import AComponent from "../AComponent";

export default class UploadedCodesCard
    extends AComponent<EComponents.UPLOADED_CODES_CARD>
    implements IComponent<EComponents.UPLOADED_CODES_CARD>, IUploadedCodesCard
{
    constructor(
        id: string,
        title: string,
        style: Array<IProperty>,
        classNames: string,
        components: Array<IComponent<EComponents>>,
        public purchaseDateText: string,
        public uploadeInformationText: string,
        public purchaseDateTextRaw: string,
        public uploadeInformationTextRaw: string
    ) {
        super(
            id,
            title,
            EComponents.UPLOADED_CODES_CARD,
            style,
            classNames,
            components
        );
    }

    toPayload(): { [key: string]: any } {
        return super.toPayload({
            purchaseDateText: this.purchaseDateTextRaw,
            uploadeInformationText: this.uploadeInformationTextRaw,
        });
    }
}
