import Draw from "@/entities/PrizificPageBuilder/sections/Draw";
import {
    drawFontFamily,
    drawBackground,
    drawTitleColor,
    drawTitleFontSize,
    drawParagraphColor,
    drawParagraphFontSize,
} from "./DrawPropterties";

export const draw = new Draw(
    "-",
    "Draw",
    [
        drawFontFamily,
        drawBackground,
        drawTitleColor,
        drawTitleFontSize,
        drawParagraphColor,
        drawParagraphFontSize,
    ],
    "",
    [],
    "Draw title",
    "Draw Description",
    "Draw title",
    "Draw description"
);
