import FormItem from "@/entities/PrizificPageBuilder/components/FormItem";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { ERegistrationFormItem } from "~~/enums/PrizificPageBuilder/ERegistrationFormItem";

export const edmSubscriptionFormItem = new FormItem(
    "-",
    "Edm subscription form item",
    [],
    "",
    [],
    "",
    "",
    "",
    "",
    ERegistrationFormItem.EDM_SUBSCRIPTION,
    "",
    ""
);
