
export class RuntimeError extends Error {

    constructor(message?: string, options?: ErrorOptions, protected pageId?: string,) {
        if (pageId) {
            super(message + " Page Id: " + pageId, options);
        } else {
            super(message, options);
        }
    }
}
export class RuntimeComponentParseError extends RuntimeError { } 
export class RuntimeComponentFactoryNotFoundError extends RuntimeError { }

export class RuntimePageParseError extends RuntimeError { }

export class RuntimePropertyParseError extends RuntimeError { }



