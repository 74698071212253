import Property from "~/entities/PrizificPageBuilder/Property";
import { EInputType } from "~/enums/PrizificPageBuilder/EInputType";
import { EProperty } from "~/enums/PrizificPageBuilder/EProperty";
import { ERoles } from "~/enums/PrizificPageBuilder/ERoles";
import { EState } from "~/enums/PrizificPageBuilder/EState";
import { EUnit } from "~/enums/PrizificPageBuilder/EUnit";

export const modalTitleTextColor = new Property(
    "Title text color",
    "#e44f60",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginModalTitle"
);

export const modalDescriptionTextColor = new Property(
    "Description text color",
    "#3a89a2",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginModalDescription"
);

export const modalCloseIconColor = new Property(
    "Close icon color",
    "#d3ab90",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginModalClose"
);

export const modalRightButtonBackground = new Property(
    "Right button background color",
    "#e44f60",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalRightButton"
);

export const modalRightButtonColor = new Property(
    "Right button text color",
    "#f7f3e8",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalRightButton"
);

export const modalRightButtonBorderColor = new Property(
    "Right button border color",
    "#e44f60",
    EState.DEFAULT,
    EProperty.BORDER_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalRightButton"
);

export const modalRightButtonFontSize = new Property(
    "Right button font size",
    16,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalRightButton",
    EUnit.PX,
    13,
    24
);

export const modalRightButtonPadding = new Property(
    "Right button padding",
    "20px 18px",
    EState.DEFAULT,
    EProperty.PADDING,
    EInputType.TEXT,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalRightButton"
);

export const modalRightButtonBorderRadius = new Property(
    "Right button border radius",
    10,
    EState.DEFAULT,
    EProperty.BORDER_RADIUS,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalRightButton",
    EUnit.PX,
    0,
    24
);
