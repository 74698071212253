import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { ISection } from "@/interfaces/PrizificPageBuilder/ISection";
import { IText } from "@/interfaces/PrizificPageBuilder/IText";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import AComponent from "../AComponent";
import { ITimeInterval } from "~~/interfaces/PrizificPageBuilder/ITimeInterval";

export default class TimeInterval
    extends AComponent<EComponents.TIME_INTERVAL>
    implements IComponent<EComponents.TIME_INTERVAL>, ITimeInterval, ISection
{
    constructor(
        id: string,
        title: string,
        style: Array<IProperty>,
        classNames: string,
        components: Array<IComponent<EComponents>>,
        public beforeCampaignText: string,
        public beforeCampaignTextRaw: string,
        public activeCampaignText: string,
        public activeCampaignTextRaw: string,
        public afterCampaignText: string,
        public afterCampaignTextRaw: string,
        public sectionTitle?: string,
        public sectionParagraph?: string,
        public sectionTitleRaw?: string,
        public sectionParagraphRaw?: string
    ) {
        super(
            id,
            title,
            EComponents.TIME_INTERVAL,
            style,
            classNames,
            components
        );
    }

    toPayload(): { [key: string]: any } {
        return super.toPayload({
            beforeCampaignText: this.beforeCampaignTextRaw,
            activeCampaignText: this.activeCampaignTextRaw,
            afterCampaignText: this.afterCampaignTextRaw,
            sectionTitle: this.sectionTitleRaw,
            sectionParagraph: this.sectionParagraphRaw,
        });
    }
}
