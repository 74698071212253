import Modal from "@/entities/PrizificPageBuilder/components/Modal";
import { EModal } from "~~/enums/PrizificPageBuilder/EModal";

export const couponCodeConflictItselfModal = new Modal(
    "-",
    "Coupon code conflict itself modal",
    [],
    "",
    [],
    "title",
    "description",
    "",
    "Felveszem a kapcsolatot az ügyfélszolgálattal",
    "title",
    "description",
    "",
    "Felveszem a kapcsolatot az ügyfélszolgálattal",
    EModal.COUPONCODECONFLICTITSELF
);
