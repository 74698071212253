import Form from "@/entities/PrizificPageBuilder/components/Form";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import {
    editProfileFormBackground,
    editProfileFormItemLabelColor,
    editProfileFormItemLabelFontSize,
    editProfileFormItemBorderColor,
    editProfileFormItemBorderWidth,
    editProfileFormItemBorderRadius,
    editProfileFormSubmitButtonPadding,
    editProfileFormSubmitButtonFontSize,
    editProfileFormSubmitButtonBackground,
    editProfileFormSubmitButtonTextColor,
    editProfileFormSubmitButtonBorderRadius,
    editProfileFormSubmitButtonBackgroundHover,
    editProfileFormSubmitButtonTextColorHover,
} from "./EditProfileFormProperties";
import { firstNameFormItem } from "./FisrtNameFormItem";
import { lastNameFormItem } from "./LastNameFormItem";

export const editProfileForm = new Form(
    "-",
    "Edit Profile form",
    [
        editProfileFormBackground,
        editProfileFormItemLabelColor,
        editProfileFormItemLabelFontSize,
        editProfileFormItemBorderColor,
        editProfileFormItemBorderWidth,
        editProfileFormItemBorderRadius,
        editProfileFormSubmitButtonPadding,
        editProfileFormSubmitButtonFontSize,
        editProfileFormSubmitButtonBorderRadius,
        editProfileFormSubmitButtonBackground,
        editProfileFormSubmitButtonTextColor,
        editProfileFormSubmitButtonBackgroundHover,
        editProfileFormSubmitButtonTextColorHover,
    ],
    "",
    [lastNameFormItem, firstNameFormItem]
);
