import { useCampaignDataStore } from "~~/store/campaign/campaignData";

/**
 * Add url params to given url
 *
 * @param url
 * @param params
 * @returns
 */
function addUrlParams(url: string, params: { [key: string]: string }) {
    const urlObj = new URL(url);
    Object.keys(params).forEach((key) => {
        urlObj.searchParams.append(key, params[key]);
    });
    return urlObj.toString();
}

export function useMediaUrl(
    mediaFile: string,
    width?: number,
    height?: number
): string {
    let fileUrl = `${useApiBaseUrl()}imagemanagerms/${mediaFile}`;
    //Only for szentkirályi, becouse of the proxy issues
    try {
        const campaignDataStore = useCampaignDataStore()
        if (campaignDataStore.campaignUUId == "cca63d5c-c47c-45be-8a3f-3c98e0037b7f") {
            fileUrl = `https://prizific.com/imagemanagerms/${mediaFile}`
        }
    } catch (e) {
        console.error("Error for szeintkirályi", e);
    }
    //INFO original media url, use this in the future
    //const fileUrl = `${useApiBaseUrl()}imagemanagerms/${mediaFile}`;
    const params: { [key: string]: string } = {};

    width ? (params.width = width.toString()) : undefined;
    height ? (params.height = height.toString()) : undefined;

    const updatedUrl = addUrlParams(fileUrl, params);

    return updatedUrl;
}

