import FormItem from "@/entities/PrizificPageBuilder/components/FormItem";
import { EShippingAddressFormItem } from "@/enums/PrizificPageBuilder/EShippingAddressFormItem";

export const shippingCityFormItem = new FormItem(
    "-",
    "City form item",
    [],
    "",
    [],
    "Település",
    "",
    "Település",
    "",
    EShippingAddressFormItem.CITY
);
