import { PageBuilderFactory } from "~/factory/PageBuilderFactory";
import { AgeGateFactory } from "~/factory/parsers/AgeGateFactory";
import { ButtonFactory } from "~/factory/parsers/ButtonFactory";
import { CodeUploadFactory } from "~/factory/parsers/CodeUploadFactory";
import { CodeUploadInformationFactory } from "~/factory/parsers/CodeUploadInformationFactory";
import { ContactFactory } from "~/factory/parsers/ContactFactory";
import { ContactWithPrivacyPolicyFactory } from "~/factory/parsers/ContactWithPrivacyPolicyFactory";
import { ContactPrivacyPolicyFactory } from "~/factory/parsers/ContactPrivacyPolicyFactory";
import { EditPasswordFactory } from "~/factory/parsers/EditPasswordFactory";
import { FaqsFactory } from "~/factory/parsers/FaqsFactory";
import { FeedbackPageMessageFactory } from "~/factory/parsers/FeedbackPageMessageFactory";
import { FooterFactory } from "~/factory/parsers/FooterFactory";
import { FormFactory } from "~/factory/parsers/FormFactory";
import { FormItemFactory } from "~/factory/parsers/FormItemFactory";
import { GameStepsFactory } from "~/factory/parsers/GameStepsFactory";
import { GroupFactory } from "~/factory/parsers/GroupFactory";
import { HeroImageFactory } from "~/factory/parsers/HeroImageFactory";
import { ImgFactory } from "~/factory/parsers/ImgFactory";
import { ListItemFactory } from "~/factory/parsers/ListItemFactory";
import { LoginFactory } from "~/factory/parsers/LoginFactory";
import { ModalFactory } from "~/factory/parsers/ModalFactory";
import { NavbarFactory } from "~/factory/parsers/NavbarFactory";
import { NavItemFactory } from "~/factory/parsers/NavItemFactory";
import { NewPasswordFactory } from "~/factory/parsers/NewPasswordFactory";
import { DrawFactory } from "~/factory/parsers/DrawFactory";
import { DiscountCouponsFactory } from "~/factory/parsers/DiscountCouponsFactory";
import { PrizesFactory } from "~/factory/parsers/PrizesFactory";
import { PrizesByTypeFactory } from "~/factory/parsers/PrizesByTypeFactory";
import { PrizesGroupByTypeFactory } from "~/factory/parsers/PrizesGroupByTypeFactory";
import { VoteFactory } from "~/factory/parsers/VoteFactory";
import { VoteDefaultFactory } from "~/factory/parsers/VoteDefaultFactory";
import { PromoProductsFactory } from "~/factory/parsers/PromoProductsFactory";
import { RecipeUploadFactory } from "~/factory/parsers/RecipeUploadFactory";
import { RegistrationFactory } from "~/factory/parsers/RegistrationFactory";
import { ShippingAddressFactory } from "~/factory/parsers/ShippingAddressFactory";
import { TimeIntervalFactory } from "~/factory/parsers/TimeIntervalFactory";
import { UploadedCodesCardFactory } from "~/factory/parsers/UploadedCodesCardFactory";
import { WinnersFactory } from "~/factory/parsers/WinnersFactory";
import { WinnersListFactory } from "~/factory/parsers/WinnersListFactory";
import { YourPrizeFactory } from "~/factory/parsers/YourPrizeFactory";
import { WinnerFeedbackPageMessageFactory } from "~/factory/parsers/WinnerFeedbackPageMessageFactory";
import { ImageTextContentFactory } from "~/factory/parsers/ImageTextContentFactory";
import { ImageTextFactory } from "~/factory/parsers/ImageTextFactory";
import { UserConsentingFactory } from "~~/factory/parsers/UserConsentingFactory";
import { TextFactory } from "~/factory/parsers/TextFactory";
import { CookieSettingsFactory } from "~/factory/parsers/CookieSettingsFactory";
import { EditProfileFactory } from "~/factory/parsers/EditProfileFactory";
import { UploadedCodesFactory } from "~/factory/parsers/UploadedCodesFactory";
import { GameStepsSzentkiralyiFactory } from "~/factory/parsers/GameStepsSzentkirlayiFactory";
import { NavItemWithThreeStateFactory } from "~/factory/parsers/NavItemWithThreeStateFactory";
import { PrizesMinionFactory } from "~/factory/parsers/PrizesMinionFactory";
import { FooterSmallFactory } from "~/factory/parsers/FooterSmallFactory";
import { YoutubeVideoFactory } from "~/factory/parsers/YoutubeVideoFactory";
import { VideoFactory } from "~/factory/parsers/VideoFactory";
import { TextButtonFactory } from "~/factory/parsers/TextButtonFactory";
import { MapsFactory } from "~~/factory/parsers/MapsFactory";
import { PrizesMinionWeeklyDailyFactory } from "~~/factory/parsers/PrizesMinionWeeklyDailyFactory";

export default defineNuxtPlugin(() => {
    const pageBuilderFactory = new PageBuilderFactory([
        new AgeGateFactory(),
        new ButtonFactory(),
        new CodeUploadFactory(),
        new CodeUploadInformationFactory(),
        new ContactFactory(),
        new ContactWithPrivacyPolicyFactory(),
        new ContactPrivacyPolicyFactory(),
        new DrawFactory(),
        new DiscountCouponsFactory(),
        new EditPasswordFactory(),
        new EditProfileFactory(),
        new FaqsFactory(),
        new FeedbackPageMessageFactory(),
        new FooterFactory(),
        new FooterSmallFactory(),
        new FormFactory(),
        new FormItemFactory(),
        new GameStepsFactory(),
        new GameStepsSzentkiralyiFactory(),
        new GroupFactory(),
        new HeroImageFactory(),
        new ImgFactory(),
        new ListItemFactory(),
        new LoginFactory(),
        new ModalFactory(),
        new NavbarFactory(),
        new NavItemFactory(),
        new NavItemWithThreeStateFactory(),
        new NewPasswordFactory(),
        new PrizesFactory(),
        new PrizesMinionFactory(),
        new PrizesMinionWeeklyDailyFactory(),
        new PrizesByTypeFactory(),
        new PrizesGroupByTypeFactory(),
        new PromoProductsFactory(),
        new RecipeUploadFactory(),
        new RegistrationFactory(),
        new ShippingAddressFactory(),
        new TimeIntervalFactory(),
        new UploadedCodesFactory(),
        new UploadedCodesCardFactory(),
        new WinnersFactory(),
        new WinnerFeedbackPageMessageFactory(),
        new WinnersListFactory(),
        new YourPrizeFactory(),
        new ImageTextContentFactory(),
        new ImageTextFactory(),
        new UserConsentingFactory(),
        new TextFactory(),
        new CookieSettingsFactory(),
        new VoteFactory(),
        new VoteDefaultFactory(),
        new YoutubeVideoFactory(),
        new VideoFactory(),
        new TextButtonFactory(),
        new MapsFactory(),
    ]);

    return {
        provide: {
            pageBuilderFactory: pageBuilderFactory,
        },
    };
});
