import FormItem from "@/entities/PrizificPageBuilder/components/FormItem";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { ERecipeUploadFormItem } from "~/enums/PrizificPageBuilder/ERecipeUploadFormItem";

export const recipeUploadFormItem = new FormItem(
    "-",
    "Recipe upload form item",
    [],
    "",
    [],
    "Nyugta képének feltöltése",
    "Nyugta képének feltöltése",
    "Nyugta képének feltöltése",
    "Nyugta képének feltöltése",
    ERecipeUploadFormItem.RECIPE_UPLOAD
);
