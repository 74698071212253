import NewPassword from "~~/entities/PrizificPageBuilder/sections/NewPassword";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { newPasswordForm } from "./components/NewPasswordForm";
import {
    newPasswordBackground,
    newPasswordTitleColor,
    newPasswordTitleFontSize,
} from "./NewPasswordProperties";

export const newPassword = new NewPassword(
    "New password",
    EComponents.NEW_PASSWORD,
    [newPasswordBackground, newPasswordTitleColor, newPasswordTitleFontSize],
    "",
    [newPasswordForm],
    "Jelszó módosítása",
    "",
    "Jelszó módosítása",
    ""
);
