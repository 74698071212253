import FormItem from "@/entities/PrizificPageBuilder/components/FormItem";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { ECodeUploadFormItem } from "~/enums/PrizificPageBuilder/ECodeUploadFormItem";

export const placeOfPurchaseFormItem = new FormItem(
    "-",
    "Place of purchase form item",
    [],
    "",
    [],
    "Vásárlás helye",
    "Kérjük válassz",
    "Vásárlás helye",
    "Kérjük válassz",
    ECodeUploadFormItem.PLACE_OF_PURCHASE
);
