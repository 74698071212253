import Registration from "@/entities/PrizificPageBuilder/sections/Registration";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { registrationFeedbackModals } from "./components/RegistrationFeedbackModals";
import { registrationForm } from "./components/RegistrationForm";
import {
    registrationFontFamily,
    registrationBackground,
    registrationParagraphColor,
    registrationParagraphFontSize,
    registrationTitleColor,
    registrationTitleFontSize,
    registrationToggleButtonColor,
    registrationToggleButtonBackground,
    registrationToggleButtonBorderColor,
    registrationToggleButtonActiveColor,
    registrationToggleButtonActiveBackground,
    registrationToggleButtonBorderRadius,
} from "./RegistrationProperties";

export const registration = new Registration(
    "-",
    "Registration",
    [
        registrationFontFamily,
        registrationBackground,
        registrationTitleColor,
        registrationTitleFontSize,
        registrationParagraphColor,
        registrationParagraphFontSize,
        registrationToggleButtonColor,
        registrationToggleButtonBackground,
        registrationToggleButtonBorderColor,
        registrationToggleButtonActiveColor,
        registrationToggleButtonActiveBackground,
        registrationToggleButtonBorderRadius,
    ],
    "",
    [registrationForm, registrationFeedbackModals],
    "Regisztráció",
    "Az első blokkfeltöltéshez regisztráció szükséges. Töltsd ki a lenti adatokat, hogy feltöltésed érvényes legyen. Kérjük, olyan adatokat adj meg, amin mindig elérhetünk. Ha több blokkot is feltöltesz, nagyobb eséllyel nyerhetsz!",
    "Regisztráció",
    "Az első blokkfeltöltéshez regisztráció szükséges. Töltsd ki a lenti adatokat, hogy feltöltésed érvényes legyen. Kérjük, olyan adatokat adj meg, amin mindig elérhetünk. Ha több blokkot is feltöltesz, nagyobb eséllyel nyerhetsz!"
);
