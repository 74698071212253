import { AComponentFactory } from "../ComponentFactory";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import CodeUploadInformation from "@/entities/PrizificPageBuilder/sections/CodeUploadInformation";
import { codeUploadInformation } from "~~/entities/pages/winner/sections/CodeUploadInformation/CodeUploadInformation";

export class CodeUploadInformationFactory extends AComponentFactory<CodeUploadInformation> {
    getTypeName() {
        return EComponents.CODE_UPLOAD_INFORMATION;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, [
            "data.specific.sectionTitle",
            "data.specific.sectionParagraph",
            "data.specific.sectionTitle",
            "data.specific.sectionParagraph",
        ]);

        return new CodeUploadInformation(
            rawData.id,
            rawData.data.title,
            usePropertiesMerger(properties, codeUploadInformation.style),
            rawData.data.classNames,
            subComponents,
            getConvertedText(rawData.data.specific.sectionTitleCompiled),
            getConvertedText(rawData.data.specific.sectionParagraphCompiled),
            rawData.data.specific.sectionTitle,
            rawData.data.specific.sectionParagraph
        );
    }
}
