import Winners from "~~/entities/PrizificPageBuilder/sections/Winners";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import {
    winnersFontFamily,
    winnersBackground,
    winnersTitleColor,
    winnersTitleFontSize,
    winnersParagraphColor,
    winnersParagraphFontSize,
} from "./WinnersProperties";
import { winnersList } from "./components/WinnersList";

export const winners = new Winners(
    "-",
    "Winners",
    [
        winnersFontFamily,
        winnersBackground,
        winnersTitleColor,
        winnersTitleFontSize,
        winnersParagraphColor,
        winnersParagraphFontSize,
    ],
    "",
    [winnersList],
    "Nyertesek",
    "Minden nyertest értesítünk e-mailben, napi nyeremény esetén közvetlenül a kódfeltöltés után. Azon nyertesek kerülnek publikálásra, akiknek feltöltött blokk képe elfogadásra került!",
    "Nyertesek",
    "Minden nyertest értesítünk e-mailben, napi nyeremény esetén közvetlenül a kódfeltöltés után. Azon nyertesek kerülnek publikálásra, akiknek feltöltött blokk képe elfogadásra került!"
);
