import TimeInterval from "@/entities/PrizificPageBuilder/sections/TimeInterval";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import {
    timeIntervalFontFamily,
    timeIntervalBackground,
    timeIntervalTextColor,
} from "./TimeIntervalProperties";

export const timeInterval = new TimeInterval(
    "-",
    "Time interval",
    [timeIntervalFontFamily, timeIntervalTextColor, timeIntervalBackground],
    "",
    [],
    "A játék 2021. május 1. kezdődik",
    "A játék 2021. május 1. kezdődik",
    "A játék időtartama: 2021. május 1. és 2021. július 31. között",
    "A játék időtartama: 2021. május 1. és 2021. július 31. között",
    "A játék lejárt",
    "A játék lejárt"
);
