import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import YourPrize from "@/entities/PrizificPageBuilder/sections/YourPrize";
import { AComponentFactory } from "../ComponentFactory";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import ImageTextContent from "~~/entities/PrizificPageBuilder/sections/ImageTextContent";
import {
    imageTextContentBackground,
    imageTextContentParagraphColor,
    imageTextContentParagraphFontSize,
    imageTextContentTitleColor,
    imageTextContentTitleFontSize,
} from "~~/entities/InsertableSections/ImageTextContentProperty/ImageTextContentProperty";
import { ImageTextFactory } from "./ImageTextFactory";

export class ImageTextContentFactory extends AComponentFactory<ImageTextContent> {
    getTypeName() {
        return EComponents.IMAGE_TEXT_CONTENT;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, [
            // "data.specific.sectionTitleCompiled",
            // "data.specific.sectionParagraphCompiled",
            // "data.specific.sectionTitle",
            // "data.specific.sectionParagraph",
        ]);

        return new ImageTextContent(
            rawData.id,
            rawData.data.title,
            properties,
            rawData.data.classNames,
            subComponents,
            getConvertedText(rawData.data.specific.sectionTitleCompiled),
            getConvertedText(rawData.data.specific.sectionParagraphCompiled),
            rawData.data.specific.sectionTitle,
            rawData.data.specific.sectionParagraph
        );
    }

    createPlain() {
        const imageTextFactory = new ImageTextFactory();
        return new ImageTextContent(
            "-",
            "Image text content",
            [
                imageTextContentBackground,
                imageTextContentTitleColor,
                imageTextContentTitleFontSize,
                imageTextContentParagraphColor,
                imageTextContentParagraphFontSize,
            ],
            "",
            [imageTextFactory.createPlain()],
            getConvertedText("Default title"),
            getConvertedText("Default paragraph"),
            "Default title",
            "Default paragraph"
        );
    }
}
