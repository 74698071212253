import Property from "@/entities/PrizificPageBuilder/Property";
import { EInputType } from "@/enums/PrizificPageBuilder/EInputType";
import { EProperty } from "@/enums/PrizificPageBuilder/EProperty";
import { ERoles } from "@/enums/PrizificPageBuilder/ERoles";
import { EState } from "@/enums/PrizificPageBuilder/EState";
import { EUnit } from "@/enums/PrizificPageBuilder/EUnit";

export const loginFontFamily = new Property(
    "Font family",
    "",
    EState.DEFAULT,
    EProperty.FONT_FAMILY,
    EInputType.FONT_SELECT,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginBackground"
);

export const loginBackground = new Property(
    "Background color",
    "#878787",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginBackground"
);

export const loginTitleColor = new Property(
    "Title text color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginTitle"
);

export const loginTitleFontSize = new Property(
    "Title font size",
    32,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginTitle",
    EUnit.PX,
    24,
    48
);

export const loginParagraphColor = new Property(
    "Paragraph text color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginParagraph"
);

export const loginParagraphFontSize = new Property(
    "Paragraph font size",
    16,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginParagraph",
    EUnit.PX,
    13,
    32
);

export const loginToggleButtonBackground = new Property(
    "Toggle button background color",
    "#fff",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginToggleButton"
);

export const loginToggleButtonColor = new Property(
    "Toggle button color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginToggleButton"
);

export const loginToggleButtonBorderColor = new Property(
    "Toggle button border color",
    "#444444",
    EState.DEFAULT,
    EProperty.BORDER_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginToggleButton"
);

export const loginToggleButtonActiveColor = new Property(
    "Toggle button active color",
    "#fff",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginToggleButtonActive"
);

export const loginToggleButtonActiveBackground = new Property(
    "Toggle button active background color",
    "#444444",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginToggleButtonActive"
);

export const loginToggleButtonBorderRadius = new Property(
    "Toggle button border radius",
    4,
    EState.DEFAULT,
    EProperty.BORDER_RADIUS,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "loginToggleButton",
    EUnit.PX,
    0,
    48
);
