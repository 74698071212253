import Navbar from "@/entities/PrizificPageBuilder/sections/Navbar";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import {
    navBarFontFamily,
    navBarBackgroundColor,
    navBarPadding,
    mobileNavBarBackgroundColor,
    mobileNavBarTextColor,
    mobileNavBarTextHoverColor,
} from "./NavbarProperties";
import { navBrandLogo } from "./components/NavBrandLogo/NavBrandLogo";
import { navItemPrizes } from "./components/NavItemPrizes/NavItemPrizes";
import { navItemCodeUpload } from "./components/NavItemCodeUpload/NavItemCodeUpload";
import { navItemWinners } from "./components/NavItemWinners/NavItemWinners";

export const navbar = new Navbar(
    "",
    null,
    false,
    "Navbar",
    [
        navBarFontFamily,
        navBarBackgroundColor,
        navBarPadding,
        mobileNavBarBackgroundColor,
        mobileNavBarTextColor,
        mobileNavBarTextHoverColor,
    ],
    "",
    [navBrandLogo, navItemPrizes, navItemWinners, navItemCodeUpload]
);
