import Form from "@/entities/PrizificPageBuilder/components/Form";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { apCodeFormItem } from "./ApCodeFormItem";
import {
    codeUploadFormBackground,
    codeUploadFormItemLabelColor,
    codeUploadFormItemLabelFontSize,
    codeUploadFormItemToggleButtonColor,
    codeUploadFormItemToggleButtonBackground,
    codeUploadFormItemToggleButtonBorderColor,
    codeUploadFormItemToggleButtonActiveColor,
    codeUploadFormItemToggleButtonActiveBackground,
    codeUploadFormItemToggleButtonBorderRadius,
    codeUploadFormItemBorderColor,
    codeUploadFormItemBorderWidth,
    codeUploadFormItemBorderRadius,
    codeUploadFormSubmitButtonPadding,
    codeUploadFormSubmitButtonFontSize,
    codeUploadFormSubmitButtonBackground,
    codeUploadFormSubmitButtonTextColor,
    codeUploadFormSubmitButtonBorderRadius,
    codeUploadFormSubmitButtonBackgroundHover,
    codeUploadFormSubmitButtonTextColorHover,
    codeUploadFormExampleTextColor,
    codeUploadFormExampleHighlightColor,
    codeUploadFormSubmitButtonBorderColor,
    codeUploadFormSubmitButtonBorderWidth,
    codeUploadFormSubmitButtonBorderWidthHover,
    codeUploadFormSubmitButtonBorderColorHover,
    codeUploadFormItemBackground,
    codeUploadFormItemTextColor,
    codeUploadFormItemPlaceholderColor,
    codeUploadFormItemReceiptImageCloseIconColor,
    codeUploadFormItemPadding,
    codeUploadFormItemFontSize,
    codeUploadFormItemHeight,
} from "./CodeUploadFormProperties";
import { emailFormItem } from "./EmailFormItem";
import { firstNameFormItem } from "./FirstNameFormItem";
import { howToPurchseFormItem } from "./HowToPurchaseFormItem";
import { placeOfPurchaseFormItem } from "./PlaceOfPurchaseFormItem";
import { purchaseDateFormItem } from "./PurchaseDateFormItem";
import { purchasedPromotionProductFormItem } from "./PurchasedPromotionProductFormItem";
import { purchaseTimeFormItem } from "./PurchaseTimeFormItem";
import { receiptNumberFormItem } from "./ReceiptNumberFormItem";
import { lastNameFormItem } from "./LastNameFormItem";
import { privacyPolicyFormItem } from "./PrivacyPolicyFormItem";

export const codeUploadForm = new Form(
    "-",
    "Code upload form",
    [
        codeUploadFormBackground,
        codeUploadFormItemLabelColor,
        codeUploadFormItemLabelFontSize,
        codeUploadFormItemToggleButtonColor,
        codeUploadFormItemToggleButtonBackground,
        codeUploadFormItemToggleButtonBorderColor,
        codeUploadFormItemToggleButtonActiveColor,
        codeUploadFormItemToggleButtonActiveBackground,
        codeUploadFormItemToggleButtonBorderRadius,
        codeUploadFormItemBackground,
        codeUploadFormItemTextColor,
        codeUploadFormItemPlaceholderColor,
        codeUploadFormItemReceiptImageCloseIconColor,
        codeUploadFormItemPadding,
        codeUploadFormItemHeight,
        codeUploadFormItemFontSize,
        codeUploadFormItemBorderColor,
        codeUploadFormItemBorderWidth,
        codeUploadFormItemBorderRadius,
        codeUploadFormSubmitButtonPadding,
        codeUploadFormSubmitButtonFontSize,
        codeUploadFormSubmitButtonBorderRadius,
        codeUploadFormSubmitButtonBackground,
        codeUploadFormSubmitButtonTextColor,
        codeUploadFormSubmitButtonBorderColor,
        codeUploadFormSubmitButtonBorderWidth,
        codeUploadFormSubmitButtonBackgroundHover,
        codeUploadFormSubmitButtonTextColorHover,
        codeUploadFormSubmitButtonBorderColorHover,
        codeUploadFormSubmitButtonBorderWidthHover,
        codeUploadFormExampleTextColor,
        codeUploadFormExampleHighlightColor,
    ],
    "",
    [
        howToPurchseFormItem,
        firstNameFormItem,
        lastNameFormItem,
        emailFormItem,
        receiptNumberFormItem,
        purchasedPromotionProductFormItem,
        apCodeFormItem,
        purchaseDateFormItem,
        purchaseTimeFormItem,
        placeOfPurchaseFormItem,
        privacyPolicyFormItem,
    ]
);
