import Property from "@/entities/PrizificPageBuilder/Property";
import { EInputType } from "@/enums/PrizificPageBuilder/EInputType";
import { EProperty } from "@/enums/PrizificPageBuilder/EProperty";
import { ERoles } from "@/enums/PrizificPageBuilder/ERoles";
import { EState } from "@/enums/PrizificPageBuilder/EState";
import { EUnit } from "@/enums/PrizificPageBuilder/EUnit";

export const brandLogoWidth = new Property(
    "Width",
    100,
    EState.DEFAULT,
    EProperty.WIDTH,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "",
    EUnit.PX,
    1,
    512
);

export const brandLogoHeight = new Property(
    "Height",
    99,
    EState.DEFAULT,
    EProperty.HEIGHT,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "",
    EUnit.PX,
    1,
    512
);
