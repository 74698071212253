import ContactWithPrivacyPolicy from "../../../../PrizificPageBuilder/sections/ContactWithPrivacyPolicy";
import {
    contactBackground,
    contactFontFamily,
    contactParagraphColor,
    contactTitleColor,
    contactTitleFontSize,
    contactParagraphFontSize,
    contactCheckboxBackground,
    contactCheckboxColor,
    contactCheckboxBorderRadius,
    contactSubmitButtonPadding,
    contactSubmitButtonFontSize,
    contactSubmitButtonBackground,
    contactSubmitButtonTextColor,
    contactSubmitButtonBorderColor,
    contactSubmitButtonBorderWidth,
    contactSubmitButtonBorderRadius,
    contactSubmitButtonBackgroundHover,
    contactSubmitButtonTextColorHover,
    contactSubmitButtonBorderColorHover,
    contactSubmitButtonBorderWidthHover,
} from "./ContactWithPrivacyPolicyPropterties";

export const contactWithPrivacyPolicy = new ContactWithPrivacyPolicy(
    "-",
    null,
    false,
    "Contact with privacy policy",
    [
        contactFontFamily,
        contactBackground,
        contactTitleColor,
        contactTitleFontSize,
        contactParagraphColor,
        contactParagraphFontSize,
        contactCheckboxBackground,
        contactCheckboxColor,
        contactCheckboxBorderRadius,
        contactSubmitButtonPadding,
        contactSubmitButtonFontSize,
        contactSubmitButtonBackground,
        contactSubmitButtonTextColor,
        contactSubmitButtonBorderColor,
        contactSubmitButtonBorderWidth,
        contactSubmitButtonBorderRadius,
        contactSubmitButtonBackgroundHover,
        contactSubmitButtonTextColorHover,
        contactSubmitButtonBorderColorHover,
        contactSubmitButtonBorderWidthHover,
    ],
    "",
    [],
    "Kapcsolat",
    ""
);
