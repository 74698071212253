import Property from "@/entities/PrizificPageBuilder/Property";
import { EInputType } from "@/enums/PrizificPageBuilder/EInputType";
import { EProperty } from "@/enums/PrizificPageBuilder/EProperty";
import { ERoles } from "@/enums/PrizificPageBuilder/ERoles";
import { EState } from "@/enums/PrizificPageBuilder/EState";
import { EUnit } from "@/enums/PrizificPageBuilder/EUnit";

export const discountCouponsFontFamily = new Property(
    "Font family",
    "",
    EState.DEFAULT,
    EProperty.FONT_FAMILY,
    EInputType.FONT_SELECT,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "discountCouponsSection"
);

export const discountCouponsBackground = new Property(
    "Background color",
    "#878787",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "discountCouponsSection"
);

export const discountCouponsImageWidth = new Property(
    "Image width",
    256,
    EState.DEFAULT,
    EProperty.WIDTH,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "discountCouponsImage",
    EUnit.PX,
    64,
    256
);

export const discountCouponsTextColor = new Property(
    "Text color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "discountCouponsSection"
);

export const discountCouponsMoreTextColor = new Property(
    "Discount Coupons more text color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "discountCouponsMore"
);

export const discountCouponsMoreHoverTextColor = new Property(
    "Discount Coupons more hover text color",
    "#444444",
    EState.HOVER,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "discountCouponsMoreHover"
);

export const discountCouponsTypeFontSize = new Property(
    "Type font size",
    12,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "discountCouponsType",
    EUnit.PX,
    11,
    16
);

export const discountCouponsPrizeNameFontSize = new Property(
    "Discount Coupons name font size",
    16,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "discountCouponsName",
    EUnit.PX,
    11,
    24
);

export const discountCouponsDescriptionFontSize = new Property(
    "Small description font size",
    13,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "discountCouponsDescription",
    EUnit.PX,
    12,
    18
);

export const discountCouponsMoreFontSize = new Property(
    "Discount Coupons more font size",
    13,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "discountCouponsMore",
    EUnit.PX,
    12,
    18
);
