import NavItem from "@/entities/PrizificPageBuilder/components/NavItem";

import {
    campaignRuleFontSize,
    campaignRulePadding,
    campaignRuleBorderRadius,
    campaignRuleTextColor,
    campaignRuleTextColorHover,
    campaignRuleBackgroundColor,
    campaignRuleBackgroundColorHover,
} from "./CampaignRuleProperties";

export const campaignRule = new NavItem(
    "-",
    null,
    false,
    "Játékszabályzat",
    [
        campaignRuleFontSize,
        campaignRulePadding,
        campaignRuleTextColor,
        campaignRuleTextColorHover,
        campaignRuleBackgroundColor,
        campaignRuleBackgroundColorHover,
    ],
    "",
    [],
    "Játékszabályzat",
    "Játékszabályzat",
    "",
    true
);
