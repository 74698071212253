import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { EInputType } from "~~/enums/PrizificPageBuilder/EInputType";

export default abstract class AComponent<T> implements IComponent<T> {


    constructor(
        protected id: string | number,

        public title: string,
        protected type: T,
        public style: Array<IProperty>,
        public classNames: string,
        protected components: Array<IComponent<EComponents>>
    ) { }

    getType(): T {
        return this.type;
    }

    getComponents(): Array<IComponent<EComponents>> {
        return this.components;
    }
   

    /**
     * Replace the given component by it's own component id
     */
    replaceComponent(
        replaceComponent: IComponent<EComponents>
    ): Array<IComponent<EComponents>> {
        this.components.forEach(
            (component: IComponent<EComponents>, index: number) => {
                if (replaceComponent.getId() == component.getId()) {
                    this.components[index] = replaceComponent;
                    return;
                } else {
                    component.replaceComponent(replaceComponent);
                }
            }
        );

        return this.components;
    }

    getId(): string | number {
        return this.id;
    }
    /**
     * Return with a style object, first only 
     * INFO: Will be replaced in future on new format of store component data
     */
    getStylePropertyByType(inputType: EInputType) {
        return this.style.find((property) => {
            return property.inputType == inputType;
        })
    }


    toPayload(specific?: { [key: string]: any }): { [key: string]: any } {
        const output: { [key: string]: any } = {
            type: this.type,
            title: this.title,
            style: this.style ? this.style.map((prop) => prop.toPayload()) : [],
            classNames: this.classNames,
        };

        if (specific) {
            output.specific = specific;
        }
        return output;
    }


    isSharedComponent(){
        return false;
    }


    getSharedComponentId(){
        return  null;
    }
}
