import Property from "@/entities/PrizificPageBuilder/Property";
import { EInputType } from "@/enums/PrizificPageBuilder/EInputType";
import { EProperty } from "@/enums/PrizificPageBuilder/EProperty";
import { ERoles } from "@/enums/PrizificPageBuilder/ERoles";
import { EState } from "@/enums/PrizificPageBuilder/EState";
import { EUnit } from "@/enums/PrizificPageBuilder/EUnit";

export const footerFontFamily = new Property(
    "Font family",
    "",
    EState.DEFAULT,
    EProperty.FONT_FAMILY,
    EInputType.FONT_SELECT,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "footerBackground"
);

export const footerBackground = new Property(
    "Background color",
    "#878787",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "footerBackground"
);

export const footerFirstGroupTitleColor = new Property(
    "First group title color",
    "#E1E1E1",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "footerFirstGroup"
);

export const footerFirstGroupTitleFontSize = new Property(
    "First group title font size",
    24,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "footerFirstGroup",
    EUnit.PX,
    16,
    48
);

export const footerSecondtGroupTitleColor = new Property(
    "Second group title color",
    "#E1E1E1",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "footerSecondGroup"
);

export const footerSecondGroupTitleFontSize = new Property(
    "Second group title font size",
    24,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "footerSecondGroup",
    EUnit.PX,
    16,
    48
);

export const footerThirdGroupTitleColor = new Property(
    "Third group title color",
    "#E1E1E1",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "footerThirdGroup"
);

export const footerThirdGroupTitleFontSize = new Property(
    "Third group title font size",
    24,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "footerThirdGroup",
    EUnit.PX,
    16,
    48
);
