import { defineStore } from "pinia";
import { useTrackingStore } from "~~/store/tracking/tracking";

export interface ICookieConsent {
    statistics: boolean;
    marketing: boolean;
    show: boolean;
}

export const useCookieSettingsDialogStore = defineStore(
    "useCookieSettingsDialog",
    {
        state: () => {
            return {
                cookieConsent: {
                    statistics: false,
                    marketing: false,
                    show: false,
                },
                activePage: "intro",
            };
        },
        getters: {
            /**
             * Get client cookie
             *
             * @returns {ICookieConsent}
             */
            getCookieConsent(state): ICookieConsent {
                return state.cookieConsent;
            },

            /**
             * Get dialog active page
             *
             * @returns {string}
             */
            getCookieConsentActivePage(state): string {
                return state.activePage;
            },
        },
        actions: {
            /**
             * Set cookie consent
             *
             * @param string cookiesString required only for cookie-consent middleware (server)
             */
            setCookieConsent(cookiesString: any) {
                return new Promise(() => {
                    try {
                        if (cookiesString) {
                            this.cookieConsent = cookiesString;
                        } else if (
                            useCookie("prizific-cookie-consent", { maxAge: 60 * 60 * 24 * 100 })?.value?.length
                            // cookie.get("prizific-cookie-consent")?.length
                        ) {
                            this.cookieConsent = useCookie(
                                "prizific-cookie-consent"
                            )?.value as any;
                        } else {
                            this.cookieConsent.show = true;
                        }

                        if (this.cookieConsent.statistics) {
                            // this.$pgtag.enable();
                            //useGtagConsent(true);
                        } else {
                            // this.$pgtag.disable();
                            //useGtagConsent(false);
                        }
                    } catch (e) {
                        console.error("setCookieConsent", e);

                        this.cookieConsent.show = true;
                    }
                });
            },

            /**
             * Set active page
             *
             * @param {*} page
             */
            setActivePage(page: any) {
                this.activePage = page;
            },

            /**
             * Set all cookie true and saving in cookie
             */
            approveAllCookie() {
                this.cookieConsent.statistics = true;
                this.cookieConsent.marketing = true;
                this.cookieConsent.show = false;

                useCookie("prizific-cookie-consent", { maxAge: 60 * 60 * 24 * 100 }).value = JSON.stringify({
                    statistics: true,
                    marketing: true,
                    show: false,
                });

                const trackingStore = useTrackingStore();
                
                if(this.cookieConsent && this.cookieConsent.statistics){
                    trackingStore.enable();
                }else{
                    trackingStore.disable();
                }

                // cookie.set(
                //     "prizific-cookie-consent",
                //     JSON.stringify({
                //         statistics: true,
                //         marketing: true,
                //         show: false,
                //     })
                // );
            },

            /**
             * Set selected cookies true and saving in cookie
             */
            approveSelectedCookies(selectedCookies: any) {
                this.cookieConsent.show = false;

                useCookie("prizific-cookie-consent", { maxAge: 60 * 60 * 24 * 100 }).value = JSON.stringify({
                    statistics: selectedCookies.statistics,
                    marketing: selectedCookies.marketing,
                    show: false,
                });

                const trackingStore = useTrackingStore();
                if(selectedCookies && selectedCookies.statistics){
                    trackingStore.enable();
                }else{
                    trackingStore.disable();
                }

                // cookie.set(
                //     "prizific-cookie-consent",
                //     JSON.stringify({
                //         statistics: selectedCookies.statistics,
                //         marketing: selectedCookies.marketing,
                //         show: false,
                //     })
                // );
            },

            /**
             * Show cookie dialog
             */
            showCookieDialog() {
                this.cookieConsent.show = true;
            },

            /**
             * Close cookie dialog
             */
            closeCookieDialog() {
                this.cookieConsent.show = false;
            },

            /**
             * Set google analytics
             * @deprecated
             */
            setAnalytics() {
                
            },
        },
    }
);
