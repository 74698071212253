import Prizes from "@/entities/PrizificPageBuilder/sections/Prizes";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { prizesModal } from "./components/PrizesModal/PrizesModal";
import {
    prizeFontFamily,
    prizesBackground,
    prizeImageWidth,
    prizesTypeTextColor,
    prizesTextColor,
    prizesMoreTextColor,
    prizesMoreHoverTextColor,
    prizesTypeFontSize,
    prizesPrizeNameFontSize,
    prizesDescriptionFontSize,
    prizesMoreFontSize,
} from "./PrizesProperties";

export const prizes = new Prizes(
    "-",
    "Prizes",
    [
        prizeFontFamily,
        prizesBackground,
        prizeImageWidth,
        prizesTypeTextColor,
        prizesTextColor,
        prizesMoreTextColor,
        prizesMoreHoverTextColor,
        prizesTypeFontSize,
        prizesPrizeNameFontSize,
        prizesDescriptionFontSize,
        prizesMoreFontSize,
    ],
    "",
    [prizesModal],
    "",
    "",
    "",
    "",
    ""
);
