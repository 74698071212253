import Property from "@/entities/PrizificPageBuilder/Property";
import { EInputType } from "@/enums/PrizificPageBuilder/EInputType";
import { EProperty } from "@/enums/PrizificPageBuilder/EProperty";
import { ERoles } from "@/enums/PrizificPageBuilder/ERoles";
import { EState } from "@/enums/PrizificPageBuilder/EState";
import { EUnit } from "@/enums/PrizificPageBuilder/EUnit";

export const recipeUploadFontFamily = new Property(
    "Font family",
    "",
    EState.DEFAULT,
    EProperty.FONT_FAMILY,
    EInputType.FONT_SELECT,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "recipeUpload"
);

export const recipeUploadBackground = new Property(
    "Background color",
    "#fff",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "recipeUpload"
);

export const recipeUploadColor = new Property(
    "Text color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "recipeUploadTitle"
);

export const recipeUploadFontSize = new Property(
    "Text font size",
    24,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "recipeUploadTitle",
    EUnit.PX,
    11,
    32
);
