import Modal from "@/entities/PrizificPageBuilder/components/Modal";
import { EModal } from "~~/enums/PrizificPageBuilder/EModal";

export const couponCodeConflictModal = new Modal(
    "-", 
    "Coupon code conflict modal",
    [],
    "",
    [],
    "title",
    "description",
    "",
    "button",
    "title",
    "description",
    "",
    "button",
    EModal.COUPONCODECONFLICT
);
