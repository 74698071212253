import type { RouterConfig } from "@nuxt/schema";

export default <RouterConfig>{
    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve) => {
            if (savedPosition) resolve(savedPosition);

            // If no hash than scroll to top
            if (!to.hash) {
                resolve({
                    left: 0,
                    top: 0,
                });
            }

            // If the hash is on the same path then scroll to hash
            if (to.hash && to.path == from.path) {
                const el: any = document.querySelector(to.hash);
                const navBarHeight: any = (
                    document.getElementById("navbar") as any
                ).offsetHeight;
                const top =
                    el.getBoundingClientRect().top +
                    window.pageYOffset -
                    navBarHeight;

                resolve({
                    left: 0,
                    top,
                });

                if (to.hash == "#kodfeltoltes") {
                    document.getElementById("apCode")?.focus();
                }
            }

            // If the hash does not point to the from path than we need wait to page load then scroll to hash
            setTimeout(() => {
                if (to.hash && to.path != from.path) {
                    const el: any = document.querySelector(to.hash);
                    const navBarHeight: any = (
                        document.getElementById("navbar") as any
                    ).offsetHeight;
                    const top =
                        el.getBoundingClientRect().top +
                        window.pageYOffset -
                        navBarHeight;

                    resolve({
                        left: 0,
                        top,
                    });

                    if (to.hash == "#kodfeltoltes") {
                        document.getElementById("apCode")?.focus();
                    }
                }
            }, 1000);
        });
    },
};
