import FormItem from "@/entities/PrizificPageBuilder/components/FormItem";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { ECodeUploadFormItem } from "~/enums/PrizificPageBuilder/ECodeUploadFormItem";
import {
    modalSelectedBackground,
    modalSelectedBorderColor,
    modalSelectedTextColor,
} from "./PurchasedPromotionProductFormItemProperties";

export const purchasedPromotionProductFormItem = new FormItem(
    "-",
    "Purchased promotion product form item",
    [modalSelectedBackground, modalSelectedTextColor, modalSelectedBorderColor],
    "",
    [],
    "Mit vásároltál?",
    "Válassz terméket",
    "Mit vásároltál?",
    "Válassz terméket",
    ECodeUploadFormItem.PURCHASED_PROMOTION_PRODUCT
);
