import Form from "@/entities/PrizificPageBuilder/components/Form";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import WinnersList from "~~/entities/PrizificPageBuilder/components/WinnersList";
import {
    winnersListToggleButtonBackground,
    winnersListToggleButtonBorderColor,
    winnersListToggleButtonActiveColor,
    winnersListToggleButtonActiveBackground,
    winnersListToggleButtonBorderRadius,
    winnersListItemBackground,
    winnersListItemBorderColor,
    winnersListItemBorderWidth,
    winnersListItemBorderRadius,
    winnersListToggleButtonColor,
    winnersListItemPadding,
    winnersListItemColor,
} from "./WinnersListProperties";

export const winnersList = new WinnersList(
    "-",
    "Winners list",
    [
        winnersListToggleButtonColor,
        winnersListToggleButtonBackground,
        winnersListToggleButtonBorderColor,
        winnersListToggleButtonActiveColor,
        winnersListToggleButtonActiveBackground,
        winnersListToggleButtonBorderRadius,
        winnersListItemBackground,
        winnersListItemColor,
        winnersListItemBorderColor,
        winnersListItemBorderWidth,
        winnersListItemBorderRadius,
        winnersListItemPadding,
    ],
    "",
    [],
    "Főnyeremény",
    "Főnyeremény",
    "Havi nyeremény",
    "Havi nyeremény",
    "Heti nyeremény",
    "Heti nyeremény",
    "Napi nyeremény",
    "Napi nyeremény"
);
