import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import RecipeUpload from "@/entities/PrizificPageBuilder/sections/RecipeUpload";
import { AComponentFactory } from "../ComponentFactory";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { recipeUpload } from "~~/entities/pages/winner/sections/RecipeUpload/RecipeUpload";

export class RecipeUploadFactory extends AComponentFactory<RecipeUpload> {
    getTypeName() {
        return EComponents.RECIPE_UPLOAD;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, [
            "data.specific.sectionTitleCompiled",
            "data.specific.sectionParagraphCompiled",
            "data.specific.sectionTitle",
            "data.specific.sectionParagraph",
        ]);

        return new RecipeUpload(
            rawData.id,
            rawData.data.title,
            usePropertiesMerger(properties, recipeUpload.style),
            rawData.data.classNames,
            subComponents,
            getConvertedText(rawData.data.specific.sectionTitleCompiled),
            getConvertedText(rawData.data.specific.sectionParagraphCompiled),
            rawData.data.specific.sectionTitle,
            rawData.data.specific.sectionParagraph
        );
    }
}
