import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { AComponentFactory } from "../ComponentFactory";
import { EComponents } from "~/enums/PrizificPageBuilder/EComponents";
import UserConsenting from "~/entities/PrizificPageBuilder/sections/UserConsenting";
import { EHiveLoginText } from "~~/enums/PrizificPageBuilder/EHiveLoginText";
import Text from "~~/entities/PrizificPageBuilder/components/Text";
import { userConsentingGroup } from "~~/entities/pages/home/sections/UserConsenting/components/UserConsentingGroup";
import {
    userConsentingBackground,
    userConsentingTitleColor,
    userConsentingTitleFontSize,
    userConsentingParagraphColor,
    userConsentingParagraphFontSize,
    userConsentingPrimaryColor,
    userConsentingSecondaryColor,
} from "~~/entities/pages/home/sections/UserConsenting/UserConsentingProperty";
import { userConsent } from "~~/entities/pages/home/sections/UserConsenting/UserConsent";

export class UserConsentingFactory extends AComponentFactory<UserConsenting> {
    getTypeName() {
        return EComponents.USER_CONSENTING;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, [
            // "data.specific.sectionTitleCompiled",
            // "data.specific.sectionParagraphCompiled",
            // "data.specific.sectionTitle",
            // "data.specific.sectionParagraph",
        ]);

        return new UserConsenting(
            rawData.id,
            rawData.data.title,
            usePropertiesMerger(properties, userConsent.style),
            rawData.data.classNames,
            subComponents,
            rawData.data.specific.images
                ? rawData.data.specific.images
                : [
                      {
                          src: "",
                          imageType: "extra-extra-large",
                          imageLabel: "1920px",
                      },
                      {
                          src: "",
                          imageType: "extra-large",
                          imageLabel: "1400px",
                      },
                      {
                          src: "",
                          imageType: "large",
                          imageLabel: "1200px",
                      },
                      {
                          src: "",
                          imageType: "medium",
                          imageLabel: "992px",
                      },
                      {
                          src: "",
                          imageType: "small",
                          imageLabel: "768px",
                      },
                      {
                          src: "",
                          imageType: "extra-small",
                          imageLabel: "576px",
                      },
                  ]
        );
    }

    createPlain() {
        return new UserConsenting(
            "-",
            "User consent",
            [
                userConsentingBackground,
                userConsentingTitleColor,
                userConsentingTitleFontSize,
                userConsentingParagraphColor,
                userConsentingParagraphFontSize,
                userConsentingPrimaryColor,
                userConsentingSecondaryColor,
            ],
            "",
            [userConsentingGroup],
            [
                {
                    src: "",
                    imageType: "extra-extra-large",
                    imageLabel: "1920px",
                },
                {
                    src: "",
                    imageType: "extra-large",
                    imageLabel: "1400px",
                },
                {
                    src: "",
                    imageType: "large",
                    imageLabel: "1200px",
                },
                {
                    src: "",
                    imageType: "medium",
                    imageLabel: "992px",
                },
                {
                    src: "",
                    imageType: "small",
                    imageLabel: "768px",
                },
                {
                    src: "",
                    imageType: "extra-small",
                    imageLabel: "576px",
                },
            ]
        );
    }
}
