import FormItem from "@/entities/PrizificPageBuilder/components/FormItem";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { ERegistrationFormItem } from "~~/enums/PrizificPageBuilder/ERegistrationFormItem";

export const overEighteenFormItem = new FormItem(
    "-",
    "Over 18 form item",
    [],
    "",
    [],
    "",
    "",
    "",
    "",
    ERegistrationFormItem.OVER_EIGHTEEN,
    "Elmúltam 18 éves.",
    "Elmúltam 18 éves.",
    false
);
