import CodeUploadInformation from "~~/entities/PrizificPageBuilder/sections/CodeUploadInformation";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import {
    codeUploadInformationFontFamily,
    codeUploadInformationBackground,
    codeUploadInformationColor,
    codeUploadInformationFontSize,
    codeUploadInformationBoxBorderRadius,
    codeUploadInformationBoxPadding,
    codeUploadInformationBoxBackground,
    codeUploadInformationBoxFontSize,
    codeUploadInformationBoxColor,
} from "./CodeUploadInformationProperties";

export const codeUploadInformation = new CodeUploadInformation(
    "",
    "Code upload information",
    [
        codeUploadInformationFontFamily,
        codeUploadInformationBackground,
        codeUploadInformationColor,
        codeUploadInformationFontSize,
        codeUploadInformationBoxBackground,
        codeUploadInformationBoxFontSize,
        codeUploadInformationBoxColor,
        codeUploadInformationBoxBorderRadius,
        codeUploadInformationBoxPadding,
    ],
    "",
    [],
    "Ezzel a kódfeltöltéssel nyertél",
    "",
    "Ezzel a kódfeltöltéssel nyertél",
    ""
);
