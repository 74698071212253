import NavItem from "@/entities/PrizificPageBuilder/components/NavItem";

import {
    codeUploadNavItemFontSize,
    codeUploadNavItemPadding,
    codeUploadNavItemBorderRadius,
    codeUploadNavItemTextColor,
    codeUploadNavItemTextColorHover,
    codeUploadNavItemBackgroundColor,
    codeUploadNavItemBackgroundColorHover,
} from "./CodeUploadNavItemProperties";

export const codeUploadNavItem = new NavItem(
    "-",
    null,
    false,
    "Kódfeltöltés",
    [
        codeUploadNavItemFontSize,
        codeUploadNavItemPadding,
        codeUploadNavItemBorderRadius,
        codeUploadNavItemTextColor,
        codeUploadNavItemTextColorHover,
        codeUploadNavItemBackgroundColor,
        codeUploadNavItemBackgroundColorHover,
    ],
    "",
    [],
    "Kódfeltöltés",
    "Kódfeltöltés",
    "/#kodfeltoltes",
    false
);
