import { useCookieSettingsDialogStore } from "@/store/components/cookieSettingsDialog/cookieSettingsDialog";
import { useCampaignDataStore } from "~~/store/campaign/campaignData";

export default defineNuxtRouteMiddleware(async (to, from) => {

    const cookieSettingsDialogStore = useCookieSettingsDialogStore();


    try {
        const campaignDataStore = useCampaignDataStore();
        //On staroprament campaign skip the cookie consent
        if (
            campaignDataStore.campaignUUId == "a46bdb28-0c9b-463b-a48e-b2e78acd71b8" ||
            campaignDataStore.campaignUUId == "cc601dc3-716a-4cb3-a994-14d3ec111761"
        ) {
            return 
        }
        cookieSettingsDialogStore.setCookieConsent(
            useCookie("prizific-cookie-consent").value
        );
    } catch (e) {
        console.error("Error while pare cookie json", e);
    }
});
