export enum EProperty {
    BACKGROUND_COLOR = "backgroundColor",
    FONT_FAMILY = "fontFamily",
    FONT_SIZE = "fontSize",
    BORDER_RADIUS = "borderRadius",
    BORDER_COLOR = "borderColor",
    BORDER_WIDTH = "borderWidth",
    PADDING = "padding",
    COLOR = "color",
    WIDTH = "width",
    MAX_WIDTH = "maxWidth",
    HEIGHT = "height",
    FILL = "fill",
    STROKE = "stroke",
}
