import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import AComponent from "../AComponent";

export default class Group
    extends AComponent<EComponents.GROUP>
    implements IComponent<EComponents.GROUP>
{
    constructor(
        id: string,
        title: string,
        style: Array<IProperty>,
        classNames: string,
        components: Array<IComponent<EComponents>>
    ) {
        super(id, title, EComponents.GROUP, style, classNames, components);
    }
}
