import { IPage } from "@/interfaces/PrizificPageBuilder/IPage";
import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import Page from "@/entities/PrizificPageBuilder/Page";
import {
    RuntimeComponentFactoryNotFoundError,
    RuntimeComponentParseError,
} from "@/error/RuntimeError";
import { ComponentFactory } from "./ComponentFactory";
import Property from "~/entities/PrizificPageBuilder/Property";
import CookieSettings from "~~/entities/PrizificPageBuilder/sections/CookieSettings";
import { cookieSettings } from "~~/entities/pages/shared/sections/CookieSettings/CookieSettings";

export class PageBuilderFactory {
    constructor(protected componentFactories: Array<ComponentFactory<any>>) { }

    createPage<T extends EComponents>(pageData: any, type: T): IPage<T> {
        const pageId = pageData.page.id ?? -1;
        const pageName = pageData.page.name ?? "-";
        // const pageClassNames = pageData.page.classNames ?? "";
        // const pageGlobalConfig = pageData.page.globalConfig ?? {};

        const components: IComponent<EComponents>[] = (
            pageData.page.components ?? []
        ).map((rawComponentData: any) => {
            return this.createComponent(rawComponentData);
        });

        const settingsExists = components.find((comp) => {
            return comp.getType() == EComponents.COOKIE_SETTINGS;
        })

        if(!settingsExists){
            components.push(cookieSettings)
        }

        const rawProperties: any = [];
        const properties: IProperty[] = rawProperties.map((rawProperty: any) =>
            this.createProperty(rawProperty)
        );

        const page = new Page(
            pageId,
            pageName,
            type,
            properties,
            "",
            components,
            {}
        );

        return page;
    }

    /**
     * Create a component by the server response or the given format
     * TODO: add interface for this!
     *
     * @param componentData
     * @returns
     */
    createComponent(componentData: any): IComponent<EComponents> {
        if (!componentData.data || !componentData.data.type) {
            console.error("Component type Not found!--->",componentData);
            throw new RuntimeComponentParseError("Component type Not found!");
        }

        const componentType = componentData.data.type;
        const componentFactory = this.componentFactories.find(
            (parser: ComponentFactory<any>) => {
                return parser.getTypeName() == componentType;
            }
        );

        if (!componentFactory) {
            throw new RuntimeComponentFactoryNotFoundError(
                "Component Factory not found for: " + componentType
            );
        }

        const rawSubComponents = componentData.components ?? [];

        const subComponents = rawSubComponents.map((rawComponent: any) =>
            this.createComponent(rawComponent)
        );

        const rawProperties = componentData.data.style ?? [];
        const properties: IProperty[] = rawProperties.map((rawProp: any) =>
            this.createProperty(rawProp)
        );
        
        return componentFactory.create(
            componentData,
            subComponents,
            properties
        ); //TODO: add properties
    }
    /**
     * Create a  new plain component with factory, and return with the created component
     * 
     * TODO: add more type guard in here
     */
    createPlainComponent(componentType: EComponents): IComponent<EComponents> {
        const componentFactory = this.componentFactories.find(
            (parser: ComponentFactory<any>) => {
                return parser.getTypeName() == componentType.toString();
            }
        );

        if (!componentFactory) {
            throw new RuntimeComponentFactoryNotFoundError(
                "Component Factory not found for: " + componentType
            );
        }

        return componentFactory.createPlain();
    }


    createProperty(stylePropertyRawData: any): IProperty {
        return new Property(
            stylePropertyRawData.label,
            stylePropertyRawData.value,
            stylePropertyRawData.state,
            stylePropertyRawData.property,
            stylePropertyRawData.inputType,
            stylePropertyRawData.roles,
            stylePropertyRawData.belongsTo,
            stylePropertyRawData.unit,
            stylePropertyRawData.min,
            stylePropertyRawData.max
        );
    }

    /**
     * All available component type, wh has a parser
     */
    getAvailableComponentTypes() {
        return this.componentFactories.map((factory) => factory.getTypeName())
    }
}
