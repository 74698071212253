import Modal from "@/entities/PrizificPageBuilder/components/Modal";
import { EModal } from "~~/enums/PrizificPageBuilder/EModal";

export const apCodeNotWinModal = new Modal(
    "-", 
    "Ap code not win modal",
    [],
    "",
    [],
    "Sajnos nem nyertél!",
    "Sajnos nem nyertél leírás",
    "",
    "Bezár",
    "Sajnos nem nyertél!",
    "Sajnos nem nyertél leírás",
    "",
    "Bezár",
    EModal.APCODENOTWIN
);
