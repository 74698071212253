import Property from "@/entities/PrizificPageBuilder/Property";
import { EInputType } from "@/enums/PrizificPageBuilder/EInputType";
import { EProperty } from "@/enums/PrizificPageBuilder/EProperty";
import { ERoles } from "@/enums/PrizificPageBuilder/ERoles";
import { EState } from "@/enums/PrizificPageBuilder/EState";
import { EUnit } from "@/enums/PrizificPageBuilder/EUnit";

export const cookieSettingsFontFamily = new Property(
    "Font family",
    "",
    EState.DEFAULT,
    EProperty.FONT_FAMILY,
    EInputType.FONT_SELECT,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "cookieSettingsBackground"
);

export const cookieSettingsBackground = new Property(
    "Background color",
    "#d3ab90",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "cookieSettingsBackground"
);

export const cookieSettingsTitleColor = new Property(
    "Title color",
    "#000000",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "cookieSettingsTitle"
);

export const cookieSettingsTitleFontSize = new Property(
    "Title font size",
    28,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "cookieSettingsTitle",
    EUnit.PX,
    16,
    48
);

export const cookieSettingsParagraphColor = new Property(
    "Paragraph color",
    "#000000",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "cookieSettingsParagraph"
);

export const cookieSettingsParagraphFontSize = new Property(
    "Paragraph font size",
    16,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "cookieSettingsParagraph",
    EUnit.PX,
    16,
    48
);

export const modalCloseIconColor = new Property(
    "Close icon color",
    "#000000",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "cookieSettingsClose"
);

export const modalLeftButtonBackground = new Property(
    "Left button background color",
    "#ffffff",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalLeftButton"
);

export const modalLeftButtonColor = new Property(
    "Left button text color",
    "#000000",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalLeftButton"
);

export const modalLeftButtonBorderColor = new Property(
    "Left button border color",
    "#ffffff",
    EState.DEFAULT,
    EProperty.BORDER_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalLeftButton"
);

export const modalLeftButtonFontSize = new Property(
    "Left button font size",
    16,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalLeftButton",
    EUnit.PX,
    13,
    24
);

export const modalLeftButtonPadding = new Property(
    "Left button padding",
    "20px 18px",
    EState.DEFAULT,
    EProperty.PADDING,
    EInputType.TEXT,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalLeftButton"
);

export const modalLeftButtonBorderRadius = new Property(
    "Left button border radius",
    10,
    EState.DEFAULT,
    EProperty.BORDER_RADIUS,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalLeftButton",
    EUnit.PX,
    0,
    24
);

export const modalRightButtonBackground = new Property(
    "Right button background color",
    "#e44f60",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalRightButton"
);

export const modalRightButtonColor = new Property(
    "Right button text color",
    "#f7f3e8",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalRightButton"
);

export const modalRightButtonBorderColor = new Property(
    "Right button border color",
    "#e44f60",
    EState.DEFAULT,
    EProperty.BORDER_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalRightButton"
);

export const modalRightButtonBorderWidth = new Property(
    "Right button border width",
    1,
    EState.DEFAULT,
    EProperty.BORDER_WIDTH,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalRightButton",
    EUnit.PX,
    0,
    8
);

export const modalRightButtonFontSize = new Property(
    "Right button font size",
    16,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalRightButton",
    EUnit.PX,
    13,
    24
);

export const modalRightButtonPadding = new Property(
    "Right button padding",
    "20px 18px",
    EState.DEFAULT,
    EProperty.PADDING,
    EInputType.TEXT,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalRightButton"
);

export const modalRightButtonBorderRadius = new Property(
    "Right button border radius",
    10,
    EState.DEFAULT,
    EProperty.BORDER_RADIUS,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalRightButton",
    EUnit.PX,
    0,
    24
);
