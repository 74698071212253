import Property from "@/entities/PrizificPageBuilder/Property";
import { EInputType } from "@/enums/PrizificPageBuilder/EInputType";
import { EProperty } from "@/enums/PrizificPageBuilder/EProperty";
import { ERoles } from "@/enums/PrizificPageBuilder/ERoles";
import { EState } from "@/enums/PrizificPageBuilder/EState";
import { EUnit } from "@/enums/PrizificPageBuilder/EUnit";

export const uploadedCodesCardHeadBackground = new Property(
    "Card head background color",
    "#fff",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "uploadedCodesCardHead"
);

export const uploadedCodesCardHeadTextColor = new Property(
    "Card head text color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "uploadedCodesCardHead"
);

export const uploadedCodesCardHeadTextFontSize = new Property(
    "Card head font size",
    12,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "uploadedCodesCardHead",
    EUnit.PX,
    12,
    16
);

export const uploadedCodesCardSwiperPaginationBackground = new Property(
    "Card swiper pagination background color",
    "#444444",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "uploadedCodesCardSwiperPagination"
);

export const uploadedCodesCardSwiperPaginationBorderColor = new Property(
    "Card swiper pagination border color",
    "#444444",
    EState.DEFAULT,
    EProperty.BORDER_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "uploadedCodesCardSwiperPagination"
);

export const uploadedCodesCardSwiperNavigationColor = new Property(
    "Card swiper navigation color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "uploadedCodesCardSwiperNavigation"
);

export const uploadedCodesCardBodyBackground = new Property(
    "Card body background color",
    "#444444",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "uploadedCodesCardBody"
);

export const uploadedCodesCardBodyTextColor = new Property(
    "Card body text color",
    "#fff",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "uploadedCodesCardBody"
);

export const uploadedCodesCardBodyTextFontSize = new Property(
    "Card body font size",
    12,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "uploadedCodesCardBody",
    EUnit.PX,
    12,
    16
);

export const uploadedCodesCardBodyWinnerCodeUploadBackground = new Property(
    "Card body winner code upload background color",
    "#444444",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "uploadedCodesCardBodyWinnerCodeUpload"
);

export const uploadedCodesCardBodyWinnerCodeUploadTextColor = new Property(
    "Card body winner code upload text color",
    "#fff",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "uploadedCodesCardBodyWinnerCodeUpload"
);

export const uploadedCodesCardBodyWinnerCodeUploadTextFontSize = new Property(
    "Card body winner code upload font size",
    12,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "uploadedCodesCardBodyWinnerCodeUpload",
    EUnit.PX,
    12,
    16
);
