import FormItem from "@/entities/PrizificPageBuilder/components/FormItem";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { ELoginFormItem } from "~/enums/PrizificPageBuilder/ELoginFormItem";

export const passwordFormItem = new FormItem(
    "-",
    "Password form item",
    [],
    "",
    [],
    "Jelszó",
    "",
    "Jelszó",
    "",
    ELoginFormItem.PASSWORD
);
