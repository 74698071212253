import type { UseFetchOptions } from "nuxt/app";
import type { NitroFetchOptions } from "nitropack";

export function useApiFetch<T>(url: string, options: UseFetchOptions<T> = {}) {
    let internalOptions: UseFetchOptions<any> = {};
    if (options) {
        internalOptions = options;
    }

    internalOptions.baseURL = useApiBaseUrl();
    try {
        const config = useRuntimeConfig();
        if (config.testCampaignBearerToken) {
            if (!internalOptions.headers) {
                internalOptions.headers = {};
            }

            if (internalOptions.headers["authorization"] === undefined) {
                internalOptions.headers["authorization"] =
                    "Bearer " + config.testCampaignBearerToken;
            }
        }
    } catch (e) {
        console.warn("e", e);
    }
    //console.log("FetchURL", internalOptions.baseURL, url);
    return useFetch(url, internalOptions);
}

/**
 * Use only in pinia
 */
export async function usePiniaFetch<T>(url: string, options: NitroFetchOptions<any> = {}): Promise<T> {

    let internalOptions: NitroFetchOptions<any> = {};
    if (options) {
        internalOptions = options;
    }

    internalOptions.baseURL = useApiBaseUrl();
    try {
        const config = useRuntimeConfig();
        if (config.testCampaignBearerToken) {
            if (!internalOptions.headers) {
                internalOptions.headers = {};
            }

            if (internalOptions.headers["authorization"] === undefined) {
                internalOptions.headers["authorization"] =
                    "Bearer " + config.testCampaignBearerToken;
            }
        }
    } catch (e) {
        console.warn("e", e);
    }
    //console.log("FetchURL", internalOptions.baseURL, url);
    return await $fetch(url, internalOptions) as any;
}

//This should only for szentkirályi
export function useDirectApiFetch(url: string, options: NitroFetchOptions<any> = {}): Promise<Response> {
    const config = useRuntimeConfig();

    let internalOptions: UseFetchOptions<any> = {};
    if (options) {
        internalOptions = options;
    }

    internalOptions.baseURL = "https://campaign-proxy.prizific.com/tehetsegek/api";

    if (config.testCampaignBearerToken) {
        if (!internalOptions.headers) {
            internalOptions.headers = {};
        }

        if (internalOptions.headers["authorization"] === undefined) {
            internalOptions.headers["authorization"] =
                "Bearer " + config.testCampaignBearerToken;
        }
    }
    //console.log("FetchURL", internalOptions.baseURL, url);
    return useFetch(url, internalOptions);
}
