import Property from "@/entities/PrizificPageBuilder/Property";
import { EInputType } from "@/enums/PrizificPageBuilder/EInputType";
import { EProperty } from "@/enums/PrizificPageBuilder/EProperty";
import { ERoles } from "@/enums/PrizificPageBuilder/ERoles";
import { EState } from "@/enums/PrizificPageBuilder/EState";

export const navBarFontFamily = new Property(
    "Font family",
    "",
    EState.DEFAULT,
    EProperty.FONT_FAMILY,
    EInputType.FONT_SELECT,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "navbar"
);

export const navBarBackgroundColor = new Property(
    "Background color",
    "#C8C8C8",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "navbar"
);

export const navBarPadding = new Property(
    "Padding",
    "5px",
    EState.DEFAULT,
    EProperty.PADDING,
    EInputType.TEXT,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "navbar"
);

export const mobileNavBarBackgroundColor = new Property(
    "Mobile background color",
    "#C8C8C8",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "mobileNavbar"
);

export const mobileNavBarTextColor = new Property(
    "Mobile text color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "mobileNavbar"
);

export const mobileNavBarTextHoverColor = new Property(
    "Mobile hover text color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "mobileNavbarHover"
);
