import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { AComponentFactory } from "../ComponentFactory";
import Modal from "@/entities/PrizificPageBuilder/components/Modal";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";

export class ModalFactory extends AComponentFactory<Modal> {
    getTypeName() {
        return EComponents.MODAL;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, [
            // "data.specific.modalTitleCompiled",
            // "data.specific.modalDescriptionCompiled",
            // "data.specific.modalLeftButtonTextCompiled",
            // "data.specific.modalRightButtonTextCompiled",
            // "data.specific.modalTitle",
            // "data.specific.modalDescription",
            // "data.specific.modalLeftButtonText",
            // "data.specific.modalRightButtonText",
            // "data.specific.belongsTo",
        ]);

        return new Modal(
            rawData.id,
            rawData.data.title,
            properties,
            rawData.data.classNames,
            subComponents,
            getConvertedText(rawData.data.specific.modalTitleCompiled),
            getConvertedText(rawData.data.specific.modalDescriptionCompiled),
            getConvertedText(rawData.data.specific.modalLeftButtonTextCompiled),
            getConvertedText(
                rawData.data.specific.modalRightButtonTextCompiled
            ),
            rawData.data.specific.modalTitle,
            rawData.data.specific.modalDescription,
            rawData.data.specific.modalLeftButtonText,
            rawData.data.specific.modalRightButtonText,
            rawData.data.specific.belongsTo
        );
    }

    createPlain() {
        return new Modal(
            "-",
            "Modal",
            [],
            "",
            [],
            getConvertedText("Default title"),
            getConvertedText("Default description"),
            getConvertedText("Default left button"),
            getConvertedText("Default right button"),
            "Default title",
            "Default description",
            "Default left button",
            "Default right button",
            ""
        );
    }
}
