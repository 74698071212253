import Faqs from "~~/entities/PrizificPageBuilder/sections/Faqs";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import {
    faqsFontFamily,
    faqsBackground,
    faqsTitleColor,
    faqsTitleFontSize,
    faqsCardBorderColor,
    faqsCardBorderWidth,
    faqsCardTitleColor,
    faqsCardTitleFontSize,
    faqsCardBodyColor,
    faqsCardBodyFontSize,
    faqsCardIconColor,
} from "./FaqsProperties";

export const faqs = new Faqs(
    "-",
    "Faqs",
    [
        faqsFontFamily,
        faqsBackground,
        faqsTitleColor,
        faqsTitleFontSize,
        faqsCardBorderColor,
        faqsCardBorderWidth,
        faqsCardTitleColor,
        faqsCardTitleFontSize,
        faqsCardBodyColor,
        faqsCardBodyFontSize,
        faqsCardIconColor,
    ],
    "",
    [],
    "Gyakori kérdések",
    "",
    "Gyakori kérdések",
    "",
    ""
);
