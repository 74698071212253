import Property from "@/entities/PrizificPageBuilder/Property";
import { EInputType } from "@/enums/PrizificPageBuilder/EInputType";
import { EProperty } from "@/enums/PrizificPageBuilder/EProperty";
import { ERoles } from "@/enums/PrizificPageBuilder/ERoles";
import { EState } from "@/enums/PrizificPageBuilder/EState";
import { EUnit } from "@/enums/PrizificPageBuilder/EUnit";
import Text from "~~/entities/PrizificPageBuilder/components/Text";

export const promoProductsFontFamily = new Property(
    "Font family",
    "",
    EState.DEFAULT,
    EProperty.FONT_FAMILY,
    EInputType.FONT_SELECT,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "promoProductsBackground"
);

export const promoProductsBackground = new Property(
    "Background color",
    "#878787",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "promoProductsBackground"
);

export const promoProductsTitleColor = new Property(
    "Title text color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "promoProductsTitle"
);

export const promoProductsTitleFontSize = new Property(
    "Title font size",
    32,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "promoProductsTitle",
    EUnit.PX,
    24,
    48
);

export const promoProductsParagraphColor = new Property(
    "Paragrap text color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "promoProductsParagraph"
);

export const promoProductsParagraphFontSize = new Property(
    "Paragraph font size",
    12,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "promoProductsParagraph",
    EUnit.PX,
    6,
    48
);

export const promoProductsImageWidth = new Property(
    "Image width",
    153,
    EState.DEFAULT,
    EProperty.WIDTH,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "promoProductsImage",
    EUnit.PX,
    100,
    254
);

export const promoProductsNameColor = new Property(
    "Product name color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "promoProductsName"
);

export const promoProductsSwiperPagination = new Property(
    "Swiper pagination color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "promoProductsSwiperPagination"
);

export const promoProductsSwiperPaginationActive = new Property(
    "Swiper pagination active color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "promoProductsSwiperPaginationActive"
);

export const promoProductsSwiperArrow = new Property(
    "Swiper arrow color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "promoProductsSwiperArrow"
);

export const promoProductsSwiperArrowSize = new Property(
    "Swiper arrow size",
    44,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "promoProductsSwiperArrow",
    EUnit.PX,
    16,
    56
);

export const promoProductsAfterColor = new Property(
    "Bottom text color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "afterPromoProductText"
);

export const promoProductsAfterFontSize = new Property(
    "Bottom text font size",
    12,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "afterPromoProductText",
    EUnit.PX,
    6,
    48
);

export const afterPromoProductsText = new Text(
    "-",
    "Bottom Text",
    [],
    "",
    [],
    "After Promo Products Text",
    "After Promo products Text",
    "afterPromoProductText"
);
