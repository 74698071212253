import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import UploadedCodesCard from "@/entities/PrizificPageBuilder/components/UploadedCodesCard";
import { AComponentFactory } from "../ComponentFactory";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { uploadedCodesCard } from "~~/entities/pages/uploaded-codes/components/UploadedCodesCard";

export class UploadedCodesCardFactory extends AComponentFactory<UploadedCodesCard> {
    getTypeName() {
        return EComponents.UPLOADED_CODES_CARD;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, [
            "data.specific.purchaseDateTextCompiled",
            "data.specific.uploadeInformationTextCompiled",
            "data.specific.purchaseDateText",
            "data.specific.uploadeInformationText",
        ]);

        return new UploadedCodesCard(
            rawData.id,
            rawData.data.title,
            usePropertiesMerger(properties, uploadedCodesCard.style),
            rawData.data.classNames,
            subComponents,
            getConvertedText(rawData.data.specific.purchaseDateTextCompiled),
            getConvertedText(
                rawData.data.specific.uploadeInformationTextCompiled
            ),
            rawData.data.specific.purchaseDateText,
            rawData.data.specific.uploadeInformationText
        );
    }
}
