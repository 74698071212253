import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import HeroImage from "@/entities/PrizificPageBuilder/sections/HeroImage";
import { RuntimeComponentParseError } from "@/error/RuntimeError";
import { AComponentFactory } from "../ComponentFactory";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { heroImage } from "~~/entities/pages/home/sections/HeroImage/HeroImage";

export class HeroImageFactory extends AComponentFactory<HeroImage> {
    getTypeName() {
        return EComponents.HERO_IMAGE;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, [
            // "data.specific.sectionTitleCompiled",
            // "data.specific.sectionParagraphCompiled",
            // "data.specific.sectionTitle",
            // "data.specific.sectionParagraph",
            // "data.specific.images",
        ]);

        return new HeroImage(
            rawData.id,
            rawData.data.title,
            usePropertiesMerger(properties, heroImage.style),
            rawData.data.classNames,
            subComponents,
            getConvertedText(rawData.data.specific.sectionTitleCompiled),
            getConvertedText(rawData.data.specific.sectionParagraphCompiled),
            rawData.data.specific.sectionTitle,
            rawData.data.specific.sectionParagraph,
            rawData.data.specific.images
        );
    }
}
