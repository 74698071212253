import Form from "@/entities/PrizificPageBuilder/components/Form";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import {
    loginFormBackground,
    loginFormItemLabelColor,
    loginFormItemLabelFontSize,
    loginFormItemBorderColor,
    loginFormItemBorderWidth,
    loginFormItemBorderRadius,
    loginFormSubmitButtonPadding,
    loginFormSubmitButtonFontSize,
    loginFormSubmitButtonBackground,
    loginFormSubmitButtonTextColor,
    loginFormSubmitButtonBorderRadius,
    loginFormSubmitButtonBackgroundHover,
    loginFormSubmitButtonTextColorHover,
    loginFormCheckboxBorderRadius,
    loginFormCheckboxColor,
    loginFormCheckboxBackground,
} from "./LoginFormProperties";
import { emailFormItem } from "./EmailFormItem";
import { passwordFormItem } from "./PasswordFormItem";

export const loginForm = new Form(
    "-", 
    "Login form",
    [
        loginFormBackground,
        loginFormItemLabelColor,
        loginFormItemLabelFontSize,
        loginFormItemBorderColor,
        loginFormItemBorderWidth,
        loginFormItemBorderRadius,
        loginFormCheckboxBackground,
        loginFormCheckboxColor,
        loginFormCheckboxBorderRadius,
        loginFormSubmitButtonPadding,
        loginFormSubmitButtonFontSize,
        loginFormSubmitButtonBorderRadius,
        loginFormSubmitButtonBackground,
        loginFormSubmitButtonTextColor,
        loginFormSubmitButtonBackgroundHover,
        loginFormSubmitButtonTextColorHover,
    ],
    "",
    [emailFormItem, passwordFormItem]
);
