import Button from "~~/entities/PrizificPageBuilder/components/Button";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import {
    noRecipeButtonColor,
    noRecipeButtonBackground,
    noRecipeButtonFontSize,
    noRecipeButtonBorderRadius,
    noRecipeButtonPadding,
} from "./NoRecipeButtonProperties";

export const noRecipeButton = new Button(
    "-",
    "No recipe button",
    [
        noRecipeButtonColor,
        noRecipeButtonBackground,
        noRecipeButtonFontSize,
        noRecipeButtonBorderRadius,
        noRecipeButtonPadding,
    ],
    "",
    [],
    "Sajnos nem találom a nyugtát :(",
    "Sajnos nem találom a nyugtát :("
);
