import Property from "@/entities/PrizificPageBuilder/Property";
import { EInputType } from "@/enums/PrizificPageBuilder/EInputType";
import { EProperty } from "@/enums/PrizificPageBuilder/EProperty";
import { ERoles } from "@/enums/PrizificPageBuilder/ERoles";
import { EState } from "@/enums/PrizificPageBuilder/EState";
import { EUnit } from "@/enums/PrizificPageBuilder/EUnit";

export const contactFontFamily = new Property(
    "Font family",
    "",
    EState.DEFAULT,
    EProperty.FONT_FAMILY,
    EInputType.FONT_SELECT,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "contactBackground"
);

export const contactBackground = new Property(
    "Background color",
    "#fff",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "contactBackground"
);

export const contactTitleColor = new Property(
    "Title text color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "contactTitle"
);

export const contactTitleFontSize = new Property(
    "Title font size",
    24,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "contactTitle",
    EUnit.PX,
    16,
    48
);

export const contactParagraphColor = new Property(
    "Paragraph text color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "contactParagraph"
);

export const contactParagraphFontSize = new Property(
    "Paragraph font size",
    18,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "contactParagraph",
    EUnit.PX,
    11,
    32
);

export const contactCheckboxBackground = new Property(
    "Checkbox background",
    "#fff",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "contactCheckbox"
);

export const contactCheckboxColor = new Property(
    "Checkbox check color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "contactCheckbox"
);

export const contactCheckboxBorderColor = new Property(
    "Checkbox border color",
    "#444444",
    EState.DEFAULT,
    EProperty.BORDER_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "contactCheckbox"
);

export const contactCheckboxBorderRadius = new Property(
    "Checkbox border radius",
    8,
    EState.DEFAULT,
    EProperty.BORDER_RADIUS,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "contactCheckbox",
    EUnit.PX,
    0,
    48
);

export const contactSubmitButtonPadding = new Property(
    "Submit button padding",
    "6px 8px",
    EState.DEFAULT,
    EProperty.PADDING,
    EInputType.TEXT,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "contactSubmitButton"
);

export const contactSubmitButtonFontSize = new Property(
    "Submit button font size",
    14,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "contactSubmitButton",
    EUnit.PX,
    14,
    24
);

export const contactSubmitButtonBackground = new Property(
    "Submit button background color",
    "#e44f60",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "contactSubmitButton"
);

export const contactSubmitButtonTextColor = new Property(
    "Submit button text color",
    "#fdfcf9",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "contactSubmitButton"
);

export const contactSubmitButtonBorderColor = new Property(
    "Submit button border color",
    "#fdfcf9",
    EState.DEFAULT,
    EProperty.BORDER_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "contactSubmitButton"
);

export const contactSubmitButtonBorderWidth = new Property(
    "Submit button border width",
    1,
    EState.DEFAULT,
    EProperty.BORDER_WIDTH,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "contactSubmitButton",
    EUnit.PX,
    0,
    8
);

export const contactSubmitButtonBorderRadius = new Property(
    "Submit button border radius",
    8,
    EState.DEFAULT,
    EProperty.BORDER_RADIUS,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "contactSubmitButton",
    EUnit.PX,
    0,
    48
);

export const contactSubmitButtonBackgroundHover = new Property(
    "Submit button hover background color",
    "#e44f60",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "contactSubmitButtonHover"
);

export const contactSubmitButtonTextColorHover = new Property(
    "Submit button hover text color",
    "#fdfcf9",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "contactSubmitButtonHover"
);

export const contactSubmitButtonBorderColorHover = new Property(
    "Submit button hover border color",
    "#fdfcf9",
    EState.DEFAULT,
    EProperty.BORDER_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "contactSubmitButtonHover"
);

export const contactSubmitButtonBorderWidthHover = new Property(
    "Submit button hover border width",
    1,
    EState.DEFAULT,
    EProperty.BORDER_WIDTH,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "contactSubmitButtonHover",
    EUnit.PX,
    0,
    8
);
