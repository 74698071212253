import NavItem from "@/entities/PrizificPageBuilder/components/NavItem";

import {
    prizesNavItemFontSize,
    prizesNavItemPadding,
    prizesNavItemBorderRadius,
    prizesNavItemTextColor,
    prizesNavItemTextColorHover,
    prizesNavItemBackgroundColor,
    prizesNavItemBackgroundColorHover,
} from "./PrizesNavItemProperties";

export const prizesNavItem = new NavItem(
    "-",
    null,
    false,
    "Nyeremények",
    [
        prizesNavItemFontSize,
        prizesNavItemPadding,
        prizesNavItemBorderRadius,
        prizesNavItemTextColor,
        prizesNavItemTextColorHover,
        prizesNavItemBackgroundColor,
        prizesNavItemBackgroundColorHover,
    ],
    "",
    [],
    "Nyeremények",
    "Nyeremények",
    "/#nyeremenyek",
    false
);
