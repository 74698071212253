import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { AComponentFactory } from "../ComponentFactory";
import ListItem from "@/entities/PrizificPageBuilder/components/ListItem";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import {
    stepFontSize,
    stepColor,
    stepIncrementWidth,
} from "~~/entities/pages/home/sections/GameSteps/GameStepsProperties";

export class ListItemFactory extends AComponentFactory<ListItem> {
    getTypeName() {
        return EComponents.LIST_ITEM;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, [
            // "data.specific.textCompiled",
            // "data.specific.text",
            // "data.specific.src"
        ]);

        return new ListItem(
            rawData.id,
            rawData.data.title,
            properties,
            rawData.data.classNames,
            subComponents,
            getConvertedText(rawData.data.specific?.textCompiled) ?? "-",
            rawData.data.specific?.text ?? "-",
            rawData.data.specific?.src ?? ""
        );
    }

    createPlain() {
        return new ListItem(
            "-",
            "Game step",
            [stepFontSize, stepColor, stepIncrementWidth],
            "",
            [],
            "-",
            "-",
            ""
        );
    }
}
