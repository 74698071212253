import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { RuntimeComponentParseError } from "~/error/RuntimeError";

export interface ComponentFactory<T> {
    getTypeName(): string;
    /**
     * Create the component by the given type, and return with it
     *
     * @param rawData
     * @param subComponents
     * @param properties
     * @throws RuntimeComponentParseError
     */
    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ): T;

    /**
     * Create a default component with default data
     */
    createPlain(): T;
}

/**
 * Component factory help to create the given component
 */
export abstract class AComponentFactory<T> implements ComponentFactory<T> {
    protected validationArray: Array<string> = [
        "id",
        "data.title",
        "data.classNames",
    ];

    abstract getTypeName(): string;

    abstract create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ): T;

    createPlain(): T {
        throw new Error("Required but not implemented yet on: " + this.getTypeName());
    }

    /**
     * Check the required properties are available, if not throw a error
     *
     * @param rawData
     */
    protected validate(rawData: any, extraValidation?: Array<string>): void {
        let validationArray = this.validationArray;

        if (extraValidation) {
            validationArray = this.validationArray.concat(extraValidation);
        }

        validationArray.forEach((key) => {
            let temp = rawData;
            key.split(".").forEach((keyPart) => {
                if (temp[keyPart] === undefined) {
                    throw new RuntimeComponentParseError(
                        'The given data not exist for given type: "' +
                        this.getTypeName() +
                        '" and given key: "' +
                        key +
                        '"'
                    );
                }
                temp = temp[keyPart];
            });
        });
    }
}
