import FormItem from "@/entities/PrizificPageBuilder/components/FormItem";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { ERegistrationFormItem } from "~~/enums/PrizificPageBuilder/ERegistrationFormItem";

export const zipFormItem = new FormItem(
    "-",
    "Zip form item",
    [],
    "",
    [],
    "ZIP",
    "",
    "ZIP",
    "",
    ERegistrationFormItem.ZIP
);
