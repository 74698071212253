import ListItem from "@/entities/PrizificPageBuilder/components/ListItem";
import {
    stepIncrementWidth,
    stepFontSize,
    stepIncrementHeight,
    stepColor,
    stepIncrementBackground,
    stepIncrementColor,
    stepIncrementBorderRadius,
} from "../../GameStepsProperties";

export const secondGameStep = new ListItem(
    "-",
    "Game step",
    [stepFontSize, stepColor, stepIncrementWidth],
    "",
    [],
    "Töltsd fel a vásárlást igazoló blokk AP kódját, időpontját és add meg az email címed.",
    "Töltsd fel a vásárlást igazoló blokk AP kódját, időpontját és add meg az email címed.",
    ""
);
