import FormItem from "@/entities/PrizificPageBuilder/components/FormItem";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { ERegistrationFormItem } from "~~/enums/PrizificPageBuilder/ERegistrationFormItem";

export const phoneNumberFormItem = new FormItem(
    "-",
    "Phone number form item",

    [],
    "",
    [],
    "Phone number",
    "pl.: 20 123 4567",
    "Phone number",
    "pl.: 20 123 4567",
    ERegistrationFormItem.PHONE_NUMBER
);
