import FormItem from "@/entities/PrizificPageBuilder/components/FormItem";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { ECodeUploadFormItem } from "~/enums/PrizificPageBuilder/ECodeUploadFormItem";

export const purchaseTimeFormItem = new FormItem(
    "-",
    "Purchase time form item",
    [],
    "",
    [],
    "Vásárlás ideje",
    "óra : perc",
    "Vásárlás ideje",
    "óra : perc",
    ECodeUploadFormItem.PURCHASE_TIME
);
