import Group from "~/entities/PrizificPageBuilder/components/Group";
import { apCodeConflictModal } from "./ApCodeConflictModal";
import { apCodeExhaustedModal } from "./ApCodeExhaustedModal";
import { successfulCodeUploadModal } from "./SuccessFulCodeUploadModal";
import { apCodeShortTimeGateModal } from "./ApCodeShortTimeGateModal";
import { apCodeConflictItselfModal } from "./ApCodeConflictItselfModal";
import { couponCodeNotExistModal } from "./CouponCodeNotExistModal";
import { couponCodeConflictModal } from "./CouponCodeConflictModal";
import { couponCodeConflictItselfModal } from "./CouponCodeConflictItselfModal";
import { apCodeNotWinModal } from "./ApCodeNotWinModal";
import {
    modalFontFamily,
    modalTitleTextColor,
    modalDescriptionTextColor,
    modalCloseIconColor,
    modalRightButtonBackground,
    modalRightButtonColor,
    modalRightButtonBorderColor,
    modalRightButtonFontSize,
    modalRightButtonPadding,
    modalRightButtonBorderRadius,
    modalRightButtonBorderWidth,
} from "./CodeUploadFeedbackModalsProperties";

export const codeUploadFeedbackModals = new Group(
    "-",
    "Feedback modals",
    [
        modalFontFamily,
        modalTitleTextColor,
        modalDescriptionTextColor,
        modalCloseIconColor,
        modalRightButtonBackground,
        modalRightButtonColor,
        modalRightButtonBorderColor,
        modalRightButtonBorderWidth,
        modalRightButtonFontSize,
        modalRightButtonPadding,
        modalRightButtonBorderRadius,
    ],
    "",
    [
        successfulCodeUploadModal,
        apCodeNotWinModal,
        apCodeConflictModal,
        apCodeExhaustedModal,
        apCodeShortTimeGateModal,
        apCodeConflictItselfModal,
        couponCodeNotExistModal,
        couponCodeConflictModal,
        couponCodeConflictItselfModal,
    ]
);
