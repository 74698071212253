<template>
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>
</template>

<script setup lang="ts">
import { ID_INJECTION_KEY } from "element-plus";
import { useCampaignDataStore } from "@/store/campaign/campaignData";
import { useTrackingStore } from "@/store/tracking/tracking";
import { useCookieSettingsDialogStore } from "./store/components/cookieSettingsDialog/cookieSettingsDialog";
import { useAuthStore } from "./store/user/auth";
import { storeToRefs } from "pinia";

provide(ID_INJECTION_KEY, {
    prefix: 100,
    current: 0,
});
const authStore = useAuthStore();
const campaignDataStore = useCampaignDataStore();

const focused = useWindowFocus();
const { isRegistrationRequired, isHiveConnection } =
    storeToRefs(campaignDataStore);

if (
    isRegistrationRequired.value &&
    !isHiveConnection.value &&
    authStore.authCookie().value !== undefined &&
    authStore.user == undefined
) {
    authStore.getMe();
}

watch(
    () => focused.value,
    (newValue) => {
        if (newValue && authStore.authCookie().value == undefined) {
            authStore.user = undefined;
        } else if (
            newValue &&
            isRegistrationRequired.value &&
            !isHiveConnection.value &&
            authStore.authCookie().value !== undefined &&
            authStore.user == undefined
        ) {
            authStore.getMe();
        }
    }
);

useHead({
    link: [
        {
            hid: "icon",
            rel: "icon",
            type: "image/x-icon",
            href: campaignDataStore.campaignData.favIcon
                ? useMediaUrl(campaignDataStore.campaignData.favIcon)
                : useCdnUrl("/images/default.png"),
        },
    ],
});

onMounted(() => {
    if (campaignDataStore.campaignData) {
        useSeoMeta({
            title: campaignDataStore.campaignData.metaTitle ?? "",
            ogTitle: campaignDataStore.campaignData.ogTitle ?? "",
            description: campaignDataStore.campaignData.metaDescription ?? "",
            ogDescription: campaignDataStore.campaignData.ogDescription ?? "",
            ogImage:
                useMediaUrl(campaignDataStore.campaignData.ogImageFile) ?? "",
        });
    }
});

if (campaignDataStore.campaignData) {
    useServerSeoMeta({
        title: campaignDataStore.campaignData.metaTitle ?? "",
        ogTitle: campaignDataStore.campaignData.ogTitle ?? "",
        description: campaignDataStore.campaignData.metaDescription ?? "",
        ogDescription: campaignDataStore.campaignData.ogDescription ?? "",
        ogImage: useMediaUrl(campaignDataStore.campaignData.ogImageFile) ?? "",
    });

    useHead({
        link: [
            {
                hid: "icon",
                rel: "icon",
                type: "image/x-icon",
                href: campaignDataStore.campaignData.favIcon
                    ? useMediaUrl(campaignDataStore.campaignData.favIcon)
                    : useCdnUrl("/images/default.png"),
            },
        ],
    });
} else {
    console.error("No campaign Data Available!");
}

try {
    const cookieSettingsDialogStore = useCookieSettingsDialogStore();
    const trackingStore = useTrackingStore();

    trackingStore.init(
        cookieSettingsDialogStore.getCookieConsent.statistics ?? false
    );
} catch (e) {
    console.error("Error, consent", e);
}

if (campaignDataStore.campaignData.fonts) {
    let styleString = "";

    campaignDataStore.campaignData.fonts.forEach((font: any) => {
        styleString += `
        @font-face {
            font-family: "dynamic-font-${font.id}";
            src: url("${useMediaUrl(font.font)}");
        }        
        `;
    });

    useHead({
        style: [
            {
                key: "dynamic-fonts",
                id: "dynamic-font",
                innerHTML: styleString,
                // valid options are: 'head' | 'bodyClose' | 'bodyOpen'
                tagPosition: "head",
            },
        ],
    });

    const cocogooseFont = ref(
        useCdnUrl("/fonts/Cocogoose/Cocogoose-Light.woff2")
    );
    useHead({
        style: [
            {
                key: "cocogoose-font",
                id: "cocogoose-font",
                tagPosition: "head",
                innerHTML: `
                    @font-face {
                        font-family: "Cocogoose";
                        src: url("${cocogooseFont.value}");
                    }`,
            },
        ],
    });

    const montserratFont = ref(
        useCdnUrl("/fonts/Montserrat/Montserrat-Regular.woff2")
    );
    useHead({
        style: [
            {
                key: "montserrat-font",
                id: "montserrat-font",
                tagPosition: "head",
                innerHTML: `
                    @font-face {
                        font-family: "Montserrat";
                        src: url("${montserratFont.value}");
                    }
                `,
            },
        ],
    });

    const robotoThinFont = ref(useCdnUrl("/fonts/Roboto/Roboto-Thin.woff2"));
    const robotoThinItalicFont = ref(
        useCdnUrl("/fonts/Roboto/Roboto-ThingItalic.woff2")
    );
    const robotoLightFont = ref(useCdnUrl("/fonts/Roboto/Roboto-Light.woff2"));
    const robotoLightItalicFont = ref(
        useCdnUrl("/fonts/Roboto/Roboto-LightItalic.woff2")
    );
    const robotoRegularFont = ref(
        useCdnUrl("/fonts/Roboto/Roboto-Regular.woff2")
    );
    const robotoItalicFont = ref(
        useCdnUrl("/fonts/Roboto/Roboto-Italic.woff2")
    );
    const robotoMediumFont = ref(
        useCdnUrl("/fonts/Roboto/Roboto-Medium.woff2")
    );
    const robotoMediumItalicFont = ref(
        useCdnUrl("/fonts/Roboto/Roboto-MediumItalic.woff2")
    );
    const robotoBoldFont = ref(useCdnUrl("/fonts/Roboto/Roboto-Bold.woff2"));

    const robotoBoldItalicFont = ref(
        useCdnUrl("/fonts/Roboto/Roboto-BoldItalic.woff2")
    );
    const robotoBlackFont = ref(
        useMediaUrl("/fonts/Roboto/Roboto-Black.woff2")
    );
    const robotoBlackItalicFont = ref(
        useCdnUrl("/fonts/Roboto/Roboto-BlackItalic.woff2")
    );

    useHead({
        style: [
            {
                key: "roboto-font",
                id: "roboto-font",
                tagPosition: "head",
                innerHTML: `
                    @font-face {
                        font-family: "Roboto";
                        src: url("${robotoThinFont.value}") format("woff2");
                        font-weight: 100;
                        font-style: normal;
                        font-display: swap;
                    }
                    @font-face {
                        font-family: "Roboto";
                        src: url("${robotoThinItalicFont.value}") format("woff2");
                        font-weight: 100;
                        font-style: italic;
                        font-display: swap;
                    }
                    @font-face {
                        font-family: "Roboto";
                        src: url("${robotoLightFont.value}") format("woff2");
                        font-weight: 300;
                        font-style: normal;
                        font-display: swap;
                    }
                    @font-face {
                        font-family: "Roboto";
                        src: url("${robotoLightItalicFont.value}") format("woff2");
                        font-weight: 300;
                        font-style: italic;
                        font-display: swap;
                    }
                    @font-face {
                        font-family: "Roboto";
                        src: url("${robotoRegularFont.value}") format("woff2");
                        font-weight: 400;
                        font-style: normal;
                        font-display: swap;
                    }
                    @font-face {
                        font-family: "Roboto";
                        src: url("${robotoItalicFont.value}") format("woff2");
                        font-weight: 400;
                        font-style: italic;
                        font-display: swap;
                    }
                    @font-face {
                        font-family: "Roboto";
                        src: url("${robotoMediumFont.value}") format("woff2");
                        font-weight: 500;
                        font-style: normal;
                        font-display: swap;
                    }
                    @font-face {
                        font-family: "Roboto";
                        src: url("${robotoMediumItalicFont.value}") format("woff2");
                        font-weight: 500;
                        font-style: italic;
                        font-display: swap;
                    }
                    @font-face {
                        font-family: "Roboto";
                        src: url("${robotoBoldFont.value}") format("woff2");
                        font-weight: 700;
                        font-style: normal;
                        font-display: swap;
                    }
                    @font-face {
                        font-family: "Roboto";
                        src: url("${robotoBoldItalicFont.value}") format("woff2");
                        font-weight: 700;
                        font-style: italic;
                        font-display: swap;
                    }
                    @font-face {
                        font-family: "Roboto";
                        src: url("${robotoBlackFont.value}") format("woff2");
                        font-weight: 900;
                        font-style: normal;
                        font-display: swap;
                    }
                    @font-face {
                        font-family: "Roboto";
                        src: url("${robotoBlackItalicFont.value}") format("woff2");
                        font-weight: 900;
                        font-style: italic;
                        font-display: swap;
                    }
                `,
            },
        ],
    });

    const helveticaNeueBoldFont = ref(
        useCdnUrl("/fonts/HelveticaNeue/HelveticaNeueBold.woff2")
    );

    useHead({
        style: [
            {
                key: "helveticaneue-font",
                id: "helveticaneue-font",
                tagPosition: "head",
                innerHTML: `
                    @font-face {
                        font-family: "HelveticaNeue";
                        src: url("${helveticaNeueBoldFont.value}") format("woff2");
                        font-weight: 700;
                        font-style: normal;
                        font-display: swap;
                    }
                `,
            },
        ],
    });

    const poppinsFont = ref(useCdnUrl("/fonts/Poppins/poppins-regular.woff2"));

    useHead({
        style: [
            {
                key: "poppins-font",
                id: "poppins-font",
                tagPosition: "head",
                innerHTML: `
                    @font-face {
                        font-family: "Poppins";
                        src: url("${poppinsFont.value}") format("woff2");
                        font-weight: 400;
                        font-style: normal;
                        font-display: swap;
                    }
                `,
            },
        ],
    });

    if (
        campaignDataStore.campaignUUId ==
            "a46bdb28-0c9b-463b-a48e-b2e78acd71b8" ||
        campaignDataStore.campaignUUId == "cc601dc3-716a-4cb3-a994-14d3ec111761"
    ) {
        useHead({
            script: [
                {
                    innerHTML: `
                        console.log("Cookie Start!");
                        var consentListeners = [];
window.addConsentListenerTA = function (callback) {
    consentListeners.push(callback);
};
var onConsentChange = function (consent, behaviour) {
    consentListeners.forEach(function (callback) {
        callback(consent, behaviour);
    });
};
var __dispatched__ = {}; //Map of previously dispatched preference levels
/*
US +1 888.878.7830 | EU +44 (0)203.078.6495 | FR +33 420.102.065 | DE +49 221.569.4412 | www.trustarc.com | ©2021 TrustArc Inc. 18
First step is to register with the CM API to receive callbacks when a preference update
occurs. You must wait for the CM API (PrivacyManagerAPI object) to exist on the page
before
registering.
*/
var __i__ = self.postMessage && setInterval(function () {
    if (self.PrivacyManagerAPI && __i__) {
        var apiObject = {
            PrivacyManagerAPI: {
                action: "getConsentDecision",
                timestamp: new Date().getTime(),
                self: self.location.host
            }
        };
        self.top.postMessage(JSON.stringify(apiObject), "*");
        __i__ = clearInterval(__i__);
    }
}, 50);
/*
Callbacks will occur in the form of a PostMessage event. This code listens for the
appropriately formatted PostMessage event, gets the new consent decision, and then
pushes
the events into the GTM framework. Once the event is submitted, that consent decision
is
marked in the 'dispatched' map so it does not occur more than once.
*/
self.addEventListener("message", function (e, d) {
    try {
        if (e.data && (d = JSON.parse(e.data)) &&
            (d = d.PrivacyManagerAPI) && d.capabilities &&
            d.action == "getConsentDecision") {
            console.log("On consent changed!");
            // Callback function to trigger GCM Template
            var notice_behavior = window.truste.util.readCookie("notice_behavior") ||
                (truste.eu.bindMap.behavior + ',' + truste.eu.bindMap.behaviorManager);
            var cmapi_cookie_privacy =
                window.truste.util.readCookie("cmapi_cookie_privacy") || '';
            onConsentChange(cmapi_cookie_privacy, notice_behavior);
            self.dataLayer && self.dataLayer.push({ "event": "Consent Changed" });
            //US + 1 888.878.7830 | EU + 44(0)203.078.6495 | FR + 33 420.102.065 | DE + 49 221.569.4412 | www.trustarc.com | ©2021 TrustArc Inc. 19
            console.log("After On consent changed!");
        }
    } catch (xx) {
    }
});

`,
                },
                {
                    innerHTML: `
                        window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', 'G-602BR7CC2L');
// Default consent types to 'denied' as a placeholder
// Determine actual values based on your own requirements
// Please omit consent types not being used
gtag('consent', 'default', {
'ad_storage': 'denied',
'ad_user_data': 'denied',
'ad_personalization': 'denied',
'analytics_storage': 'denied',
'functionality_storage': 'denied',
'personalization_storage': 'denied',
'security_storage': 'denied',
'wait_for_update': 500
});
gtag('set', 'ads_data_redaction', true);
gtag('set', 'developer_id.dNTIxZG', true);

                    `,
                },
                {
                    "data-cbid": "175120311",
                    id: "Cookiebot",
                    src: "https://consent.trustarc.com/notice?domain=molsoncoors-eu.com&c=teconsent&text=true&country=gb&gtm=1&js=nj&noticeType=bb",
                    async: "true",
                },
                {
                    src: "https://www.googletagmanager.com/gtag/js?id=G-602BR7CC2L",
                    async: "true",
                },
            ],
        });
    }
}
</script>

<!--


-->

<style lang="scss"></style>
