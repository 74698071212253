import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { ISection } from "@/interfaces/PrizificPageBuilder/ISection";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { IImg } from "@/interfaces/PrizificPageBuilder/IImg";
import AComponent from "../AComponent";

export default class HeroImage
    extends AComponent<EComponents.HERO_IMAGE>
    implements IComponent<EComponents.HERO_IMAGE>, ISection
{
    constructor(
        id: string,
        title: string,
        style: Array<IProperty>,
        classNames: string,
        components: Array<IComponent<EComponents>>,
        public sectionTitle: string,
        public sectionParagraph: string,
        public sectionTitleRaw: string,
        public sectionParagraphRaw: string,
        public images: Array<IImg>
    ) {
        super(id, title, EComponents.HERO_IMAGE, style, classNames, components);
    }

    toPayload(): { [key: string]: any } {
        return super.toPayload({
            sectionTitle: this.sectionTitleRaw,
            sectionParagraph: this.sectionParagraphRaw,
            images: this.images,
        });
    }
}
