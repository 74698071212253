export enum EModal {
    SUCCESSFULCODEUPLOAD = "SuccessfulCodeUpload",
    APCODENOTWIN = "ApCodeNotWin",
    APCODECONFLICT = "ApCodeConflict",
    APCODEEXHAUSTED = "APCodeExhausted",
    APCODESHORTTIMEGATE = "APCodeShortTimeGate",
    APCODECONFLICTITSELF = "APCodeConflictItself",
    COUPONCODECONFLICTITSELF = "CouponCodeConflictItself",
    COUPONCODENOTEXIST = "CouponCodeNotExist",
    COUPONCODECONFLICT = "CouponCodeConflict",
    NOTACTIVATEDUSER = "NotActivatedUser",
    EMAILORPASSWORDINCORRECT = "EmailOrPasswordIncorrect",
    FORGOTTENPASSWORD = "ForgottenPassword",
    ALMOSTDONE = "AlmostDone",
    PLEASEACTIVATEYOURACCOUNT = "PleaseActivateYourAccount",
    ACTIVATIONTIMEEXPIRED = "ActivationTimeExpired",
    NO_RECIPE_MODAL = "no-recipe-modal",
    ACCEPT_TERMS = "accept-terms",
}
