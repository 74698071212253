import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { IListItem } from "@/interfaces/PrizificPageBuilder/IListItem";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import AComponent from "../AComponent";

export default class ListItem
    extends AComponent<EComponents.LIST_ITEM>
    implements IComponent<EComponents.LIST_ITEM>, IListItem
{
    constructor(
        id: string,
        title: string,
        style: Array<IProperty>,
        classNames: string,
        components: Array<IComponent<EComponents>>,
        public text: string,
        public textRaw: string,
        public src: string
    ) {
        super(id, title, EComponents.LIST_ITEM, style, classNames, components);
    }

    toPayload(): { [key: string]: any } {
        return super.toPayload({
            text: this.textRaw,
            src: this.src,
        });
    }
}
