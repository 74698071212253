import FormItem from "@/entities/PrizificPageBuilder/components/FormItem";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { EShippingAddressFormItem } from "~~/enums/PrizificPageBuilder/EShippingAddressFormItem";

export const shippingNameFormItem = new FormItem(
    "-",
    "Name form item",
    [],
    "",
    [],
    "Szállítási név",
    "",
    "Szállítási név",
    "",
    EShippingAddressFormItem.NAME
);
