import NavItem from "@/entities/PrizificPageBuilder/components/NavItem";

import {
    homeNavItemFontSize,
    homeNavItemPadding,
    homeNavItemBorderRadius,
    homeNavItemTextColor,
    homeNavItemTextColorHover,
    homeNavItemBackgroundColor,
    homeNavItemBackgroundColorHover,
} from "./HomeNavItemProperties";

export const homeNavItem = new NavItem(
    "-",
    null,
    false,
    "Főoldal",
    [
        homeNavItemFontSize,
        homeNavItemPadding,
        homeNavItemBorderRadius,
        homeNavItemTextColor,
        homeNavItemTextColorHover,
        homeNavItemBackgroundColor,
        homeNavItemBackgroundColorHover,
    ],
    "",
    [],
    "Főoldal",
    "Főoldal",
    "/",
    false
);
