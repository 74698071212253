import NavItem from "@/entities/PrizificPageBuilder/components/NavItem";
//import { logoYoutube } from "ionicons/icons";

import {
    socialYoutubeWidth,
    socialYoutubeFillColor,
    socialYoutubeFillColorHover,
} from "./SocialYoutubeProperties";

export const socialYoutube = new NavItem(
    "-",
    null,
    false,
    "Youtube",
    [socialYoutubeWidth, socialYoutubeFillColor, socialYoutubeFillColorHover],
    "",
    [],
    "",
    "",
    "",
    true,
    "youtube"
);
