import Img from "@/entities/PrizificPageBuilder/components/Img";
import { brandLogoWidth } from "./BrandLogoProperties";

export const brandLogo = new Img(
    "-",
    null,
    false,
    "Brand Logo",
    [brandLogoWidth],
    "",
    [],
    "/images/prizific-test-page-logo.png" //TODO: ADD CDN URL for this, by config
);
