import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { INavItemWithThreeState } from "@/interfaces/PrizificPageBuilder/INavItemWithThreeState";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import AComponent from "../AComponent";
import ASharedComponent from "../ASharedComponent";

export default class NavItemWithThreeState
    extends ASharedComponent<EComponents.NAV_ITEM_WITH_THREE_STATE>
    implements
        IComponent<EComponents.NAV_ITEM_WITH_THREE_STATE>,
        INavItemWithThreeState
{
    constructor(
        id: string,
        sharedComponentId: string | number | null,
        isSharedComponent: boolean,
        title: string,
        style: Array<IProperty>,
        classNames: string,
        components: Array<IComponent<EComponents>>,
        public textStateOne: string,
        public textStateOneRaw: string,
        public textStateTwo: string,
        public textStateTwoRaw: string,
        public textStateThree: string,
        public textStateThreeRaw: string,
        public toStateOne: string,
        public toStateTwo: string,
        public toStateThree: string,
        public link: boolean,
        public icon?: string
    ) {
        super(
            id,
            sharedComponentId,
            isSharedComponent,
            title,
            EComponents.NAV_ITEM_WITH_THREE_STATE,
            style,
            classNames,
            components
        );
    }

    toPayload(): { [key: string]: any } {
        return super.toPayload({
            textStateOne: this.textStateOneRaw,
            textStateTwo: this.textStateTwoRaw,
            textStateThree: this.textStateThreeRaw,
            toStateOne: this.toStateOne,
            toStateTwo: this.toStateTwo,
            toStateThree: this.toStateThree,
            link: this.link,
            icon: this.icon,
        });
    }
}
