import PromoProducts from "@/entities/PrizificPageBuilder/sections/PromoProducts";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import {
    promoProductsFontFamily,
    promoProductsBackground,
    promoProductsTitleColor,
    promoProductsTitleFontSize,
    promoProductsParagraphColor,
    promoProductsParagraphFontSize,
    promoProductsImageWidth,
    promoProductsNameColor,
    promoProductsSwiperPagination,
    promoProductsSwiperPaginationActive,
    promoProductsSwiperArrow,
    promoProductsSwiperArrowSize,
    promoProductsAfterColor,
    promoProductsAfterFontSize,
    afterPromoProductsText,
} from "./PromoProductsProperties";

export const promoProducts = new PromoProducts(
    "-",
    "Promotion products",
    [
        promoProductsFontFamily,
        promoProductsBackground,
        promoProductsTitleColor,
        promoProductsTitleFontSize,
        promoProductsParagraphColor,
        promoProductsParagraphFontSize,
        promoProductsImageWidth,
        promoProductsNameColor,
        promoProductsSwiperPagination,
        promoProductsSwiperPaginationActive,
        promoProductsSwiperArrow,
        promoProductsSwiperArrowSize,
        promoProductsAfterColor,
        promoProductsAfterFontSize,
    ],
    "",
    [afterPromoProductsText],
    "A játékban résztvevő termékek",
    "Promo Product Description",
    "A játékban résztvevő termékek",
    "Promo Product Description",
    ""
);
