import Property from "@/entities/PrizificPageBuilder/Property";
import { EInputType } from "@/enums/PrizificPageBuilder/EInputType";
import { EProperty } from "@/enums/PrizificPageBuilder/EProperty";
import { ERoles } from "@/enums/PrizificPageBuilder/ERoles";
import { EState } from "@/enums/PrizificPageBuilder/EState";
import { EUnit } from "@/enums/PrizificPageBuilder/EUnit";

export const prizeFontFamily = new Property(
    "Font family",
    "",
    EState.DEFAULT,
    EProperty.FONT_FAMILY,
    EInputType.FONT_SELECT,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "prizeSection"
);

export const prizesBackground = new Property(
    "Background color",
    "#878787",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "prizeSection"
);

export const prizeImageWidth = new Property(
    "Image width",
    256,
    EState.DEFAULT,
    EProperty.WIDTH,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "prizeImage",
    EUnit.PX,
    64,
    256
);

export const prizesTypeTextColor = new Property(
    "Type text color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "prizeType"
);

export const prizesTextColor = new Property(
    "Text color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "prizeSection"
);

export const prizesMoreTextColor = new Property(
    "Prizes more text color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "prizeMore"
);

export const prizesMoreHoverTextColor = new Property(
    "Prizes more hover text color",
    "#444444",
    EState.HOVER,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "prizeMoreHover"
);

export const prizesTypeFontSize = new Property(
    "Type font size",
    12,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "prizeType",
    EUnit.PX,
    11,
    16
);

export const prizesPrizeNameFontSize = new Property(
    "Prize name font size",
    16,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "prizeName",
    EUnit.PX,
    11,
    24
);

export const prizesDescriptionFontSize = new Property(
    "Small description font size",
    13,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "prizeDescription",
    EUnit.PX,
    12,
    18
);

export const prizesMoreFontSize = new Property(
    "Prizes more font size",
    13,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "prizeMore",
    EUnit.PX,
    12,
    18
);
