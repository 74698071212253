import CookieSettings from "@/entities/PrizificPageBuilder/sections/CookieSettings";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";

import {
    cookieSettingsFontFamily,
    cookieSettingsBackground,
    cookieSettingsTitleColor,
    cookieSettingsTitleFontSize,
    cookieSettingsParagraphColor,
    cookieSettingsParagraphFontSize,
    modalLeftButtonBackground,
    modalLeftButtonBorderColor,
    modalLeftButtonFontSize,
    modalLeftButtonPadding,
    modalLeftButtonBorderRadius,
    modalRightButtonBackground,
    modalRightButtonColor,
    modalRightButtonBorderColor,
    modalRightButtonBorderWidth,
    modalRightButtonFontSize,
    modalRightButtonPadding,
    modalRightButtonBorderRadius,
    modalLeftButtonColor,
} from "./CookieSettingsProperties";

export const cookieSettings = new CookieSettings(
    "",
    null,
    false,
    "Cookie settings",
    [
        cookieSettingsFontFamily,
        cookieSettingsBackground,
        cookieSettingsTitleColor,
        cookieSettingsTitleFontSize,
        cookieSettingsParagraphColor,
        cookieSettingsParagraphFontSize,
        modalLeftButtonBackground,
        modalLeftButtonColor,
        modalLeftButtonBorderColor,
        modalLeftButtonFontSize,
        modalLeftButtonPadding,
        modalLeftButtonBorderRadius,
        modalRightButtonBackground,
        modalRightButtonColor,
        modalRightButtonBorderColor,
        modalRightButtonBorderWidth,
        modalRightButtonFontSize,
        modalRightButtonPadding,
        modalRightButtonBorderRadius,
    ],
    "",
    [],
    "Cookie beállítások",
    "Ezen a weboldalon sütiket használunk a felhasználói élmény javítása, a hirdetések testreszabása valamint a navigáció megkönnyítése érdekében. Bővebb információ itt: Cookie szabályzat",
    "Beállítások",
    "Összes elfogadása",
    "Kiválasztottak elfogadása",
    "Cookie beállítások",
    "Ezen a weboldalon sütiket használunk a felhasználói élmény javítása, a hirdetések testreszabása valamint a navigáció megkönnyítése érdekében. Bővebb információ itt: Cookie szabályzat",
    "Beállítások",
    "Összes elfogadása",
    "Kiválasztottak elfogadása"
);
