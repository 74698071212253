import Property from "~/entities/PrizificPageBuilder/Property";
import { EInputType } from "~/enums/PrizificPageBuilder/EInputType";
import { EProperty } from "~/enums/PrizificPageBuilder/EProperty";
import { ERoles } from "~/enums/PrizificPageBuilder/ERoles";
import { EState } from "~/enums/PrizificPageBuilder/EState";
import { EUnit } from "~/enums/PrizificPageBuilder/EUnit";

export const imageTextContentBackground = new Property(
    "Background color",
    "#3a89a2",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "imageTextContentBackground"
);

export const imageTextContentTitleColor = new Property(
    "Title text color",
    "#f7f3e8",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "imageTextContentTitle"
);

export const imageTextContentTitleFontSize = new Property(
    "Title font size",
    32,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "imageTextContentTitle",
    EUnit.PX,
    24,
    48
);

export const imageTextContentParagraphColor = new Property(
    "Paragraph text color",
    "#f7f3e8",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "imageTextContentParagraph"
);

export const imageTextContentParagraphFontSize = new Property(
    "Paragraph font size",
    16,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "imageTextContentParagraph",
    EUnit.PX,
    13,
    32
);
