import Modal from "@/entities/PrizificPageBuilder/components/Modal";
import {
    modalFontFamily,
    modalImageWidth,
    modalTitleTextColor,
    modalDescriptionTextColor,
    modalCloseIconColor,
    modalRightButtonBackground,
    modalRightButtonColor,
    modalRightButtonBorderColor,
    modalRightButtonFontSize,
    modalRightButtonPadding,
    modalRightButtonBorderRadius,
} from "./PrizesModalProperties";

export const prizesModal = new Modal(
    "-",
    "Prizes modal",
    [
        modalFontFamily,
        modalImageWidth,
        modalTitleTextColor,
        modalDescriptionTextColor,
        modalCloseIconColor,
        modalRightButtonBackground,
        modalRightButtonColor,
        modalRightButtonBorderColor,
        modalRightButtonFontSize,
        modalRightButtonPadding,
        modalRightButtonBorderRadius,
    ],
    "",
    [],
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    ""
);
