import Form from "@/entities/PrizificPageBuilder/components/Form";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import {
    editPasswordFormBackground,
    editPasswordFormItemLabelColor,
    editPasswordFormItemLabelFontSize,
    editPasswordFormItemBorderColor,
    editPasswordFormItemBorderWidth,
    editPasswordFormItemBorderRadius,
    editPasswordFormSubmitButtonPadding,
    editPasswordFormSubmitButtonFontSize,
    editPasswordFormSubmitButtonBackground,
    editPasswordFormSubmitButtonTextColor,
    editPasswordFormSubmitButtonBorderRadius,
    editPasswordFormSubmitButtonBackgroundHover,
    editPasswordFormSubmitButtonTextColorHover,
} from "./EditPasswordFormProperties";
import { passwordFormItem } from "./PasswordFormItem";

export const editPasswordForm = new Form(
    "-",
    "Edit password form",
    [
        editPasswordFormBackground,
        editPasswordFormItemLabelColor,
        editPasswordFormItemLabelFontSize,
        editPasswordFormItemBorderColor,
        editPasswordFormItemBorderWidth,
        editPasswordFormItemBorderRadius,
        editPasswordFormSubmitButtonPadding,
        editPasswordFormSubmitButtonFontSize,
        editPasswordFormSubmitButtonBorderRadius,
        editPasswordFormSubmitButtonBackground,
        editPasswordFormSubmitButtonTextColor,
        editPasswordFormSubmitButtonBackgroundHover,
        editPasswordFormSubmitButtonTextColorHover,
    ],
    "",
    [passwordFormItem]
);
