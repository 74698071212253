import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import NavItem from "@/entities/PrizificPageBuilder/components/NavItem";
import { AComponentFactory } from "../ComponentFactory";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import NavItemWithThreeState from "~~/entities/PrizificPageBuilder/components/NavItemWithThreeState";

export class NavItemWithThreeStateFactory extends AComponentFactory<NavItemWithThreeState> {
    getTypeName() {
        return EComponents.NAV_ITEM_WITH_THREE_STATE;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, [
            // "data.specific.textCompiled",
            // "data.specific.link",
            // "data.specific.icon",
        ]);

        return new NavItemWithThreeState(
            rawData.id,
            rawData.sharedComponentId ?? null,
            rawData.isSharedComponent ?? false,
            rawData.data.title,
            properties,
            rawData.data.classNames,
            subComponents,
            getConvertedText(rawData.data.specific.textStateOneCompiled) ?? "",
            rawData.data.specific.textStateOne,
            getConvertedText(rawData.data.specific.textStateTwoCompiled) ?? "",
            rawData.data.specific.textStateTwo,
            getConvertedText(rawData.data.specific.textStateThreeCompiled) ??
                "",
            rawData.data.specific.textStateThree,
            rawData.data.specific.toStateOne,
            rawData.data.specific.toStateTwo,
            rawData.data.specific.toStateThree,
            rawData.data.specific.link,
            rawData.data.specific.icon
        );
    }
}
