import ListItem from "@/entities/PrizificPageBuilder/components/ListItem";
import {
    stepIncrementWidth,
    stepFontSize,
    stepColor,
} from "../../GameStepsProperties";

export const fourthGameStep = new ListItem(
    "-",
    "Game step",
    [stepFontSize, stepColor, stepIncrementWidth],
    "",
    [],
    "-",
    "-",
    ""
);
