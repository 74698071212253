import { IComponent } from "~/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "~/interfaces/PrizificPageBuilder/IProperty";
import { IModal } from "~/interfaces/PrizificPageBuilder/IModal";
import { EComponents } from "~/enums/PrizificPageBuilder/EComponents";
import { EModal } from "~/enums/PrizificPageBuilder/EModal";
import AComponent from "../AComponent";

export default class Modal
    extends AComponent<EComponents.MODAL>
    implements IComponent<EComponents.MODAL>, IModal
{
    constructor(
        id: string,
        title: string,
        style: Array<IProperty>,
        classNames: string,
        components: Array<IComponent<EComponents>>,
        public modalTitle: string,
        public modalDescription: string,
        public modalLeftButtonText: string,
        public modalRightButtonText: string,
        public modalTitleRaw: string,
        public modalDescriptionRaw: string,
        public modalLeftButtonTextRaw: string,
        public modalRightButtonTextRaw: string,
        public belongsTo: EModal | string
    ) {
        super(id, title, EComponents.MODAL, style, classNames, components);
    }

    toPayload(): { [key: string]: any } {
        return super.toPayload({
            modalTitle: this.modalTitleRaw,
            modalDescription: this.modalDescriptionRaw,
            modalLeftButtonText: this.modalLeftButtonTextRaw,
            modalRightButtonText: this.modalRightButtonTextRaw,
            belongsTo: this.belongsTo,
        });
    }
}
