import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { IForm } from "~~/interfaces/PrizificPageBuilder/IForm";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import AComponent from "../AComponent";

export default class Form
    extends AComponent<EComponents.FORM>
    implements IComponent<EComponents.FORM>, IForm
{
    constructor(
        id: string,
        title: string,
        style: Array<IProperty>,
        classNames: string,
        components: Array<IComponent<EComponents>>
    ) {
        super(id, title, EComponents.FORM, style, classNames, components);
    }
}
