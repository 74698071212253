import Property from "~/entities/PrizificPageBuilder/Property";
import { EInputType } from "~/enums/PrizificPageBuilder/EInputType";
import { EProperty } from "~/enums/PrizificPageBuilder/EProperty";
import { ERoles } from "~/enums/PrizificPageBuilder/ERoles";
import { EState } from "~/enums/PrizificPageBuilder/EState";
import { EUnit } from "~/enums/PrizificPageBuilder/EUnit";

export const modalTitleTextColor = new Property(
    "Title text color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "prizeModalTitle"
);

export const modalDescriptionTextColor = new Property(
    "Description text color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "prizeModalDescription"
);

export const modalCloseIconColor = new Property(
    "Close icon color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "prizeModalClose"
);

export const modalLeftButtonBackground = new Property(
    "Left button background color",
    "#3A89A2",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalLeftButton"
);

export const modalLeftButtonColor = new Property(
    "Left button text color",
    "#fff",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalLeftButton"
);

export const modalLeftButtonBorderColor = new Property(
    "Left button border color",
    "#3A89A2",
    EState.DEFAULT,
    EProperty.BORDER_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalLeftButton"
);

export const modalLeftButtonFontSize = new Property(
    "Left button font size",
    16,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalLeftButton",
    EUnit.PX,
    13,
    24
);

export const modalLeftButtonPadding = new Property(
    "Left button padding",
    "20px 18px",
    EState.DEFAULT,
    EProperty.PADDING,
    EInputType.TEXT,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalLeftButton"
);

export const modalLeftButtonBorderRadius = new Property(
    "Left button border radius",
    10,
    EState.DEFAULT,
    EProperty.BORDER_RADIUS,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalLeftButton",
    EUnit.PX,
    0,
    24
);

export const modalRightButtonBackground = new Property(
    "Right button background color",
    "#3A89A2",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalRightButton"
);

export const modalRightButtonColor = new Property(
    "Right button text color",
    "#fff",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalRightButton"
);

export const modalRightButtonBorderColor = new Property(
    "Right button border color",
    "#3A89A2",
    EState.DEFAULT,
    EProperty.BORDER_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalRightButton"
);

export const modalRightButtonFontSize = new Property(
    "Right button font size",
    16,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalRightButton",
    EUnit.PX,
    13,
    24
);

export const modalRightButtonPadding = new Property(
    "Right button padding",
    "20px 18px",
    EState.DEFAULT,
    EProperty.PADDING,
    EInputType.TEXT,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalRightButton"
);

export const modalRightButtonBorderRadius = new Property(
    "Right button border radius",
    10,
    EState.DEFAULT,
    EProperty.BORDER_RADIUS,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "modalRightButton",
    EUnit.PX,
    0,
    24
);
