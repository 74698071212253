import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import PrizesByType from "@/entities/PrizificPageBuilder/sections/PrizesByType";
import { AComponentFactory } from "../ComponentFactory";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import {
    prizeFontFamily,
    prizesBackground,
    prizeImageWidth,
    prizesTextColor,
    prizesMoreTextColor,
    prizesMoreHoverTextColor,
    prizesTypeFontSize,
    prizesPrizeNameFontSize,
    prizesDescriptionFontSize,
    prizesMoreFontSize,
} from "~~/entities/pages/home/sections/Prizes/PrizesProperties";

export class PrizesByTypeFactory extends AComponentFactory<PrizesByType> {
    getTypeName() {
        return EComponents.PRIZES_BY_TYPE;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, [
            // "data.specific.sectionTitleCompiled",
            // "data.specific.sectionParagraphCompiled",
            // "data.specific.sectionTitle",
            // "data.specific.sectionParagraph",
            // "data.specific.images"
        ]);

        return new PrizesByType(
            rawData.id,
            rawData.data.title,
            properties,
            rawData.data.classNames,
            subComponents,
            rawData.data.specific.sectionTitleCompiled
                ? getConvertedText(rawData.data.specific.sectionTitleCompiled)
                : "",
            rawData.data.specific.sectionParagraphCompiled
                ? getConvertedText(
                      rawData.data.specific.sectionParagraphCompiled
                  )
                : "",
            rawData.data.specific.sectionTitle
                ? rawData.data.specific.sectionTitle
                : "",
            rawData.data.specific.sectionParagraph
                ? rawData.data.specific.sectionParagraph
                : "",
            rawData.data.specific.images
                ? rawData.data.specific.images
                : [
                      {
                          src: "",
                          imageType: "extra-extra-large",
                          imageLabel: "1920px",
                      },
                      {
                          src: "",
                          imageType: "extra-large",
                          imageLabel: "1400px",
                      },
                      {
                          src: "",
                          imageType: "large",
                          imageLabel: "1200px",
                      },
                      {
                          src: "",
                          imageType: "medium",
                          imageLabel: "992px",
                      },
                      {
                          src: "",
                          imageType: "small",
                          imageLabel: "768px",
                      },
                      {
                          src: "",
                          imageType: "extra-small",
                          imageLabel: "576px",
                      },
                  ]
        );
    }

    createPlain() {
        return new PrizesByType(
            "-",
            "Prize group",
            [
                prizeFontFamily,
                prizesBackground,
                prizeImageWidth,
                prizesTextColor,
                prizesMoreTextColor,
                prizesMoreHoverTextColor,
                prizesTypeFontSize,
                prizesPrizeNameFontSize,
                prizesDescriptionFontSize,
                prizesMoreFontSize,
            ],
            "",
            [],
            getConvertedText("Default title"),
            getConvertedText("Default paragraph"),
            "Default title",
            "Default paragraph"
        );
    }
}
