import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import AComponent from "../AComponent";
import { ISection } from "@/interfaces/PrizificPageBuilder/ISection";

export default class ShippingAddress
    extends AComponent<EComponents.SHIPPING_ADDRESS>
    implements IComponent<EComponents.SHIPPING_ADDRESS>, ISection
{
    constructor(
        id: string,
        title: string,
        style: Array<IProperty>,
        classNames: string,
        components: Array<IComponent<EComponents>>,
        public sectionTitle?: string,
        public sectionParagraph?: string,
        public sectionTitleRaw?: string,
        public sectionParagraphRaw?: string
    ) {
        super(
            id,
            title,
            EComponents.SHIPPING_ADDRESS,
            style,
            classNames,
            components
        );
    }

    toPayload(): { [key: string]: any } {
        return super.toPayload({
            sectionTitle: this.sectionTitleRaw,
            sectionParagraph: this.sectionParagraphRaw,
        });
    }
}
