import FormItem from "@/entities/PrizificPageBuilder/components/FormItem";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { ECodeUploadFormItem } from "~/enums/PrizificPageBuilder/ECodeUploadFormItem";

export const purchaseDateFormItem = new FormItem(
    "-",
    "Purchase date form item",
    [],
    "",
    [],
    "Vásárlás dátuma",
    "Add meg a vásárlás dátumát",
    "Vásárlás dátuma",
    "Add meg a vásárlás dátumát",
    ECodeUploadFormItem.PURCHASE_DATE
);
