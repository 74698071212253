import validate from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45campagn_45data_45global from "/opt/atlassian/pipelines/agent/build/middleware/01.campagnData.global.ts";
import _02_45age_45limit_45global from "/opt/atlassian/pipelines/agent/build/middleware/02.ageLimit.global.ts";
import _03_45actual_45page_45global from "/opt/atlassian/pipelines/agent/build/middleware/03.actualPage.global.ts";
import auth_45global from "/opt/atlassian/pipelines/agent/build/middleware/auth.global.ts";
import cookie_45consent_45global from "/opt/atlassian/pipelines/agent/build/middleware/cookieConsent.global.ts";
export const globalMiddleware = [
  validate,
  _01_45campagn_45data_45global,
  _02_45age_45limit_45global,
  _03_45actual_45page_45global,
  auth_45global,
  cookie_45consent_45global
]
export const namedMiddleware = {}