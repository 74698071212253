import { useCampaignDataStore } from "~/store/campaign/campaignData";

export default defineNuxtRouteMiddleware(async (to, from) => {
    //if (process.server) {
    //    return;
    //}

    const campaignDataStore = useCampaignDataStore();

    try {
        const router = useRouter();

        if (campaignDataStore?.campaignData?.ageRestriction) {
            const adultCookie = useCookie("adult").value;
            console.log("adultCookie", adultCookie);
            if (adultCookie !== undefined && Boolean(adultCookie)) {
                return;
            } else if (adultCookie === undefined) {
                if (
                    from.name !== "elmultal-tizennyolc" &&
                    to.name !== "elmultal-tizennyolc"
                ) {
                    console.log("navigation needed 1");
                    //return navigateTo(useDynamicLink("/elmultal-tizennyolc"));

                    return navigateTo({
                        path: useDynamicLink(
                            "/elmultal-tizennyolc"
                        ),
                    }, { redirectCode: 302 }
                    );
                }
            } else if (!Boolean(adultCookie)) {
                if (
                    from.name !== "elmultal-tizennyolc" &&
                    to.name !== "elmultal-tizennyolc"
                ) {
                    console.log("navigation needed");
                    return navigateTo({
                        path: useDynamicLink(
                            "/elmultal-tizennyolc"
                        ),
                    }, { redirectCode: 302 }
                    );
                    //return navigateTo(useDynamicLink("/elmultal-tizennyolc")); // When we dont need 'nem-vagy-eleg-idos' page
                }
                // if (
                //     from.name !== "nem-vagy-eleg-idos" &&
                //     to.name !== "nem-vagy-eleg-idos"
                // ) {
                //     return navigateTo("/nem-vagy-eleg-idos"); TODO: create 'nem-vagy-eleg-idos' page
                // }
            }
        }
    } catch (e) {
        //console.log("Error while parsing cookie", e);
    }
});
