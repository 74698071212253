import Login from "@/entities/PrizificPageBuilder/sections/Login";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { loginFeedbackModals } from "./components/LoginFeedbackModals";
import { loginForm } from "./components/LoginForm";
import {
    loginFontFamily,
    loginBackground,
    loginTitleColor,
    loginTitleFontSize,
    loginParagraphColor,
    loginParagraphFontSize,
    loginToggleButtonColor,
    loginToggleButtonBackground,
    loginToggleButtonBorderColor,
    loginToggleButtonActiveColor,
    loginToggleButtonActiveBackground,
    loginToggleButtonBorderRadius,
} from "./LoginProperties";

export const login = new Login(
    "-",
    "Login",
    [
        loginFontFamily,
        loginBackground,
        loginTitleColor,
        loginTitleFontSize,
        loginParagraphColor,
        loginParagraphFontSize,
        loginToggleButtonColor,
        loginToggleButtonBackground,
        loginToggleButtonBorderColor,
        loginToggleButtonActiveColor,
        loginToggleButtonActiveBackground,
        loginToggleButtonBorderRadius,
    ],
    "",
    [loginForm, loginFeedbackModals],
    "Bejelentkezés",
    "Kód feltöltéséhez kérjük, jelentkezz be!",
    "Bejelentkezés",
    "Kód feltöltéséhez kérjük, jelentkezz be!"
);
