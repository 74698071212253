import Form from "@/entities/PrizificPageBuilder/components/Form";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import {
    ageGateFormItemLabelColor,
    ageGateFormItemLabelFontSize,
    ageGateFormItemBackground,
    ageGateFormItemColor,
    ageGateFormItemBorderColor,
    ageGateFormItemBorderWidth,
    ageGateFormItemBorderRadius,
    ageGateFormSubmitButtonPadding,
    ageGateFormSubmitButtonFontSize,
    ageGateFormSubmitButtonBorderRadius,
    ageGateFormSubmitButtonBackground,
    ageGateFormSubmitButtonTextColor,
    ageGateFormSubmitButtonBackgroundHover,
    ageGateFormSubmitButtonTextColorHover,
    ageGateFormTextColor,
    ageGateFormSubmitButtonBorderColor,
    ageGateFormSubmitButtonBorderWidth,
} from "./AgeGateFormProperties";

export const ageGateForm = new Form(
    "-",
    "Age gate form",
    [
        ageGateFormItemLabelColor,
        ageGateFormItemLabelFontSize,
        ageGateFormItemBackground,
        ageGateFormItemColor,
        ageGateFormItemBorderColor,
        ageGateFormItemBorderWidth,
        ageGateFormItemBorderRadius,
        ageGateFormSubmitButtonPadding,
        ageGateFormSubmitButtonFontSize,
        ageGateFormSubmitButtonBorderColor,
        ageGateFormSubmitButtonBorderWidth,
        ageGateFormSubmitButtonBorderRadius,
        ageGateFormSubmitButtonBackground,
        ageGateFormSubmitButtonTextColor,
        ageGateFormSubmitButtonBackgroundHover,
        ageGateFormSubmitButtonTextColorHover,
        ageGateFormTextColor,
    ],
    "",
    []
);
