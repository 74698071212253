import Group from "~/entities/PrizificPageBuilder/components/Group";
import { pleaseActivateYourAccountModal } from "./PleaseActivateYourAccountModal";
import {
    modalTitleTextColor,
    modalDescriptionTextColor,
    modalCloseIconColor,
    modalRightButtonBackground,
    modalRightButtonColor,
    modalRightButtonBorderColor,
    modalRightButtonFontSize,
    modalRightButtonPadding,
    modalRightButtonBorderRadius,
} from "./RegistrationFeedbackModalsProperties";

export const registrationFeedbackModals = new Group(
    "-",
    "Feedback modals",
    [
        modalTitleTextColor,
        modalDescriptionTextColor,
        modalCloseIconColor,
        modalRightButtonBackground,
        modalRightButtonColor,
        modalRightButtonBorderColor,
        modalRightButtonFontSize,
        modalRightButtonPadding,
        modalRightButtonBorderRadius,
    ],
    "",
    [pleaseActivateYourAccountModal]
);
