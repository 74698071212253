import Property from "@/entities/PrizificPageBuilder/Property";
import { EInputType } from "@/enums/PrizificPageBuilder/EInputType";
import { EProperty } from "@/enums/PrizificPageBuilder/EProperty";
import { ERoles } from "@/enums/PrizificPageBuilder/ERoles";
import { EState } from "@/enums/PrizificPageBuilder/EState";
import { EUnit } from "@/enums/PrizificPageBuilder/EUnit";

export const ageGateFormItemLabelColor = new Property(
    "Label text color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "ageGateFormItemLabel"
);

export const ageGateFormItemLabelFontSize = new Property(
    "Label font size",
    14,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "ageGateFormItemLabel",
    EUnit.PX,
    14,
    24
);

export const ageGateFormItemBackground = new Property(
    "Input background color",
    "#fff",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "ageGateFormItemInput"
);

export const ageGateFormItemColor = new Property(
    "Input color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "ageGateFormItemInput"
);

export const ageGateFormItemBorderColor = new Property(
    "Input border color",
    "#fff",
    EState.DEFAULT,
    EProperty.BORDER_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "ageGateFormItemInput"
);

export const ageGateFormItemBorderRadius = new Property(
    "Input border radius",
    10,
    EState.DEFAULT,
    EProperty.BORDER_RADIUS,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "ageGateFormItemInput",
    EUnit.PX,
    0,
    64
);

export const ageGateFormItemBorderWidth = new Property(
    "Input border width",
    0,
    EState.DEFAULT,
    EProperty.BORDER_WIDTH,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "ageGateFormItemInput",
    EUnit.PX,
    0,
    8
);

export const ageGateFormSubmitButtonPadding = new Property(
    "Submit button padding",
    "4px 56px",
    EState.DEFAULT,
    EProperty.PADDING,
    EInputType.TEXT,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "ageGateFormSubmitButton"
);

export const ageGateFormSubmitButtonFontSize = new Property(
    "Submit button font size",
    18,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "ageGateFormSubmitButton",
    EUnit.PX,
    14,
    24
);

export const ageGateFormSubmitButtonBackground = new Property(
    "Submit button background color",
    "#3A89A2",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "ageGateFormSubmitButton"
);

export const ageGateFormSubmitButtonTextColor = new Property(
    "Submit button text color",
    "#fff",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "ageGateFormSubmitButton"
);

export const ageGateFormSubmitButtonBorderColor = new Property(
    "Submit button border color",
    "#fdfcf9",
    EState.DEFAULT,
    EProperty.BORDER_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "ageGateFormSubmitButton"
);

export const ageGateFormSubmitButtonBorderWidth = new Property(
    "Submit button border width",
    1,
    EState.DEFAULT,
    EProperty.BORDER_WIDTH,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "ageGateFormSubmitButton",
    EUnit.PX,
    0,
    8
);

export const ageGateFormSubmitButtonBorderRadius = new Property(
    "Submit button border radius",
    13,
    EState.DEFAULT,
    EProperty.BORDER_RADIUS,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "ageGateFormSubmitButton",
    EUnit.PX,
    0,
    48
);

export const ageGateFormSubmitButtonBackgroundHover = new Property(
    "Submit button background color hover",
    "#3A89A2",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "ageGateFormSubmitButtonHover"
);

export const ageGateFormSubmitButtonTextColorHover = new Property(
    "Submit button text color hover",
    "#fff",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "ageGateFormSubmitButtonHover"
);

export const ageGateFormTextColor = new Property(
    "Info text color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "ageGateInfoText"
);
