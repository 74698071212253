import { defineStore } from "pinia";

import { EComponents } from "~/enums/PrizificPageBuilder/EComponents";
import { IPage } from "~/interfaces/PrizificPageBuilder/IPage";
import { RuntimeError } from "~~/error/RuntimeError";
import { IComponent } from "~/interfaces/PrizificPageBuilder/IComponent";
import { ISection } from "~~/interfaces/PrizificPageBuilder/ISection";
import Vue from "vue";
import { useCampaignDataStore } from "./campaign/campaignData";

interface PageState {
    pages: { [key: string]: any /*IPage<EComponents>*/ };
    updateInProgress: EUpdateProgress;
}

export enum EUpdateProgress {
    IN_PROGRESS = "in-progress",
    FAILED = "failed",
    SUCCESS = "success",
}

export const usePagesStore = defineStore("pages", {
    state: (): PageState => {
        return {
            pages: {},
            updateInProgress: EUpdateProgress.SUCCESS,
        };
    },
    getters: {
        getPage(store) {
            const { $pageBuilderFactory } = useNuxtApp();

            return (pageNameComponentType: EComponents) => {
                if (store.pages[pageNameComponentType]) {
                    return $pageBuilderFactory.createPage(
                        store.pages[pageNameComponentType],
                        pageNameComponentType
                    );
                } else {
                    return undefined;
                }
            };
        },
        getPageIdByType(store) {
            const { $pageBuilderFactory } = useNuxtApp();

            return (type: EComponents) => {
                const page = this.pages[type];

                if (page) {
                    return $pageBuilderFactory
                        .createPage(store.pages[type], type)
                        .getId();
                }

                return -1;
            };
        },
    },
    actions: {
        /**
         * Return with the available state of the pages what ready and what are not
         *
         * TODO: complete and use!
         */
        async getPageState() {
            const { $axios } = useNuxtApp();

            const campaignDataStore = useCampaignDataStore();

            const campaignUuid = await campaignDataStore.obtainCampaignUUId();
            try {
                const response = await $axios.get(
                    `/api/pagebuilderms/page/${campaignUuid}/status`
                );

                if (response.status != 200) {
                    return Promise.reject(
                        new RuntimeError(
                            "The campaign page status is not available",
                            undefined,
                            campaignUuid
                        )
                    );
                }

                return response.data;
            } catch (e) {
                return Promise.reject(e);
            }
        },
        async updateComponent<T extends EComponents>(
            component: IComponent<T>
        ): Promise<IComponent<T> | undefined> {
            try {
                const campaignDataStore = useCampaignDataStore();
                const componentApiUrl = `/pagebuilderms/page/${
                    campaignDataStore.campaignUUId
                }/component/${component.getId()}`;

                const sharedComponentApiUrl = `/pagebuilderms/page/${
                    campaignDataStore.campaignUUId
                }/shared-component/${component.getSharedComponentId()}`;

                const apiUrl = component.isSharedComponent()
                    ? sharedComponentApiUrl
                    : componentApiUrl;

                const { data, error } = await useApiFetch(apiUrl, {
                    method: "PUT",
                    key: "updateComponent" + Math.random(),
                    body: { data: component.toPayload() },
                });

                if (error.value == null) {
                    const { $pageBuilderFactory } = useNuxtApp();

                    const resComp = $pageBuilderFactory.createComponent(
                        data.value.component
                    ) as IComponent<T>;

                    this.updateComponentInStore(data.value.component);

                    this.updateProgress(EUpdateProgress.SUCCESS);

                    return resComp;
                }

                this.updateProgress(EUpdateProgress.FAILED);
                throw new Error("The component are not saved correctly!");
            } catch (error) {
                console.error("Update Component", error);
                this.updateProgress(EUpdateProgress.FAILED);
                throw new Error("The component are not saved correctly!");
            }
        },
        /**
         *
         */
        async createComponent<T extends EComponents>(
            component: IComponent<T>,
            page: any
        ): Promise<IComponent<T> | undefined> {
            throw new Error("Deprecated!, Or not used");

            try {
                const campaignDataStore = useCampaignDataStore();

                const { data, error } = await useApiFetch(
                    `/pagebuilderms/page/${
                        campaignDataStore.campaignUUId
                    }/component/${page.getId()}`,
                    {
                        method: "POST",
                        body: {
                            data: component.toPayload(),
                            parentId: null,
                        },
                    }
                );

                if (error.value == null) {
                    this.updateProgress(EUpdateProgress.SUCCESS);
                    this.getPageFromServer(page.getType());
                } else {
                    this.updateProgress(EUpdateProgress.FAILED);
                }
            } catch (error) {
                console.error(
                    "Pages store error while create component",
                    error
                );
                this.updateProgress(EUpdateProgress.FAILED);
                throw new Error("The component are not saved correctly!");
            }
        },
        /**
         * Delete component
         *
         * @param component
         */
        async removeComponent<T extends EComponents>(
            component: IComponent<T>,
            actualPageType: EComponents
        ) {
            const campaignDataStore = useCampaignDataStore();

            const { error } = await useApiFetch(
                `/pagebuilderms/page/${
                    campaignDataStore.campaignUUId
                }/component/${component.getId()}`,
                {
                    key: "delete-component" + component.getId(),
                    method: "DELETE",
                }
            );
            console.log("Page delete", error.value);
            this.updateProgress(EUpdateProgress.SUCCESS);

            if (error.value == null) {
                this.getPageFromServer(actualPageType);
            }
        },

        /**
         * INFO: We only need to work with the main page, because the system will not provide any other page at this moment!
         * Get the given page and store into state
         */
        async getPageFromServer(
            pageNameComponentType: EComponents
        ): Promise<boolean> {
            const campaignDataStore = useCampaignDataStore();

            const { data, error } = await useApiFetch(
                `/pagebuilderms/page/${campaignDataStore.campaignUUId}/type/${pageNameComponentType}`,
                {
                    key:
                        "get-page-" +
                        pageNameComponentType +
                        "/" +
                        campaignDataStore.campaignUUId +
                        "/" +
                        Math.random(),
                }
            );
            if (error.value != null && error.value.statusCode == 404) {
                return false;
            } else if (error.value != null) {
                console.error("Get Page form server Error", error.value);
                return Promise.reject(
                    new RuntimeError(
                        "The Request status is not as expected!",
                        undefined,
                        campaignDataStore.campaignUUId
                    )
                );
            }

            this.pages[pageNameComponentType] = data.value;

            return true;
        },

        /**
         * Helper function for update section/component in store
         */
        updateComponentInStore(resComponent: any) {
            const pageKeys = Object.keys(this.pages);

            pageKeys.forEach((key: any) => {
                if (
                    this.pages[key] &&
                    this.pages[key].page &&
                    this.pages[key].page.components
                ) {
                    this.updateComponentInStoreRecursive(
                        resComponent,
                        this.pages[key].page.components
                    );
                }
            });
        },
        updateComponentInStoreRecursive(
            replaceComponent: any,
            oldComponents: Array<any>
        ) {
            oldComponents.forEach((component, index) => {
                if (component.id == replaceComponent.id) {
                    oldComponents[index] = replaceComponent;
                } else {
                    this.updateComponentInStoreRecursive(
                        replaceComponent,
                        component.components
                    );
                }
            });
        },

        /**
         * Update progress state
         * @param newValue
         */
        updateProgress(newValue: EUpdateProgress) {
            this.updateInProgress = newValue;
        },

        /**
         * Reorder components
         */
        async reOrderComponents<T extends EComponents>(
            component: IComponent<T>,
            order: any
        ) {
            const moveDirection =
                order.oldIndex > order.newIndex ? "up" : "down";
            const moveStep = Math.abs(
                order.oldIndex + 1 - (order.newIndex + 1)
            );
            const campaignDataStore = useCampaignDataStore();

            const { data, error } = await useApiFetch(
                `/pagebuilderms/page/${
                    campaignDataStore.campaignUUId
                }/component/${component.getId()}/move/${moveDirection}/${moveStep}`,
                {
                    method: "PUT",
                    key:
                        "reOrderComponents" +
                        campaignDataStore.campaignUUId +
                        "/" +
                        Math.random(),
                    body: {
                        data: component.toPayload(),
                    },
                }
            );

            if (error.value == null) {
                console.log("Reorder complete: ", data.value);
            }
        },
    },
});
