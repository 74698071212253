import Property from "@/entities/PrizificPageBuilder/Property";
import { EInputType } from "@/enums/PrizificPageBuilder/EInputType";
import { EProperty } from "@/enums/PrizificPageBuilder/EProperty";
import { ERoles } from "@/enums/PrizificPageBuilder/ERoles";
import { EState } from "@/enums/PrizificPageBuilder/EState";
import { EUnit } from "@/enums/PrizificPageBuilder/EUnit";

export const yourPrizeFontFamily = new Property(
    "Font family",
    "",
    EState.DEFAULT,
    EProperty.FONT_FAMILY,
    EInputType.FONT_SELECT,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "yourPrize"
);

export const yourPrizePadding = new Property(
    "Padding",
    "4px 8px",
    EState.DEFAULT,
    EProperty.PADDING,
    EInputType.TEXT,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "yourPrize"
);

export const yourPrizeBackground = new Property(
    "Background color",
    "#fff",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "yourPrize"
);

export const yourPrizeTitleFontSize = new Property(
    "Title font size",
    24,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "yourPrizeTitle",
    EUnit.PX,
    11,
    32
);

export const yourPrizeTitleColor = new Property(
    "Title color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "yourPrizeTitle"
);

export const yourPrizeNameFontSize = new Property(
    "Prize name font size",
    20,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "yourPrizeName",
    EUnit.PX,
    11,
    32
);

export const yourPrizeNameColor = new Property(
    "Prize name color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "yourPrizeName"
);

export const yourPrizeImageWidth = new Property(
    "Prize image width",
    303,
    EState.DEFAULT,
    EProperty.WIDTH,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "yourPrizeImage",
    EUnit.PX,
    25,
    320
);

// export const yourPrizeImageHeight = new Property(
//     "Prize image height",
//     228,
//     EState.DEFAULT,
//     EProperty.HEIGHT,
//     EInputType.NUMBER,
//     [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
//     "yourPrizeImage",
//     EUnit.PX,
//     25,
//     320
// );
