import Form from "@/entities/PrizificPageBuilder/components/Form";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";

import {
    recipeUploadFormBackground,
    recipeUploadFormItemLabelColor,
    recipeUploadFormItemLabelFontSize,
    recipeUploadFormItemBorderColor,
    recipeUploadFormItemBorderWidth,
    recipeUploadFormItemBorderRadius,
    recipeUploadFormItemFontSize,
    recipeUploadFormItemColor,
} from "./RecipeUploadFormProperties";
import { recipeUploadFormItem } from "./RecipeUploadFormItem";

export const recipeUploadForm = new Form(
    "-",
    "Recipe upload form",
    [
        recipeUploadFormBackground,
        recipeUploadFormItemLabelColor,
        recipeUploadFormItemLabelFontSize,
        recipeUploadFormItemColor,
        recipeUploadFormItemFontSize,
        recipeUploadFormItemBorderColor,
        recipeUploadFormItemBorderWidth,
        recipeUploadFormItemBorderRadius,
    ],
    "",
    [recipeUploadFormItem]
);
