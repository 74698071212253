import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { AComponentFactory } from "../ComponentFactory";
import Group from "@/entities/PrizificPageBuilder/components/Group";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";

export class GroupFactory extends AComponentFactory<Group> {
    getTypeName() {
        return EComponents.GROUP;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, []);

        return new Group(
            rawData.id,
            rawData.data.title,
            properties,
            rawData.data.classNames,
            subComponents
        );
    }
}
