import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import AComponent from "../AComponent";
import { IImageText } from "@/interfaces/PrizificPageBuilder/IImageText";

export default class ImageText
    extends AComponent<EComponents.IMAGE_TEXT>
    implements IComponent<EComponents.IMAGE_TEXT>, IImageText
{
    constructor(
        id: string,
        title: string,
        style: Array<IProperty>,
        classNames: string,
        components: Array<IComponent<EComponents>>,
        public imageContent: string,
        public descriptionContent: string,
        public descriptionContentRaw: string
    ) {
        super(id, title, EComponents.IMAGE_TEXT, style, classNames, components);
    }

    toPayload(): { [key: string]: any } {
        return super.toPayload({
            imageContent: this.imageContent,
            descriptionContent: this.descriptionContentRaw,
        });
    }
}
