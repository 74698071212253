import HeroImage from "@/entities/PrizificPageBuilder/sections/HeroImage";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { EImageType } from "~~/enums/PrizificPageBuilder/EImageType";
import { heroFontFamily } from "./HeroImageProperties";

export const heroImage = new HeroImage(
    "-",
    "Hero image",
    [heroFontFamily],
    "",
    [],
    "Vásárolj bármilyen kiszerelésű medvekonzervet és nyerj!",
    "Már egy termék vásárlásával is tiéd lehet a napi, heti nyeremények egyike, vagy akár a fődíj, a Tesla Model S fehér színben!",
    "Vásárolj bármilyen kiszerelésű medvekonzervet és nyerj!",
    "Már egy termék vásárlásával is tiéd lehet a napi, heti nyeremények egyike, vagy akár a fődíj, a Tesla Model S fehér színben!",
    [
        {
            src: "",
            imageType: EImageType.EXTRA_EXTRA_LARGE,
            imageLabel: "1920px",
        },
        {
            src: "",
            imageType: EImageType.EXTRA_LARGE,
            imageLabel: "1400px",
        },
        {
            src: "",
            imageType: EImageType.LARGE,
            imageLabel: "1200px",
        },
        {
            src: "",
            imageType: EImageType.MEDIUM,
            imageLabel: "992px",
        },
        {
            src: "",
            imageType: EImageType.SMALL,
            imageLabel: "768px",
        },
        {
            src: "",
            imageType: EImageType.EXTRA_SMALL,
            imageLabel: "576px",
        },
    ]
);
