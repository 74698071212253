import Contact from "@/entities/PrizificPageBuilder/sections/Contact";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { contactButton } from "./components/ContactButton/ContactButton";
import {
    contactFontFamily,
    contactBackground,
    contactTitleColor,
    contactTitleFontSize,
    contactParagraphColor,
    contactParagraphFontSize,
} from "./ContactProperties";

export const contact = new Contact(
    "",
    null,
    false,
    "Contact",
    [
        contactFontFamily,
        contactTitleColor,
        contactTitleFontSize,
        contactParagraphColor,
        contactParagraphFontSize,
        contactBackground,
    ],
    "",
    [contactButton],
    "Kérdésed van?",
    "Vedd fel velünk a kapcsolatot!",
    "Kérdésed van?",
    "Vedd fel velünk a kapcsolatot!",
    [],
);
