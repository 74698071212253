import ListItem from "@/entities/PrizificPageBuilder/components/ListItem";
import {
    stepIncrementWidth,
    stepFontSize,
    stepIncrementHeight,
    stepColor,
    stepIncrementBackground,
    stepIncrementColor,
    stepIncrementBorderRadius,
} from "../../GameStepsProperties";

export const firstGameStep = new ListItem(
    "-",
    "Game step",
    [stepFontSize, stepColor, stepIncrementWidth],
    "",
    [],
    "Vásárolj 2021. május 1. és 2021. július 31. között promóciós csomagolású medvekonzervet!",
    "Vásárolj 2021. május 1. és 2021. július 31. között promóciós csomagolású medvekonzervet!",
    ""
);
