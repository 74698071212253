import Property from "@/entities/PrizificPageBuilder/Property";
import { EInputType } from "@/enums/PrizificPageBuilder/EInputType";
import { EProperty } from "@/enums/PrizificPageBuilder/EProperty";
import { ERoles } from "@/enums/PrizificPageBuilder/ERoles";
import { EState } from "@/enums/PrizificPageBuilder/EState";
import { EUnit } from "@/enums/PrizificPageBuilder/EUnit";

export const codeUploadFontFamily = new Property(
    "Font family",
    "",
    EState.DEFAULT,
    EProperty.FONT_FAMILY,
    EInputType.FONT_SELECT,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadBackground"
);

export const codeUploadBackground = new Property(
    "Background color",
    "#3a89a2",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadBackground"
);

export const codeUploadTitleColor = new Property(
    "Title text color",
    "#f7f3e8",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadTitle"
);

export const codeUploadTitleFontSize = new Property(
    "Title font size",
    32,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadTitle",
    EUnit.PX,
    24,
    48
);

export const codeUploadParagraphColor = new Property(
    "Paragraph text color",
    "#f7f3e8",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadParagraph"
);

export const codeUploadParagraphFontSize = new Property(
    "Paragraph font size",
    16,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "codeUploadParagraph",
    EUnit.PX,
    13,
    32
);
