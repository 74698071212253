import Form from "@/entities/PrizificPageBuilder/components/Form";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import {
    newPasswordFormBackground,
    newPasswordFormItemLabelColor,
    newPasswordFormItemLabelFontSize,
    newPasswordFormItemBorderColor,
    newPasswordFormItemBorderWidth,
    newPasswordFormItemBorderRadius,
    newPasswordFormSubmitButtonPadding,
    newPasswordFormSubmitButtonFontSize,
    newPasswordFormSubmitButtonBackground,
    newPasswordFormSubmitButtonTextColor,
    newPasswordFormSubmitButtonBorderRadius,
    newPasswordFormSubmitButtonBackgroundHover,
    newPasswordFormSubmitButtonTextColorHover,
} from "./NewPasswordFormProperties";
import { passwordFormItem } from "./PasswordFormItem";

export const newPasswordForm = new Form(
    "New password form",
    EComponents.FORM,
    [
        newPasswordFormBackground,
        newPasswordFormItemLabelColor,
        newPasswordFormItemLabelFontSize,
        newPasswordFormItemBorderColor,
        newPasswordFormItemBorderWidth,
        newPasswordFormItemBorderRadius,
        newPasswordFormSubmitButtonPadding,
        newPasswordFormSubmitButtonFontSize,
        newPasswordFormSubmitButtonBorderRadius,
        newPasswordFormSubmitButtonBackground,
        newPasswordFormSubmitButtonTextColor,
        newPasswordFormSubmitButtonBackgroundHover,
        newPasswordFormSubmitButtonTextColorHover,
    ],
    "",
    [passwordFormItem]
);
