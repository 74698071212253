import FormItem from "@/entities/PrizificPageBuilder/components/FormItem";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { ECodeUploadFormItem } from "~/enums/PrizificPageBuilder/ECodeUploadFormItem";

export const apCodeFormItem = new FormItem(
    "-",
    "AP code form item",
    [],
    "",
    [],
    "AP kód",
    "",
    "AP kód",
    "",
    ECodeUploadFormItem.AP_CODE
);
