import FormItem from "@/entities/PrizificPageBuilder/components/FormItem";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { ERegistrationFormItem } from "~~/enums/PrizificPageBuilder/ERegistrationFormItem";

export const dateOfBirthDayFormItem = new FormItem(
    "-",
    "Date of birth day form item",
    [],
    "",
    [],
    "",
    "Day",
    "",
    "Day",
    ERegistrationFormItem.DATE_OF_BIRTH_DAY
);
