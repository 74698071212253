import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { ISection } from "@/interfaces/PrizificPageBuilder/ISection";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import AComponent from "../AComponent";
import { IImg } from "~~/interfaces/PrizificPageBuilder/IImg";

export default class TextButton
    extends AComponent<EComponents.TEXT_BUTTON_SECTION>
    implements IComponent<EComponents.TEXT_BUTTON_SECTION>, ISection
{
    constructor(
        id: string,
        title: string,
        style: Array<IProperty>,
        classNames: string,
        components: Array<IComponent<EComponents>>,
        public sectionTitle?: string,
        public sectionParagraph?: string,
        public sectionTitleRaw?: string,
        public sectionParagraphRaw?: string,
        public images?: Array<IImg>
    ) {
        super(
            id,
            title,
            EComponents.TEXT_BUTTON_SECTION,
            style,
            classNames,
            components
        );
    }

    toPayload(): { [key: string]: any } {
        return super.toPayload({
            sectionTitle: this.sectionTitleRaw,
            sectionParagraph: this.sectionParagraphRaw,
            images: this.images,
        });
    }
}
