import FormItem from "@/entities/PrizificPageBuilder/components/FormItem";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { ELoginFormItem } from "~~/enums/PrizificPageBuilder/ELoginFormItem";

export const emailFormItem = new FormItem(
    "-",
    "Email form item",

    [],
    "",
    [],
    "Email",
    "",
    "Email",
    "",
    ELoginFormItem.EMAIL
);
