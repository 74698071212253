import Modal from "@/entities/PrizificPageBuilder/components/Modal";
import { EModal } from "~~/enums/PrizificPageBuilder/EModal";

export const pleaseActivateYourAccountModal = new Modal(
    "-",
    "Please activate your account modal",
    [],
    "",
    [],
    "Aktiváld a fiókod cím",
    "Aktiváld a fiókod leírás",
    "",
    "Bezár",
    "Aktiváld a fiókod cím",
    "Aktiváld a fiókod leírás",
    "",
    "Bezár",
    EModal.PLEASEACTIVATEYOURACCOUNT
);
