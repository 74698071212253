import Form from "~~/entities/PrizificPageBuilder/components/Form";
import { emailFormItem } from "./EmailFormItem";
import { passwordFormItem } from "./PasswordFormItem";
import { firstNameFormItem } from "./FirstNameFormItem";
import { lastNameFormItem } from "./LastNameFormItem";
import { phoneNumberFormItem } from "./PhoneNumberFormItem";
import { dateOfBirthYearFormItem } from "./DateOfBirthYearFormItem";
import { dateOfBirthMonthFormItem } from "./DateOfBirthMonthFormItem";
import { dateOfBirthDayFormItem } from "./DateOfBirthDayFormItem";
import { zipFormItem } from "./ZipFormItem";
import { cityFormItem } from "./CityFormItem";
import { addressFormItem } from "./AddressFormItem";

export const userConsentRegistrationForm = new Form(
    "-",
    "Registration form",

    [],
    "",
    [
        emailFormItem,
        passwordFormItem,
        firstNameFormItem,
        lastNameFormItem,
        phoneNumberFormItem,
        dateOfBirthYearFormItem,
        dateOfBirthMonthFormItem,
        dateOfBirthDayFormItem,
        zipFormItem,
        cityFormItem,
        addressFormItem,
    ]
);
