import Img from "@/entities/PrizificPageBuilder/components/Img";
import { navBrandLogoWidth } from "./NavBrandLogoProperties";

export const navBrandLogo = new Img(
    "-",
    null,
    false,
    "Brand Logo",
    [navBrandLogoWidth],
    "",
    [],
    "/images/prizific-test-page-logo.png" //TODO: add CDN url for this by config
);
