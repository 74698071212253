import UploadedCodes from "~~/entities/PrizificPageBuilder/sections/UploadedCodes";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import {
    uploadedCodesFontFamily,
    uploadedCodesBackground,
    uploadedCodesTitleColor,
    uploadedCodesTitleFontSize,
} from "./UploadedCodesProperty";
import { uploadedCodesCard } from "./components/UploadedCodesCard";

export const uploadedCodes = new UploadedCodes(
    "-",
    "Uploaded codes",
    [
        uploadedCodesFontFamily,
        uploadedCodesBackground,
        uploadedCodesTitleColor,
        uploadedCodesTitleFontSize,
    ],
    "",
    [uploadedCodesCard],
    "Feltöltött kódjaim",
    "",
    "Feltöltött kódjaim",
    ""
);
