import Modal from "@/entities/PrizificPageBuilder/components/Modal";
import { EModal } from "~/enums/PrizificPageBuilder/EModal";

export const successfulCodeUploadModal = new Modal(
    "-",
    "Successful code upload modal",
    [],
    "",
    [],
    "Sikeres feltöltés!",
    "Sikeresen jelentkeztél a nyereményjátékba. Sok szerencsét kívánunk a sorsoláshoz! Ha szeretnéd növelni nyerési esélyeidet, tölts fel további AP-kódokat. Kérjük, a blokkot minden esetben őrizd meg, mert nyerés esetén azzal tudod igazolni a vásárlást!",
    "",
    "Feltöltök egy új kódot",
    "Sikeres feltöltés!",
    "Sikeresen jelentkeztél a nyereményjátékba. Sok szerencsét kívánunk a sorsoláshoz! Ha szeretnéd növelni nyerési esélyeidet, tölts fel további AP-kódokat. Kérjük, a blokkot minden esetben őrizd meg, mert nyerés esetén azzal tudod igazolni a vásárlást!",
    "",
    "Feltöltök egy új kódot",
    EModal.SUCCESSFULCODEUPLOAD
);
