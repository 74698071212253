import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { IImg } from "~~/interfaces/PrizificPageBuilder/IImg";
import { ISection } from "~~/interfaces/PrizificPageBuilder/ISection";
import AComponent from "../AComponent";

export default class VoteDefault
    extends AComponent<EComponents.VOTE_DEFAULT>
    implements IComponent<EComponents.VOTE_DEFAULT>, ISection
{
    constructor(
        id: string,
        title: string,
        style: Array<IProperty>,
        classNames: string,
        components: Array<IComponent<EComponents>>,
        public sectionTitle?: string,
        public sectionParagraph?: string,
        public sectionTitleRaw?: string,
        public sectionParagraphRaw?: string,
        public images?: Array<IImg>
    ) {
        super(
            id,
            title,
            EComponents.VOTE_DEFAULT,
            style,
            classNames,
            components
        );
    }

    toPayload(): { [key: string]: any } {
        return super.toPayload({
            sectionTitle: this.sectionTitleRaw,
            sectionParagraph: this.sectionParagraphRaw,
            images: this.images,
        });
    }
}
