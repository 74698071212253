import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { AComponentFactory } from "../ComponentFactory";
import Form from "@/entities/PrizificPageBuilder/components/Form";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";

export class FormFactory extends AComponentFactory<Form> {
    getTypeName() {
        return EComponents.FORM;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, []);

        return new Form(
            rawData.id,
            rawData.data.title,
            properties,
            rawData.data.classNames,
            subComponents
        );
    }
}
