import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import Footer from "@/entities/PrizificPageBuilder/sections/Footer";
import { RuntimeComponentParseError } from "@/error/RuntimeError";
import { AComponentFactory } from "../ComponentFactory";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { footer } from "~~/entities/pages/shared/sections/Footer/Footer";

export class FooterFactory extends AComponentFactory<Footer> {
    getTypeName() {
        return EComponents.FOOTER;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, [
            // "data.specific.firstGroupTitleCompiled",
            // "data.specific.secondGroupTitleCompiled",
            // "data.specific.thirdGroupTitleCompiled",
            // "data.specific.firstGroupTitle",
            // "data.specific.secondGroupTitle",
            // "data.specific.thirdGroupTitle",
            // "data.specific.images"
        ]);

        return new Footer(
            rawData.id,
            rawData.sharedComponentId ?? null,
            rawData.isSharedComponent ?? false,
            rawData.data.title,
            usePropertiesMerger(properties, footer.style),
            rawData.data.classNames,
            subComponents,
            getConvertedText(rawData.data.specific.firstGroupTitleCompiled),
            getConvertedText(rawData.data.specific.secondGroupTitleCompiled),
            getConvertedText(rawData.data.specific.thirdGroupTitleCompiled),
            rawData.data.specific.firstGroupTitle,
            rawData.data.specific.secondGroupTitle,
            rawData.data.specific.thirdGroupTitle,
            rawData.data.specific.images
                ? rawData.data.specific.images
                : [
                      {
                          src: "",
                          imageType: "extra-extra-large",
                          imageLabel: "1920px",
                      },
                      {
                          src: "",
                          imageType: "extra-large",
                          imageLabel: "1400px",
                      },
                      {
                          src: "",
                          imageType: "large",
                          imageLabel: "1200px",
                      },
                      {
                          src: "",
                          imageType: "medium",
                          imageLabel: "992px",
                      },
                      {
                          src: "",
                          imageType: "small",
                          imageLabel: "768px",
                      },
                      {
                          src: "",
                          imageType: "extra-small",
                          imageLabel: "576px",
                      },
                  ]
        );
    }
}
