import Property from "@/entities/PrizificPageBuilder/Property";
import { EInputType } from "@/enums/PrizificPageBuilder/EInputType";
import { EProperty } from "@/enums/PrizificPageBuilder/EProperty";
import { ERoles } from "@/enums/PrizificPageBuilder/ERoles";
import { EState } from "@/enums/PrizificPageBuilder/EState";
import { EUnit } from "@/enums/PrizificPageBuilder/EUnit";

export const newPasswordFormBackground = new Property(
    "Background color",
    "#FFFFFF00",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "newPasswordFormBackground"
);

export const newPasswordFormItemLabelColor = new Property(
    "Label text color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "newPasswordFormItemLabel"
);

export const newPasswordFormItemLabelFontSize = new Property(
    "Label font size",
    14,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "newPasswordFormItemLabel",
    EUnit.PX,
    14,
    24
);

export const newPasswordFormItemBackground = new Property(
    "Input background color",
    "#fff",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "newPasswordFormItemInput"
);

export const newPasswordFormItemBorderColor = new Property(
    "Input border color",
    "#fff",
    EState.DEFAULT,
    EProperty.BORDER_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "newPasswordFormItemInput"
);

export const newPasswordFormItemBorderRadius = new Property(
    "Input border radius",
    8,
    EState.DEFAULT,
    EProperty.BORDER_RADIUS,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "newPasswordFormItemInput",
    EUnit.PX,
    0,
    48
);

export const newPasswordFormItemBorderWidth = new Property(
    "Input border width",
    1,
    EState.DEFAULT,
    EProperty.BORDER_WIDTH,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "newPasswordFormItemInput",
    EUnit.PX,
    0,
    8
);

export const newPasswordFormCheckboxBackground = new Property(
    "Checkbox background",
    "#fff",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "newPasswordFormCheckbox"
);

export const newPasswordFormCheckboxColor = new Property(
    "Checkbox check color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "newPasswordFormCheckbox"
);

export const newPasswordFormCheckboxBorderRadius = new Property(
    "Checkbox border radius",
    8,
    EState.DEFAULT,
    EProperty.BORDER_RADIUS,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "newPasswordFormCheckbox",
    EUnit.PX,
    0,
    48
);

export const newPasswordFormSubmitButtonPadding = new Property(
    "Submit button padding",
    "6px 8px",
    EState.DEFAULT,
    EProperty.PADDING,
    EInputType.TEXT,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "newPasswordFormSubmitButton"
);

export const newPasswordFormSubmitButtonFontSize = new Property(
    "Submit button font size",
    14,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "newPasswordFormSubmitButton",
    EUnit.PX,
    14,
    24
);

export const newPasswordFormSubmitButtonBackground = new Property(
    "Submit button background color",
    "#3A89A2",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "newPasswordFormSubmitButton"
);

export const newPasswordFormSubmitButtonTextColor = new Property(
    "Submit button text color",
    "#fff",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "newPasswordFormSubmitButton"
);

export const newPasswordFormSubmitButtonBorderRadius = new Property(
    "Submit button border radius",
    8,
    EState.DEFAULT,
    EProperty.BORDER_RADIUS,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "newPasswordFormSubmitButton",
    EUnit.PX,
    0,
    48
);

export const newPasswordFormSubmitButtonBackgroundHover = new Property(
    "Submit button hover background color",
    "#3A89A2",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "newPasswordFormSubmitButtonHover"
);

export const newPasswordFormSubmitButtonTextColorHover = new Property(
    "Submit button hover text color",
    "#fff",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "newPasswordFormSubmitButtonHover"
);
