import Group from "~/entities/PrizificPageBuilder/components/Group";
import { almostDoneModal } from "./AlmostDoneModal";
import { emailOrPasswordIncorrectModal } from "./EmailOrPasswordIncorrectModal";
import { forgottenPasswordModal } from "./ForgottenPasswordModal";

import {
    modalTitleTextColor,
    modalDescriptionTextColor,
    modalCloseIconColor,
    modalRightButtonBackground,
    modalRightButtonColor,
    modalRightButtonBorderColor,
    modalRightButtonFontSize,
    modalRightButtonPadding,
    modalRightButtonBorderRadius,
} from "./LoginFeedbackModalsProperties";
import { notActivatedUserModal } from "./NotActivatedUserModal";

export const loginFeedbackModals = new Group(
    "-",
    "Feedback modals",
    [
        modalTitleTextColor,
        modalDescriptionTextColor,
        modalCloseIconColor,
        modalRightButtonBackground,
        modalRightButtonColor,
        modalRightButtonBorderColor,
        modalRightButtonFontSize,
        modalRightButtonPadding,
        modalRightButtonBorderRadius,
    ],
    "",
    [
        notActivatedUserModal,
        emailOrPasswordIncorrectModal,
        forgottenPasswordModal,
        almostDoneModal,
    ]
);
