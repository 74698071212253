import Property from "~/entities/PrizificPageBuilder/Property";
import { EInputType } from "~/enums/PrizificPageBuilder/EInputType";
import { EProperty } from "~/enums/PrizificPageBuilder/EProperty";
import { ERoles } from "~/enums/PrizificPageBuilder/ERoles";
import { EState } from "~/enums/PrizificPageBuilder/EState";
import { EUnit } from "~/enums/PrizificPageBuilder/EUnit";

export const userConsentingFontFamily = new Property(
    "Font family",
    "",
    EState.DEFAULT,
    EProperty.FONT_FAMILY,
    EInputType.FONT_SELECT,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "userConsentingBackground"
);

export const userConsentingBackground = new Property(
    "Background color",
    "#878787",
    EState.DEFAULT,
    EProperty.BACKGROUND_COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "userConsentingBackground"
);

export const userConsentingTitleColor = new Property(
    "Title text color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "userConsentingTitle"
);

export const userConsentingTitleFontSize = new Property(
    "Title font size",
    32,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "userConsentingTitle",
    EUnit.PX,
    24,
    48
);

export const userConsentingParagraphColor = new Property(
    "Paragraph text color",
    "#444444",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "userConsentingParagraph"
);

export const userConsentingParagraphFontSize = new Property(
    "Paragraph font size",
    16,
    EState.DEFAULT,
    EProperty.FONT_SIZE,
    EInputType.NUMBER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "userConsentingParagraph",
    EUnit.PX,
    13,
    32
);

export const userConsentingPrimaryColor = new Property(
    "Primary Color",
    "#3A89A2",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "userConsentingPrimaryColor"
);

export const userConsentingSecondaryColor = new Property(
    "Secondary Color",
    "#3A89A2",
    EState.DEFAULT,
    EProperty.COLOR,
    EInputType.COLOR_PICKER,
    [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
    "userConsentingSecondaryColor"
);
