export enum ECodeUploadFormItem {
    AP_CODE = "AP_CODE",
    EMAIL = "EMAIL",
    FIRST_NAME = "FIRST_NAME",
    LAST_NAME = "LAST_NAME",
    HOW_TO_PURCHASE = "HOW_TO_PURCHASE",
    PLACE_OF_PURCHASE = "PLACE_OF_PURCHASE",
    PURCHASE_DATE = "PURCHASE_DATE",
    PURCHASED_PROMOTION_PRODUCT = "PURCHASED_PROMOTION_PRODUCT",
    PURCHASE_TIME = "PURCHASE_TIME",
    RECEIPT_NUMBER = "RECEIPT_NUMBER",
    PRIVACY_POLICY = "PRIVACY_POLICY",
}
