import { defineStore } from "pinia";
import { EPrizeType } from "~~/enums/Campaign/EPrizeType";
import moment from "moment";
import { campaignRule } from "~~/entities/pages/shared/sections/Footer/components/CampaignRule/CampaignRule";

export const useCampaignDataStore = defineStore("campaignData", {
    state: () => {
        return {
            isPageBuilder: false,
            isPreview: false,
            showPageBuilderControls: true,
            campaignData: {} as any,
            campaignPrizes: [] as any,
            campaignPromotions: [] as any,
            campaignFaqs: [] as any,
            campaignDraws: {} as any,
        };
    },
    getters: {
        dynamicCampaignApiUrlPart(state): string {
            if (state.campaignData) {
                return (
                    state.campaignData.campaignDomain +
                    state.campaignData.campaignPrefix
                );
            }

            return "";
        },

        campaignUUId(state): string {
            if (state.campaignData && state.campaignData.uuid) {
                return state.campaignData.uuid;
            }

            return "";
        },
        campaignId(state) {
            if (state.campaignData && state.campaignData.id) {
                return state.campaignData.id;
            }
            return -1;
        },
        /**
         * Return with the campaign name by state
         */
        campaignName(state) {
            return state.campaignData.name ?? "-";
        },
        /**
         * Return with the campaign fonts by state
         */
        campaignFonts(state) {
            return state.campaignData.fonts ?? [];
        },
        /**
         * Return with the campaign brand colors by state
         */
        campaignBrandColors(state) {
            return state.campaignData.brandColors ?? [];
        },
        /**
         * Get Campaign prizes from state
         *
         * @param state
         * @returns {Array}
         */
        campaignPrizesByType(state) {
            let prizes: any[] = [];
            const mainPrize = state.campaignPrizes.find(
                (prize: any) => prize.type === EPrizeType.MAIN
            );
            const monthlyPrize = state.campaignPrizes.find(
                (prize: any) => prize.type === EPrizeType.MONTHLY
            );
            const weeklyPrize = state.campaignPrizes.find(
                (prize: any) => prize.type === EPrizeType.WEEKLY
            );
            const dailyPrize = state.campaignPrizes.find(
                (prize: any) => prize.type === EPrizeType.DAILY
            );

            mainPrize !== undefined ? prizes.push(mainPrize) : "";
            monthlyPrize !== undefined ? prizes.push(monthlyPrize) : "";
            weeklyPrize !== undefined ? prizes.push(weeklyPrize) : "";
            dailyPrize !== undefined ? prizes.push(dailyPrize) : "";

            return prizes;
        },

        /**
         * Registration required
         * @param state
         * @returns
         */
        isRegistrationRequired(state): boolean {
            return state.campaignData?.registrationRequired;
        },

        /**
         * Hive connection
         * @param state
         * @returns
         */
        isHiveConnection(state): boolean {
            return state.campaignData?.hiveConnection;
        },

        /**
         * Age restricition
         * @param state
         * @returns
         */
        isAgeRestriction(state): boolean {
            return state.campaignData?.ageRestriction;
        },

        /**
         * Is campaign before start
         * @param state
         * @returns {Boolean}
         */
        isCampaignBeforeStart(state): boolean {
            const now = moment(),
                campaignStart = moment(state.campaignData?.campaignStart),
                beforeStart = campaignStart.diff(now, "seconds", true);

            return beforeStart >= 0;
        },

        /**
         * Is campaign running
         * @param state
         * @returns {Boolean}
         */
        isCampaignRunning(state): boolean {
            const now = moment(),
                campaignStart = moment(state.campaignData?.campaignStart),
                campaignEnd = moment(state.campaignData?.campaignEnd),
                started = campaignStart.diff(now, "seconds", true),
                ended = campaignEnd.diff(now, "seconds", true);

            return started <= 0 && ended >= 0;
        },

        /**
         * Is campaign ended
         * @param state
         * @returns {Boolean}
         */
        isCampaignEnd(state): boolean {
            const now = moment(),
                campaignEnd = moment(state.campaignData?.campaignEnd),
                afterEnd = campaignEnd.diff(now, "seconds", true);

            return afterEnd <= 0;
        },
    },
    actions: {
        /**
         * Get campaign data and set into storage
         *
         * @returns
         */
        async getCampaignData() {
            if (process.client) {
                throw new Error(
                    "Get campaign Data Only available on server, user server prefetch, (middleware populated data)"
                );
            }

            const event = useRequestEvent();

            let reqAuthorization =
                (event.node.req.headers["authorization"] as string) ?? "";
            let reqCampaignUUid =
                (event.node.req.headers["campaignuuid"] as string) ?? "";
            let reqViewType =
                (event.node.req.headers["viewtype"] as string) ?? "";
            //console.log(
            //    "Incoming::reqAuthorization--->", reqAuthorization,
            //    "Incoming::reqCampaignUUid--->", reqCampaignUUid,
            //    "Incoming::reqViewType--->", reqViewType
            //);

            const config = useRuntimeConfig();

            reqAuthorization =
                config.testCampaignBearerToken !== undefined &&
                    config.testCampaignBearerToken !== ""
                    ? "Bearer " + config.testCampaignBearerToken
                    : reqAuthorization;
            reqCampaignUUid =
                config.testCampaignUUID !== undefined &&
                    config.testCampaignUUID !== ""
                    ? config.testCampaignUUID
                    : reqCampaignUUid;
            reqViewType =
                config.testViewMode !== undefined && config.testViewMode !== ""
                    ? config.testViewMode
                    : reqViewType;
            //console.log("Headers---",
            //
            //    {
            //        "authorization": reqAuthorization,
            //        "CampaignUUID": reqCampaignUUid,
            //        "ViewType": reqViewType,
            //    }
            //);
            const { data, error }: any = await useFetch(
                config.campaignDataServerUrl,
                {
                    headers: {
                        authorization: reqAuthorization,
                        CampaignUUID: reqCampaignUUid,
                        ViewType: reqViewType,
                    },
                }
            );

            //console.log("data, error", data, error);
            if (error.value == null) {
                this.isPageBuilder = reqViewType == "page-builder";
                this.isPreview = reqViewType == "preview";

                if (data.value.campaign.campaign) {
                    this.campaignData = data.value.campaign.campaign;
                } else {
                    this.campaignData = data.value.campaign;
                }

                this.campaignPrizes = data.value.prize.prizes;
                this.campaignPromotions = data.value.promotion.promotions;
                this.campaignFaqs = data.value.faq.faqs;

                if (data.value.draw) {
                    this.campaignDraws = data.value.draw.draws;
                } else {
                    this.campaignDraws = { edmSubscription: true };
                }

                return true;
            } else {
                console.error("Error while get campaign data---", error.value);
            }

            return false;
        },
        /**
         * If campaign already loaded return with the campaign uuid, if not try load the campaign and return with the uuid
         *
         */
        async obtainCampaignUUId() {
            if (this.campaignUUId == "") {
                await this.getCampaignData();
            }

            return this.campaignUUId;
        },
        /**
         * If campaign already loaded return with the campaign id, if not try load the campaign and return with the id
         *
         * @returns
         */
        async obtainCampaignId() {
            if (this.campaignId == -1) {
                await this.getCampaignData();
            }
            return this.campaignId;
        },
        async obtainTrackingScripts(): Promise<
            Array<{ type: string; script: string }>
        > {
            if (this.campaignId == -1) {
                await this.getCampaignData();
            }

            try {
                return this.campaignData.analyticEventScripts ?? [];
            } catch (e) {
                return [];
            }
        },
    },
});
