import UploadedCodesCard from "@/entities/PrizificPageBuilder/components/UploadedCodesCard";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import {
    uploadedCodesCardHeadBackground,
    uploadedCodesCardHeadTextColor,
    uploadedCodesCardHeadTextFontSize,
    uploadedCodesCardSwiperPaginationBackground,
    uploadedCodesCardSwiperPaginationBorderColor,
    uploadedCodesCardSwiperNavigationColor,
    uploadedCodesCardBodyBackground,
    uploadedCodesCardBodyTextColor,
    uploadedCodesCardBodyTextFontSize,
    uploadedCodesCardBodyWinnerCodeUploadBackground,
    uploadedCodesCardBodyWinnerCodeUploadTextColor,
    uploadedCodesCardBodyWinnerCodeUploadTextFontSize,
} from "./UploadedCodesCardProperty";

export const uploadedCodesCard = new UploadedCodesCard(
    "-",
    "Uploaded codes card",
    [
        uploadedCodesCardHeadBackground,
        uploadedCodesCardHeadTextColor,
        uploadedCodesCardHeadTextFontSize,
        uploadedCodesCardSwiperPaginationBackground,
        uploadedCodesCardSwiperPaginationBorderColor,
        uploadedCodesCardSwiperNavigationColor,
        uploadedCodesCardBodyBackground,
        uploadedCodesCardBodyTextColor,
        uploadedCodesCardBodyTextFontSize,
        uploadedCodesCardBodyWinnerCodeUploadBackground,
        uploadedCodesCardBodyWinnerCodeUploadTextColor,
        uploadedCodesCardBodyWinnerCodeUploadTextFontSize,
    ],
    "",
    [],
    "Feltöltés ideje",
    "Feltöltött adatok",
    "Feltöltés ideje",
    "Feltöltött adatok"
);
