import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { AComponentFactory } from "../ComponentFactory";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import ImageText from "~~/entities/PrizificPageBuilder/components/ImageText";
import {
    imageTextImageBorderRadius,
    imageTextDescriptionColor,
    imageTextDescriptionFontSize,
} from "~~/entities/InsertableSections/ImageTextContentProperty/ImageTextProperty";

export class ImageTextFactory extends AComponentFactory<ImageText> {
    getTypeName() {
        return EComponents.IMAGE_TEXT;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, [
            // "data.specific.imageContent",
            // "data.specific.descriptionContentCompiled",
            // "data.specific.descriptionContent",
        ]);

        return new ImageText(
            rawData.id,
            rawData.data.title,
            properties,
            rawData.data.classNames,
            subComponents,
            rawData.data.specific.imageContent ?? "",
            getConvertedText(rawData.data.specific.descriptionContentCompiled),
            rawData.data.specific.descriptionContent
        );
    }

    createPlain() {
        return new ImageText(
            "-",
            "Image text",
            [
                imageTextImageBorderRadius,
                imageTextDescriptionColor,
                imageTextDescriptionFontSize,
            ],
            "",
            [],
            "",
            getConvertedText("Default paragraph"),
            "Default paragraph"
        );
    }
}
