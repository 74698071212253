import FormItem from "@/entities/PrizificPageBuilder/components/FormItem";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { ECodeUploadFormItem } from "~/enums/PrizificPageBuilder/ECodeUploadFormItem";

export const privacyPolicyFormItem = new FormItem(
    "-",
    "Privacy policy form item",
    [],
    "",
    [],
    "Elolvastam és elfogadom az adatvédelmi nyilatkozatot és a játékszabályokat és elmúltam 18 éves.",
    "",
    "Elolvastam és elfogadom az adatvédelmi nyilatkozatot és a játékszabályokat és elmúltam 18 éves.",
    "",
    ECodeUploadFormItem.PRIVACY_POLICY
);
