import NavItem from "@/entities/PrizificPageBuilder/components/NavItem";

import {
    termsOfUseFontSize,
    termsOfUsePadding,
    termsOfUseBorderRadius,
    termsOfUseTextColor,
    termsOfUseTextColorHover,
    termsOfUseBackgroundColor,
    termsOfUseBackgroundColorHover,
} from "./TermsOfUseProperties";

export const termsOfUse = new NavItem(
    "-",
    null,
    false,
    "Felhasználási feltételek",
    [
        termsOfUseFontSize,
        termsOfUsePadding,
        termsOfUseTextColor,
        termsOfUseTextColorHover,
        termsOfUseBackgroundColor,
        termsOfUseBackgroundColorHover,
    ],
    "",
    [],
    "Felhasználási feltételek",
    "Felhasználási feltételek",
    "",
    true
);
