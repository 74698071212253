import { useCampaignDataStore } from '~~/store/campaign/campaignData';


export function useApiBaseUrl(): string {

    const config = useRuntimeConfig();
    const campaignDataStore = useCampaignDataStore()


    let baseUrl: URL;

    if (config.testApiDomain) {
        //console.log("config.testApiDomain", config.testApiDomain);
        //baseUrl = config.apiUrlScheme + "://" + config.testApiDomain + "/" + campaignDataStore.campaignData.campaignPrefix + "/api";
        baseUrl = new URL(config.apiUrlScheme + "://" + config.testApiDomain + "/" + campaignDataStore.campaignData.campaignPrefix + "/api");
    } else if (config.testApiUrl) {
        //console.log("config.testApiUrl", config.testApiUrl);
        baseUrl = new URL(config.apiUrlScheme + "://" + config.testApiUrl);
    } else if (campaignDataStore.isPreview) {


        //console.log("campaignDataStore.isPreview", campaignDataStore.isPreview);
        //baseUrl = config.apiUrlScheme + "://" + campaignDataStore.campaignUUId + ".preview.develop.prizific.com/api/";
        //console.log("PreviewURl", config.apiUrlScheme, campaignDataStore.campaignUUId, campaignDataStore.campaignData.campaignPrefix, config.apiUrlScheme + "://" + campaignDataStore.campaignUUId + config.previewPart + campaignDataStore.campaignData.campaignPrefix);
        baseUrl = new URL(config.apiUrlScheme + "://" + campaignDataStore.campaignUUId + config.previewPart + campaignDataStore.campaignData.campaignPrefix + "/api");
        //console.log("previewUrlBaseUrl", baseUrl.toString());
    } else if (campaignDataStore.isPageBuilder) {
        //console.log("campaignDataStore.isPageBuilder", campaignDataStore.isPageBuilder);
        //baseUrl = config.apiUrlScheme + "://" + campaignDataStore.campaignUUId + ".page-builder.develop.prizific.com/api/";
        //console.log("pageBuilderURl", config.apiUrlScheme, campaignDataStore.campaignUUId, campaignDataStore.campaignData.campaignPrefix, config.apiUrlScheme + "://" + campaignDataStore.campaignUUId + config.pageBuilderPart + campaignDataStore.campaignData.campaignPrefix);
        baseUrl = new URL(config.apiUrlScheme + "://" + campaignDataStore.campaignUUId + config.pageBuilderPart + campaignDataStore.campaignData.campaignPrefix + "/api");
        //console.log("pageBuilderBaseUrl", baseUrl.toString());
    } else {
        //baseUrl = config.apiUrlScheme + "://" + campaignDataStore.dynamicCampaignApiUrlPart + "/api/"
        //console.log("campaignDataStore.dynamicCampaignApiUrlPart", campaignDataStore.dynamicCampaignApiUrlPart);
        baseUrl = new URL(config.apiUrlScheme + "://" + campaignDataStore.dynamicCampaignApiUrlPart + "/api");
    }
    //console.log("API baseUrl.toString()-----------", baseUrl.toString());
    return baseUrl.toString()

}