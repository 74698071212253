import CodeUpload from "@/entities/PrizificPageBuilder/sections/CodeUpload";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { codeUploadForm } from "./components/CodeUploadForm";
import { codeUploadFeedbackModals } from "./components/CodeUploadFeedbackModals";
import {
    codeUploadFontFamily,
    codeUploadBackground,
    codeUploadTitleColor,
    codeUploadTitleFontSize,
    codeUploadParagraphColor,
    codeUploadParagraphFontSize,
} from "./CodeUploadProperties";

export const codeUpload = new CodeUpload(
    "-",
    "Code upload",

    [
        codeUploadFontFamily,
        codeUploadBackground,
        codeUploadTitleColor,
        codeUploadTitleFontSize,
        codeUploadParagraphColor,
        codeUploadParagraphFontSize,
    ],
    "",
    [codeUploadForm, codeUploadFeedbackModals],
    "Kódfeltöltés",
    "Kérlek add meg a blokkon található adatokat!",
    "Kódfeltöltés",
    "Kérlek add meg a blokkon található adatokat!",
    ""
);
