import Modal from "@/entities/PrizificPageBuilder/components/Modal";
import { EModal } from "~~/enums/PrizificPageBuilder/EModal";

export const apCodeConflictItselfModal = new Modal(
    "-",
    "Ap code conflict itself modal",
    [],
    "",
    [],
    "title",
    "description",
    "",
    "button",
    "title",
    "description",
    "",
    "button",
    EModal.APCODECONFLICTITSELF
);
