import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import AComponent from "../AComponent";
import { IWinnerList } from "~~/interfaces/PrizificPageBuilder/IWinnerList";

export default class WinnersList
    extends AComponent<EComponents.WINNERS_LIST>
    implements IComponent<EComponents.WINNERS_LIST>, IWinnerList
{
    constructor(
        id: string,
        title: string,
        style: Array<IProperty>,
        classNames: string,
        components: Array<IComponent<EComponents>>,
        public mainlyPrizeTabText: string,
        public mainlyPrizeTabTextRaw: string,
        public monthlyPrizeTabText: string,
        public monthlyPrizeTabTextRaw: string,
        public weeklyPrizeTabText: string,
        public weeklyPrizeTabTextRaw: string,
        public dailyPrizeTabText: string,
        public dailyPrizeTabTextRaw: string
    ) {
        super(
            id,
            title,
            EComponents.WINNERS_LIST,
            style,
            classNames,
            components
        );
    }

    toPayload(): { [key: string]: any } {
        return super.toPayload({
            mainlyPrizeTabText: this.mainlyPrizeTabTextRaw,
            monthlyPrizeTabText: this.monthlyPrizeTabTextRaw,
            weeklyPrizeTabText: this.weeklyPrizeTabTextRaw,
            dailyPrizeTabText: this.dailyPrizeTabTextRaw,
        });
    }
}
