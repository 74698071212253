
import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import { EInputType } from "~~/enums/PrizificPageBuilder/EInputType";
import AComponent from "./AComponent";



export default abstract class ASharedComponent<T> extends AComponent<T> implements IComponent<T>{

    constructor(
        id: string | number,
        protected sharedComponentId: string | number | null,
        protected isSharedComponentIn: boolean,
        title: string,
        type: T,
        style: Array<IProperty>,
        classNames: string,
        components: Array<IComponent<EComponents>>
    ) {
        super(
            id,
            title,
            type,
            style,
            classNames,
            components
        );
    }


    setSharedComponentId(sharedComponentId: string | number) {
        this.sharedComponentId = sharedComponentId
    }

    isSharedComponent() {
        return this.isSharedComponentIn;
    }

    getSharedComponentId(){
        return  this.sharedComponentId;
    }
}