import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { IImg } from "@/interfaces/PrizificPageBuilder/IImg";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import AComponent from "../AComponent";
import ASharedComponent from "../ASharedComponent";

export default class Img
    extends ASharedComponent<EComponents.IMAGE>
    implements IComponent<EComponents.IMAGE>, IImg
{
    constructor(
        id: string,
        sharedComponentId: string | number | null,
        isSharedComponent: boolean,
        title: string,
        style: Array<IProperty>,
        classNames: string,
        components: Array<IComponent<EComponents>>,
        public src: string,
        public alt?: string
    ) {
        super(
            id,
            sharedComponentId,
            isSharedComponent,
            title,
            EComponents.IMAGE,
            style,
            classNames,
            components
        );
    }

    toPayload(): { [key: string]: any } {
        return super.toPayload({
            src: this.src,
            alt: this.alt,
        });
    }
}
